import { message } from "antd";
import { takeEvery, put, call, all, fork, delay } from "redux-saga/effects";
import {
  getDoctorsPagination,
  setDoctors,
  setDoctor,
  setDoctorTitle,
} from "redux/actions/Doctors";
import {
  DELETE_DOCTOR,
  GET_DOCTOR,
  GET_DOCTORS_PAGINATION,
  POST_NEW_DOCTOR,
  UPDATE_DOCTOR,
  GET_DOCTOR_TITLES,
} from "redux/constants/Doctors";
import { ROUTES } from "routes";
import DoctorsServices from "services/DoctorsServices";
import { push } from "connected-react-router";

export function* getDoctorsPaginationSaga() {
  yield takeEvery(GET_DOCTORS_PAGINATION, function* ({ payload }) {
    const { page, perPage, search, order, orderField } = payload;
    try {
      const doctors = yield call(
        DoctorsServices.getDoctorsWithPagination,
        page,
        perPage,
        search,
        order,
        orderField
      );
      const results = {
        data: doctors.data,
        order: order,
        orderField: orderField,
      };
      yield put(setDoctors(results));
    } catch (err) {
      console.log(err);
      message.error(err.response.data.message, 3);
    }
  });
}

export function* deleteDoctorSaga() {
  yield takeEvery(DELETE_DOCTOR, function* ({ payload, data }) {
    try {
      yield call(DoctorsServices.deleteDoctor, payload);
      yield put(getDoctorsPagination(data));
    } catch (err) {
      console.log(err);
      err.response.data?.["non_field_errors"] &&
        message.error(err.response.data["non_field_errors"][0], 3);
    }
  });
}

export function* postNewDoctorSaga() {
  yield takeEvery(POST_NEW_DOCTOR, function* ({ payload, messageSuccess }) {
    try {
      yield call(DoctorsServices.createNewDoctor, payload);
      yield delay(3000, message.success(messageSuccess));
      yield put(push(ROUTES.DOCTORS_PAGE));
    } catch (err) {
      console.log(err.response);
      err.response.data?.["phone_number"] &&
        message.error(err.response.data["phone_number"][0], 3);
      err.response.data?.["non_field_errors"] &&
        message.error(err.response.data["non_field_errors"][0], 3);
      err.response.data?.["aditional_titles"] &&
        message.error(err.response.data["aditional_titles"][0][0], 3);
      err.response.data?.["email"] &&
        message.error(err.response.data["email"][0], 3);
    }
  });
}

export function* getDoctorSaga() {
  yield takeEvery(GET_DOCTOR, function* ({ payload }) {
    try {
      const { data } = yield call(DoctorsServices.getDoctor, payload);
      yield put(setDoctor(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* updateDoctorSaga() {
  yield takeEvery(UPDATE_DOCTOR, function* (payload) {
    try {
      yield call(DoctorsServices.updateDoctor, payload.data, payload.id);
      yield delay(3000, message.success(payload.messageSuccess));
    } catch (err) {
      console.log(err.response);
      err.response.data?.["phone_number"] &&
        message.error(err.response.data["phone_number"][0], 3);
      err.response.data?.["non_field_errors"] &&
        message.error(err.response.data["non_field_errors"][0], 3);
      err.response.data?.["aditional_titles"] &&
        message.error(err.response.data["aditional_titles"][0][0], 3);
    }
  });
}

export function* getDoctorTitlesSaga() {
  yield takeEvery(GET_DOCTOR_TITLES, function* () {
    try {
      const result = yield call(DoctorsServices.getDoctorsTitle);
      yield put(setDoctorTitle(result));
    } catch (err) {
      console.log(err.response);
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(getDoctorsPaginationSaga),
    fork(deleteDoctorSaga),
    fork(postNewDoctorSaga),
    fork(getDoctorSaga),
    fork(updateDoctorSaga),
    fork(getDoctorTitlesSaga),
  ]);
}
