import {
  GET_DOCTORS_PAGINATION,
  SET_DOCTORS_PAGINATION,
  DELETE_DOCTOR,
  POST_NEW_DOCTOR,
  UPDATE_DOCTOR,
  GET_DOCTOR,
  SET_DOCTOR,
  SET_DOCTOR_TITLES,
  GET_DOCTOR_TITLES,
} from "redux/constants/Doctors";

export const getDoctorsPagination = (payload) => ({
  type: GET_DOCTORS_PAGINATION,
  payload,
});

export const setDoctors = (payload) => ({
  type: SET_DOCTORS_PAGINATION,
  payload,
});

export const deleteDoctor = (payload, data) => ({
  type: DELETE_DOCTOR,
  payload,
  data,
});

export const postNewDoctor = (payload, messageSuccess) => ({
  type: POST_NEW_DOCTOR,
  payload,
  messageSuccess,
});

export const updateDoctor = (id, data, messageSuccess) => ({
  type: UPDATE_DOCTOR,
  id,
  data,
  messageSuccess,
});

export const getDoctorsInfo = (payload) => ({
  type: GET_DOCTOR,
  payload,
});

export const setDoctor = (payload) => ({
  type: SET_DOCTOR,
  payload,
});

export const getDoctorTitle = () => ({
  type: GET_DOCTOR_TITLES,
});

export const setDoctorTitle = (payload) => ({
  type: SET_DOCTOR_TITLES,
  payload,
});
