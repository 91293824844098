import React, { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AGENCY_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { PrivateRoute } from "shared/PrivateRoute";

export const AgencyViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivateRoute
          section={"agency"}
          path={`${AGENCY_PREFIX_PATH}/clone`}
          component={lazy(() => import(`./CloningPage`))}
          exact
        />
        <Redirect
          from={`${AGENCY_PREFIX_PATH}`}
          to={`${AUTH_PREFIX_PATH}/login`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AgencyViews);
