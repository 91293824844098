import ApiService from "./ApiService";

const ENDPOINTS = {
  LONG_LIVED_USER_ACCESS_TOKEN: "fb_long_lived_token",
};

class SocialMediaPermissionsService extends ApiService {
  getLongLivedUserAccessToken = (data) => {
    return this.apiClient.post(ENDPOINTS.LONG_LIVED_USER_ACCESS_TOKEN, data);
  };
}

export default new SocialMediaPermissionsService();
