import React, { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_LOYALTY_PREFIX_PATH, AUTH_LOYALTY_PREFIX_PATH } from "configs/AppConfig";
import PrivatePatientRoute from "shared/PrivatePatientRoute";
import { PublicLoyatyRoute } from "shared/PublicLoyatyRoute";

export const AuthLoyatyViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <PublicLoyatyRoute
          path={`${AUTH_LOYALTY_PREFIX_PATH}/login`}
          component={lazy(() => import("./LoginPage"))}
        />
        <PrivatePatientRoute
          path={`${AUTH_LOYALTY_PREFIX_PATH}/create-password`}
          type="createPassword"
          component={lazy(() => import(`./CreatePassword`))}
        />
        <PublicLoyatyRoute
          path={`${AUTH_LOYALTY_PREFIX_PATH}/reset-password`}
          component={lazy(() => import(`../auth-loyaty-views/ResetPassword`))}
        />
        <PublicLoyatyRoute
          path={`${AUTH_LOYALTY_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import(`./ForgotPasswordLoyaltyPage`))}
        />
        <PrivatePatientRoute
          path={`${AUTH_LOYALTY_PREFIX_PATH}/select-organization`}
          component={lazy(() => import("./SelectOrganizationPage"))}
          exact
        />
        <Redirect from={`${APP_LOYALTY_PREFIX_PATH}`} to={`${AUTH_LOYALTY_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  );
};

export default AuthLoyatyViews;
