import { COMPLETED, REDEMEED, UNCOMPLETED } from "constants/AwardsConstants";

export const changeUsersAwards = (userAward, payload, pointsIndex) => {
  const award = userAward.users_awards.find((award) => award.id == payload.award);
  const index = !!pointsIndex
    ? pointsIndex
    : award.corresponding_points.findIndex((el) => (el.id = payload.id));

  if (award) {
    award.corresponding_points[index].collected_points = payload.collected_points;
    award.corresponding_points[index].award_status = payload.award_status;
    award.corresponding_points[index].available_redeem_code = payload?.available_redeem_code;
    award.corresponding_points[index].progress = payload?.progress;
  }

  switch (payload.award_status) {
    case UNCOMPLETED:
      if (payload.collected_points == 0) userAward.uncompleted_awards += 1;
      break;
    case COMPLETED:
      userAward.completed_awards += 1;
      userAward.uncompleted_awards -= 1;
      break;
    case REDEMEED:
      userAward.redeemed_awards += 1;
      userAward.completed_awards -= 1;
      break;
    default:
      break;
  }
};
