import {GET_TREATMENTS,
SET_TREATMENTS,
CREATE_TREATMENT,
SET_NEW_TREATMENT,
GET_TREATMENT_IMAGES,
SET_TREATMENT_IMAGES, 
} from 'redux/constants/Treatment';

 export const getTreatments = () => ({
     type: GET_TREATMENTS,
 });

 export const setTreatments = (payload) => ({
     type: SET_TREATMENTS,
     payload
 });

 export const createTreatment = (payload) => ({
    type: CREATE_TREATMENT,
    payload,
 });

 export const setNewTreatment = (payload) => ({
    type: SET_NEW_TREATMENT,
    payload
 });

export const getTreatmentImages = (treatmentId) => ({
    type: GET_TREATMENT_IMAGES,
    treatmentId
});

export const setTreatmentImages = (payload) => ({
    type: SET_TREATMENT_IMAGES,
    payload 
});
