import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { GET_TREATMENTS, CREATE_TREATMENT, GET_TREATMENT_IMAGES } from "redux/constants/Treatment";
import TreatmentService from "../../services/TreatmentService";
import { setTreatments, setNewTreatment, setTreatmentImages } from "redux/actions/Treatment";

export function* getTreatments() {
    yield takeEvery(GET_TREATMENTS, function* () {
        try {
            const response = yield call(TreatmentService.getTreatments);
            yield put(setTreatments(response.data.results));
        } catch (err) {
            console.log(err);
        }
    })
}

export function* createTreatments() {
    yield takeEvery(CREATE_TREATMENT, function* ({payload}) {
        try {
            const response = yield call(TreatmentService.createTreatment, payload);
            yield put(setNewTreatment(response.data));
        } catch (err) {
            console.log(err);
        }
    })
}

export function* getTreatmentsImages() {
    yield takeEvery(GET_TREATMENT_IMAGES, function* ({treatmentId}) {
        try {
            const response = yield call(TreatmentService.getTreatmentImages, treatmentId);
            yield put(setTreatmentImages(response.data.results));
        } catch (err) {
            console.log(err);
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getTreatments),
        fork(createTreatments),
        fork(getTreatmentsImages),
    ])
}