import React, { Suspense, lazy } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Layout, Spin } from "antd";
import Loading from "components/shared-components/Loading";
import AppLoyaltyViews from "views/loyalty-app/app-loyalty-views";
const HeaderNavPatient = lazy(() => import("components/layout-components/HeaderNavPatient"));

const { Content } = Layout;

export const AppLoyaltyLayout = () => {
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <Suspense fallback={<Spin />}>
      <Layout>
        <HeaderNavPatient />
        <Layout className="app-container">
          <Layout className="app-layout">
            <div className={`app-content`}>
              <Content>
                <AppLoyaltyViews />
              </Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
    </Suspense>
  );
};

export default AppLoyaltyLayout;
