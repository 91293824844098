import languageReducer from "containers/LanguageProvider/reducer";
import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import ContactSettings from "./ContactSettings";
import { connectRouter } from "connected-react-router";
import Contact from "./Contact";
import Funnel from "./Funnel";
import Appointment from "./Appointment";
import organizationReducer from "./Organization";
import activityReducer from "./Activity";
import historyActivityReducer from "./HistoryActivity";
import htmlBuilderReducer from "./HtmlBuilder";
import campaignReducer from "./Campaign";
import smsBuilderReducer from "./SmsBuilder";
import eventReducer from "./Event";
import conversationReducer from "./Conversation";
import webPageReducer from "./WebPage";
import formBuilder from "./FormBuilder";
import paymentReducer from "./Payment";
import notificationSettingsReducer from "./NotificationSettings";
import chatWidgetReducer from "./ChatWidget";
import doctorsReducer from "./Doctors";
import treatmentReducer from "./Treatment";
import reviewsReducer from "./Reviews";
import dashboardReducer from "./Dashboard";
import notesReducer from "./Notes";
import awardsReducer from "./Awards";
import loyaltyUsersReducer from "./LoyaltyUsers";
import triggerReducer from "./Trigger";
import PaymentIntegrationReducer from "./PaymentIntegrations";
import documentsReducer from "./Documents";
import brandRegistrationReducer from "./TwilioBrandRegostration";
import picturesReducer from "./Pictures";
import pipelinesReducer from "./Pipelines";
import reportingReducer from "./Reporting";

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) =>
  combineReducers({
    theme: Theme,
    auth: Auth,
    awards: awardsReducer,
    language: languageReducer,
    contact: Contact,
    funnel: Funnel,
    router: connectRouter(history),
    contactSettings: ContactSettings,
    appointment: Appointment,
    organization: organizationReducer,
    activity: activityReducer,
    historyActivity: historyActivityReducer,
    htmlBuilder: htmlBuilderReducer,
    documents: documentsReducer,
    pictures: picturesReducer,
    pipelines: pipelinesReducer,
    campaign: campaignReducer,
    smsBuilder: smsBuilderReducer,
    event: eventReducer,
    conversation: conversationReducer,
    webPage: webPageReducer,
    formBuilder: formBuilder,
    payment: paymentReducer,
    notificationSettings: notificationSettingsReducer,
    chatWidget: chatWidgetReducer,
    doctor: doctorsReducer,
    treatment: treatmentReducer,
    reviews: reviewsReducer,
    dashboard: dashboardReducer,
    notes: notesReducer,
    loyatyUsers: loyaltyUsersReducer,
    trigger: triggerReducer,
    paymentIntegration: PaymentIntegrationReducer,
    brand: brandRegistrationReducer,
    reporting: reportingReducer,
  });
