/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import produce from "immer";
import {
  SET_FUNNELS,
  UPDATE_FUNNELS,
  SET_FUNNEL_CONTACTS,
  UPDATE_NAME_FUNNEL,
  SET_ORDER_CONTACTS,
  CLEARE_FUNNELS_DATA,
} from "../constants/Funnel";
import { ORDER_CONTACT } from "../../constants/ContactConstant";

export const initialState = {
  funnels: {
    data: [],
    isLoading: true,
  },
  contacts: [],
};

const funnelReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case SET_FUNNELS:
        draft.funnels = {
          data: payload.data,
          isLoading: false,
        };

        if (payload.type === "initial") {
          draft.contacts = [];
          payload.data.map((item) => {
            draft.contacts[item.id] = {
              page: 1,
              count: 0,
              next: null,
              previous: null,
              total_pages: 0,
              results: [],
              isLoading: true,
            };
          });
        }

        break;

      case UPDATE_FUNNELS:
        if (payload.action === "add") {
          draft.funnels = {
            data: [...draft.funnels.data, payload.data],
            isLoading: false,
          };
          draft.contacts[payload.data.id] = {
            count: 0,
            next: null,
            previous: null,
            total_pages: 1,
            results: [],
            current: 1,
            isLoading: false,
          };
        } else {
          Object.keys(draft.funnels.data).map((value, index) => {
            if (draft.funnels.data[value]?.id === payload.id) {
              draft.funnels.data = draft.funnels.data
                .slice(0, index)
                .concat(
                  draft.funnels.data.slice(index + 1, draft.funnels.data.length)
                );
            }
          });
        }

        break;
      case SET_FUNNEL_CONTACTS:
        draft.contacts[payload.id] = {
          page: payload.data.current,
          count: payload.data.count,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results:
            payload.data.current === 1
              ? payload.data.results
              : [
                  ...draft.contacts[payload.id].results,
                  ...payload.data.results,
                ],
          isLoading: false,
        };
        break;
      case SET_ORDER_CONTACTS:
        switch (payload.type) {
          case ORDER_CONTACT.ORDER:
            draft.contacts[payload.funnel_id_source].results.splice(
              payload.to,
              0,
              draft.contacts[payload.funnel_id_source].results.splice(
                payload.source,
                1
              )[0]
            );
            break;

          case ORDER_CONTACT.DELETE_AND_ADD:
            Object.keys(draft.contacts[payload.funnel_id_source].results).map(
              (value, index) => {
                if (
                  draft.contacts[payload.funnel_id_source].results[value]
                    ?.id === payload.id
                ) {
                  const contactMove =
                    draft.contacts[payload.funnel_id_source].results[value];
                  draft.contacts[payload.funnel_id_source].results =
                    draft.contacts[payload.funnel_id_source].results
                      .slice(0, index)
                      .concat(
                        draft.contacts[payload.funnel_id_source].results.slice(
                          index + 1,
                          draft.contacts[payload.funnel_id_source].results
                            .length
                        )
                      );

                  draft.contacts[payload.funnel_id_destination].results.splice(
                    payload.to,
                    0,
                    contactMove
                  );
                }
              }
            );
            break;
        }
        break;

      case UPDATE_NAME_FUNNEL:
        draft.funnels.data.map((item) => {
          if (item.id === payload.id) {
            item.name = payload.name;
          }
        });
        break;

      case CLEARE_FUNNELS_DATA:
        draft.funnels = {
          data: [],
          isLoading: false,
        };
        draft.contacts = [];
        break;
    }
  });

export default funnelReducer;
