import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
    GET_CHAT_WIDGET: "custom_chat_widget/",
    UPDATE_CHAT_WIDGET: "custom_chat_widget/%s/",
    CROP_AVATAR: "custom_chat_widget/crop_avatar/"
}

class ChatWidgetService extends ApiService {
    getChatWidget = () => {
        return this.apiClient.get(ENDPOINTS.GET_CHAT_WIDGET)
    }

    updateChatWidget = (payload, id) => {
        return this.apiClient.put(format(ENDPOINTS.UPDATE_CHAT_WIDGET, id), payload)
    }

    cropAvatar = ({payload}) => {
        return this.apiClient.post(ENDPOINTS.CROP_AVATAR, payload);
    }
}

export default new ChatWidgetService();