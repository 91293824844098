import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { GET_DASHBOARD_DATA, GET_CONTACT_CONVERSIONS } from "redux/constants/Dashboard";
import DashboardService from "../../services/DashboardService";
import { setDashboardStatsData, setContactConversions } from "redux/actions/Dashboard";

export function* getDashboardStatsData() {
  yield takeEvery(GET_DASHBOARD_DATA, function* ({payload}) {
    try {
      const { data } = yield call(DashboardService.getDashboardStatsData, payload);
      yield put(setDashboardStatsData(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getDashboardConversionData() {
  yield takeEvery(GET_CONTACT_CONVERSIONS, function* (payload) {
    try {
      const { data } = yield call(DashboardService.getDashboardConversionData, payload);
      yield put(setContactConversions(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getDashboardStatsData), fork(getDashboardConversionData)]);
}
