import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { makeSelectIsAuthenticated } from "selectors";
import { ROUTES } from "routes";

export function PublicLoyatyRoute({ component: Component, ...rest }) {
  const isAuthenticated = useSelector(makeSelectIsAuthenticated());

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Redirect to={ROUTES.LOYALTY_PAGE} /> : <Component {...props} />
      }
    />
  );
}

export default PublicLoyatyRoute;
