import {
  SET_CONTACTS,
  SHOW_MESSAGE,
  GET_CONTACTS,
  DELETE_CONTACTS,
  IMPORT_CONTACTS,
  IMPORT_CONTACTS_RESULT,
  IMPORT_STARTED,
  SET_IMPORT_STATUS_OK,
  CHANGE_IMAGE,
  GET_SINGLE_CONTACTS,
  SET_SINGLE_CONTACTS,
  EDIT_SINGLE_CONTACTS,
  CHANGE_IMAGE_SUCCESS,
  REMOVE_FROM_FUNNEL,
  STORE_CONTACTS,
  UPDATE_CONTACTS,
  GET_TAGS,
  SET_TAGS,
  SET_CONTACT_TO_FUNNEL,
  SET_UPDATE_SUCCESS_INDICATOR,
  SET_LOADING_CONTACTS,
  UPDATE_CONTACT_FUNNEL,
  SET_CONTACTS_ORDER,
  UNSUBSCRIBE_CONTACT,
  REMOVE_CONTACTS_FROM_CONVERSATION,
  UPDATE_LOYALTY_CONTACT_INFO,
  CONTACT_PARTIAL_UPDATE,
  ADD_POINTS_TO_CONTACT,
  ADD_CONTACTS_TO_CAMPAIGN,
  FILTER_CONTACTS,
  CREATE_INSURANCE,
  UPDATE_INSURANCE,
  DELETE_INSURANCE,
  SET_INSURANCE,
  REMOVE_INSURANCE,
  EDIT_INSURANCE,
  CREATE_CONTACT_ADDRESS,
  UPDATE_CONTACT_ADDRESS,
  UPDATE_CONTACT_CARD,
  EXTERNAL_PLATFORM,
  DOWNLOAD_CONTACTS,
  ADD_TAGS_IN_BULK,
  REMOVE_TAGS_IN_BULK,
  SET_CONTACTS_FOR_TAGS_BULK,
  UPDATE_CONTACT_STATUS,
  REMOVE_FUNNEL_FROM_CONTACT,
  GET_CONTACTS_FOR_MERGING,
  SET_CONTACTS_FOR_MERGING,
  MERGE_CONTACTS,
  REMOVE_MERGED_CONTACT,
  UPDATE_MERGED_CONTACTS,
} from "../constants/Contact";

export const setContacts = (payload) => ({
  type: SET_CONTACTS,
  payload,
});

export const updateContacts = (payload) => ({
  type: UPDATE_CONTACTS,
  payload,
});

export const getContacts = (payload) => ({
  type: GET_CONTACTS,
  payload,
});

export const showMessage = (payload) => ({
  type: SHOW_MESSAGE,
  payload,
});

export const deleteContacts = (payload) => ({
  type: DELETE_CONTACTS,
  payload,
});

export const importContacts = (payload) => ({
  type: IMPORT_CONTACTS,
  payload,
});

export const importContactsResults = (payload) => ({
  type: IMPORT_CONTACTS_RESULT,
  payload
});

export const setImportStatusOK = (payload) => ({
  type: SET_IMPORT_STATUS_OK,
  payload,
});

export const importStarted = (payload) => ({
  type: IMPORT_STARTED,
  payload
});

export const changeImage = (payload) => ({
  type: CHANGE_IMAGE,
  payload,
});

export const changeImageSuccess = (payload) => ({
  type: CHANGE_IMAGE_SUCCESS,
  payload,
});

export const getSingleContact = (payload) => ({
  type: GET_SINGLE_CONTACTS,
  payload,
});

export const setSingleContact = (payload) => ({
  type: SET_SINGLE_CONTACTS,
  payload,
});

export const editSingleContact = (payload) => ({
  type: EDIT_SINGLE_CONTACTS,
  payload,
});

export const removeFromFunnel = (payload) => ({
  type: REMOVE_FROM_FUNNEL,
  payload,
});

export const storeContacts = (payload, messageSuccess) => ({
  type: STORE_CONTACTS,
  payload,
  messageSuccess,
});

export const setContactToFunnel = (payload, id) => ({
  type: SET_CONTACT_TO_FUNNEL,
  payload,
  id,
});

export const updateContactFunnel = (payload) => ({
  type: UPDATE_CONTACT_FUNNEL,
  payload,
});

export const setUpdateSuccessIndicator = () => ({
  type: SET_UPDATE_SUCCESS_INDICATOR,
});

export const getTags = (payload) => ({
  type: GET_TAGS,
  payload,
});

export const setTags = (payload) => ({
  type: SET_TAGS,
  payload,
});

export const setLoadingContacts = (payload) => ({
  type: SET_LOADING_CONTACTS,
  payload,
});

export const setContactsOrder = (payload) => ({
  type: SET_CONTACTS_ORDER,
  payload,
});

export const unsubscribeContact = (data, setIsUnsubscribe, successMessage) => ({
  type: UNSUBSCRIBE_CONTACT,
  data,
  setIsUnsubscribe,
  successMessage,
});

export const deletContactsFromConversation = (payload) => ({
  type: REMOVE_CONTACTS_FROM_CONVERSATION,
  payload,
});

export const updateContactInformation = () => ({
  type: UPDATE_LOYALTY_CONTACT_INFO,
});

export const contactPartialUpdate = (payload, successMessage) => ({
  type: CONTACT_PARTIAL_UPDATE,
  payload,
  successMessage,
});

export const addPointsToContact = (payload) => ({
  type: ADD_POINTS_TO_CONTACT,
  payload,
});

export const addContactsToCampaign = (payload, campaignId) => ({
  type: ADD_CONTACTS_TO_CAMPAIGN,
  payload,
  campaignId,
});

export const filterContacts = (payload) => ({
  type: FILTER_CONTACTS,
  payload,
});

export const setInsurance = (payload) => ({
  type: SET_INSURANCE,
  payload,
});

export const editInsurance = (payload) => ({
  type: EDIT_INSURANCE,
  payload,
});

export const removeInsurance = (payload) => ({
  type: REMOVE_INSURANCE,
  payload,
});

export const createInsurance = (id, payload, successMessage) => ({
  type: CREATE_INSURANCE,
  id,
  payload,
  successMessage,
});

export const updateInsurace = (id, payload, successMessage) => ({
  type: UPDATE_INSURANCE,
  id,
  payload,
  successMessage,
});

export const deleteInsurace = (id, successMessage) => ({
  type: DELETE_INSURANCE,
  id,
  successMessage,
});

export const createContactAddress = (id, payload) => ({
  type: CREATE_CONTACT_ADDRESS,
  id,
  payload,
});

export const updateContactAddress = (id, payload) => ({
  type: UPDATE_CONTACT_ADDRESS,
  id,
  payload,
});

export const updateContactCard = (id, payload, successMessage) => ({
  type: UPDATE_CONTACT_CARD,
  id,
  payload,
  successMessage,
});

export const updateExternalPlatform = (id, payload, successMessage) => ({
  type: EXTERNAL_PLATFORM,
  id,
  payload,
  successMessage,
});

export const downloadContacts = (successMessage) => ({
  type: DOWNLOAD_CONTACTS,
  successMessage,
});

export const addTagsInBulk = (payload, successMessage) => ({
  type: ADD_TAGS_IN_BULK,
  payload,
  successMessage,
});

export const removeTagsInBulk = (payload, successMessage) => ({
  type: REMOVE_TAGS_IN_BULK,
  payload,
  successMessage,
});

export const setContactsForTagsBulk = (payload) => ({
  type: SET_CONTACTS_FOR_TAGS_BULK,
  payload,
});

export const updateContactStatus = (payload, successMessage, closeModal) => ({
  type: UPDATE_CONTACT_STATUS,
  payload,
  successMessage,
  closeModal,
});

export const removeFunnelFromContact = (payload) => ({
  type: REMOVE_FUNNEL_FROM_CONTACT,
  payload,
});

export const getContactsForMerging = (payload) => ({
  type: GET_CONTACTS_FOR_MERGING,
  payload,
});

export const setContactsForMerging = (payload) => ({
  type: SET_CONTACTS_FOR_MERGING,
  payload,
});

export const mergeContacts = (payload, closeModal, messageSuccess) => ({
  type: MERGE_CONTACTS,
  payload,
  closeModal,
  messageSuccess,
});

export const removeMergedContact = (payload) => ({
  type: REMOVE_MERGED_CONTACT,
  payload,
});

export const updateMergedContact = (payload) => ({
  type: UPDATE_MERGED_CONTACTS,
  payload,
});