import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  APP_LOYALTY_PREFIX_PATH,
  AUTH_LOYALTY_PREFIX_PATH,
  PUBLIC_PREFIX_PATH,
  AGENCY_PREFIX_PATH,
} from "./configs/AppConfig";

export const ROUTES = {
  LOGIN: `${AUTH_PREFIX_PATH}/login`,
  REGISTER: `${AUTH_PREFIX_PATH}/register`,
  FORGOT_PASSWORD: `${AUTH_PREFIX_PATH}/forgot-password`,
  CREATE_PASSWORD: `${AUTH_PREFIX_PATH}/create-password`,
  CONTACTS: `${APP_PREFIX_PATH}/contacts`,
  SINGLE_CONTACT: `${APP_PREFIX_PATH}/contacts/%s`,
  CHANGE_PASSWORD: `${APP_PREFIX_PATH}/change-password`,
  CALENDAR: `${APP_PREFIX_PATH}/calendar`,
  HTML_BUILDER: `${APP_PREFIX_PATH}/email-builder`,
  HTML_EDITOR: `${APP_PREFIX_PATH}/email-builder/builder`,
  HTML_EDIT_TEMPLATE: `${APP_PREFIX_PATH}/email-builder/%s`,
  EMAIL_TEMPLATES: `${APP_PREFIX_PATH}/email-builder/templates`,
  EMAIL_FORM_TYPE_EDITOR: `${APP_PREFIX_PATH}/email-builder/form-type-builder/%s`,
  EMAIL_FORM_TYPE_EDITOR_EDIT: `${APP_PREFIX_PATH}/email-builder/template/%s/email/%s`,
  CAMPAINGS: `${APP_PREFIX_PATH}/campaigns`,
  SMS_EDITOR: `${APP_PREFIX_PATH}/sms-builder`,
  CAMPAINGS_SINGLE_PAGE: `${APP_PREFIX_PATH}/campaigns/%s`,
  LANDING_PAGE_TEMPLATES: `${APP_PREFIX_PATH}/landing-page/templates`,
  SINGLE_LANDING_PAGE: `${APP_PREFIX_PATH}/landing-page/%s`,
  LANDING_PAGE: `${APP_PREFIX_PATH}/landing-page/`,
  SETTINGS: `${APP_PREFIX_PATH}/settings`,
  LANDING_PAGE_BUILDER: `${APP_PREFIX_PATH}/landing-page-builder/%s`,
  FORM_BUILDER: `${APP_PREFIX_PATH}/forms/builder/%s`,
  FORMS_PAGE: `${APP_PREFIX_PATH}/forms`,
  EDIT_PROFILE: `${APP_PREFIX_PATH}/edit-profile`,
  EDIT_PASSWORD: `${APP_PREFIX_PATH}/edit-password`,
  NOT_FOUND: `${APP_PREFIX_PATH}/not-found`,
  SINGLE_DOCTOR_PAGE: `${APP_PREFIX_PATH}/practice-staff/%s`,
  CREATE_DOCTOR_PAGE: `${APP_PREFIX_PATH}/practice-staff/create`,
  DOCTORS_PAGE: `${APP_PREFIX_PATH}/practice-staff`,
  DASHBOARD: `${APP_PREFIX_PATH}/dashboard`,
  VERIFICATION_CODE: `${AUTH_PREFIX_PATH}/verification-code`,
  PRACTICE_INFO: `${APP_PREFIX_PATH}/practice-info`,
  CHAT_WIDGET: `${APP_PREFIX_PATH}/chat-widget`,
  CONVERSATIONS: `${APP_PREFIX_PATH}/conversations`,
  SINGLE_USER_AWARDS_PAGE: `${APP_PREFIX_PATH}/awards/single-user/%s`,
  NEW_AWARD_PAGE: `${APP_PREFIX_PATH}/awards/create-award/`,
  SINGLE_AWARD: `${APP_PREFIX_PATH}/awards/%s`,
  AWARDS: `${APP_PREFIX_PATH}/awards`,
  LOYALTY_PAGE: `${APP_LOYALTY_PREFIX_PATH}/home`,
  LOYALTY_LOGIN: `${AUTH_LOYALTY_PREFIX_PATH}/login`,
  CREATE_PASSWORD_LOYALTY_USER: `${AUTH_LOYALTY_PREFIX_PATH}/create-password`,
  LOYALTY_SELECT_ORGANIZATION: `${AUTH_LOYALTY_PREFIX_PATH}/select-organization`,
  LOYALTY_USER_EDIT_PROFILE: `${APP_LOYALTY_PREFIX_PATH}/edit-profile`,
  LOYALTY_USER_EDIT_PASSWORD: `${APP_LOYALTY_PREFIX_PATH}/edit-password`,
  LOYALTY_USER_FORGOT_PASSWORD: `${AUTH_LOYALTY_PREFIX_PATH}/forgot-password`,
  DOCUMNETS: `${APP_PREFIX_PATH}/documents`,
  CAMPAINGS_CONTACTS: `${APP_PREFIX_PATH}/campaigns/contacts/%s`,
  EQUIPMENT_PAGE: `${APP_PREFIX_PATH}/rooms`,
  SCHEDULER: `${APP_PREFIX_PATH}/scheduler`,
  SCHEDULER_PUBLIC_EXPIRED: `${PUBLIC_PREFIX_PATH}/scheduler/link/expired`,
  CONSULT_CONFIG: `${APP_PREFIX_PATH}/virtual-consult`,
};

export const AGENCY_ROUTES = {
  CLONE: `${AGENCY_PREFIX_PATH}/clone`,
  COPY: `${AGENCY_PREFIX_PATH}/copy`,
};
