import { AGENCY_PREFIX_PATH } from "configs/AppConfig";
import { CopyOutlined } from "@ant-design/icons";
import translations from "./agencyNavTranslations";
import AppLocale from "./../lang/index";

const chosenLang = AppLocale.en.locale;

const dashBoardNavTree = [
  {
    key: "agency",
    path: `${AGENCY_PREFIX_PATH}/clone`,
    title: translations[0][chosenLang],
    breadcrumb: false,
    submenu: [],
    icon: CopyOutlined,
  },
];

const agencyNavigationConfig = [...dashBoardNavTree];

export default agencyNavigationConfig;
