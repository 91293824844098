class StripeProductStateManager {
  copyProducts(oldProducts) {
    return { ...oldProducts };
  }

  copyPrices(oldPrices) {
    return [...oldPrices];
  }

  findProduct(productList, productId) {
    return productList.find((product) => product?.id == productId);
  }

  removePrice(priceList, priceToRemove) {
    return priceList.filter((price) => price.id != priceToRemove);
  }

  updateProductPriceSet(product, newPrice) {
    product.invoiceprice_set.push(newPrice);
  }

  overrideProductPriceSet(product, priceSet, newPrice) {
    if (newPrice) {
      priceSet.push(newPrice);
    }
    product.invoiceprice_set = priceSet;
  }

  createNewPriceTemplate(newData, oldData) {
    const { productId, data } = newData;
    const newProducts = this.copyProducts(oldData);
    const product = this.findProduct(newProducts?.results, productId);
    this.updateProductPriceSet(product, data);
    return newProducts;
  }

  updateExistingPriceTemplate(newData, oldData) {
    const { productId, data, priceId } = newData;
    const newProducts = this.copyProducts(oldData);
    const product = this.findProduct(newProducts?.results, productId);
    const newPrices = this.copyPrices(product?.invoiceprice_set);
    const filteredPrices = this.removePrice(newPrices, priceId);
    this.overrideProductPriceSet(product, filteredPrices, data);
    return newProducts;
  }
}

const productManager = new StripeProductStateManager();
export default productManager;
