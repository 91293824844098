export const MAX_TEMPLATE_IMAGE = 2000000;
export const MAX_SMS_IMAGE = 5000000;
export const MAX_IMAGE_COUNT = 1;

export const TEMPLATE_TYPE = {
  EMAIL: "email",
  SMS: "sms",
};

export const DEFAULT_TEMPLATE = 1;
export const NUMBER_PER_PAGE = 10;
export const MAX_CHARACTERS_MESSAGE = 1500;
export const MAX_CHARACTERS_NAME = 255;
export const MAX_EMAIL_IMAGE_SIZE = 5 * 1024 * 1024;
export const MAX_CHARACTERS_TITLE = 100;
export const MAX_BUTTON_TEXT = 100;
export const MAX_CHARACTERS_CONTENT = 500;
export const MAX_TOTAL_CHARACTERS_CONTENT = 1500;
export const IMAGE_TYPE_CUSTOM = "custom";
export const IMAGE_TYPE_STAFF = "staff";
export const IMAGE_TYPE_TREATMENT = "treatment";
export const DEFAULT_EMAIL_BACKGROUND_COLOR = "#FFFFFF";
export const DEFAULT_EMAIL_TITLE =
  "This is a placeholder for email title, you can change it here";
export const DEFAULT_EMAIL_SUBTITLE =
  "This is a placeholder for email subtitle, you can change it here";
export const DEFAULT_EMAIL_BUTTON = "Check our website";
export const DEFAULT_EMAIL_CONTENT = `This is a placeholder for information You want to send to Your contact list. It can be a short description of a treatment or of your practice, or any kind of information You want to present to Your contacts. This content can be up to 500 chars.`;
export const DEFAULT_EMAIL_SUBCONTENT = `This is a placeholder for information You want to send to Your contact list. It can be a short description of a treatment or of your practice, or any kind of information You want to present to Your contacts. This content can be up to 500 chars.`;
export const DEFAULT_FONT_COLOR = "#545353";
export const DEFAULT_WEBSITE_TYPE = "custom";
export const DEFAULT_BUTTON_COLOR = "#FDFCFC";

export const webSiteOptions = [
  {
    label: "Custom",
    value: "custom",
  },
  {
    label: "Organization",
    value: "organization",
  },
];

export const MASS_EMAIL_MAX_NUMBER_OF_CONTACTS = 1000;
export const MASS_SMS_MAX_NUMBER_OF_CONTACTS = 1000; // after implementing drip mode, this number are increased from 100 to 1000

export const MODAL_TYPE = {
  SMS: "sms",
  EMAIL: "email",
};

export const CONTACT_EMAIL_ANALYTICS_PAGE_SIZE = 5;

export const DELIVERED = "delivered";
export const FAILED = "failed";

export const EMAIL_STATUS_COLOR_MAP = {
  [DELIVERED]: "#87d068",
  [FAILED]: "#f50",
  DEFAULT: "#ffd95a",
};

export const DEFUALT_OPENED_DESCEND_ORDER = "-contactemailanalytics__opened";

export const ORDER_FIELDS = {
  opened: "contactemailanalytics__opened",
  clicked: "contactemailanalytics__clicked",
};
export const ASCEND_SORT = "ascend";
