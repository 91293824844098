import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  GET_DOCUMENTS: "documents/?page=%s",
  UPLOAD_DOCUMENT: "documents/",
  RENAME_DOCUMENT: "documents/%s/",
  DELETE_DOCUMENT: "documents/%s/",
  SEND_MASS_DOCUMENT: "documents/send-mass-email-documents/",
  GET_CONTACT_DOCUMENTS: "contacts/%s/documents/contact_documents/?page=%s",
  GET_DOCUMENT_RECIPIENTS: "documents/%s/documents-info/?ordering=%s&page=%s",
};

class DocumentsService extends ApiService {
  getAllDocuments = async ({ page }) => {
    return await this.apiClient.get(format(ENDPOINTS.GET_DOCUMENTS, page));
  };

  uploadDocument = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.UPLOAD_DOCUMENT, payload, {
      headers: {
        "content-type": "multipart/form-data;",
      },
    });
  };

  renameDocument = async (id, data) => {
    return await this.apiClient.put(
      format(ENDPOINTS.RENAME_DOCUMENT, id),
      data
    );
  };

  deleteDocument = async (id) => {
    return await this.apiClient.delete(format(ENDPOINTS.DELETE_DOCUMENT, id));
  };

  sendMassDoucment = async (data) => {
    return await this.apiClient.post(ENDPOINTS.SEND_MASS_DOCUMENT, data);
  };

  getContactDocuments = async (contactId, page) => {
    return await this.apiClient.get(
      format(ENDPOINTS.GET_CONTACT_DOCUMENTS, contactId, page)
    );
  };

  getDocumentRecipients = async (id, order, page) => {
    return await this.apiClient.get(format(ENDPOINTS.GET_DOCUMENT_RECIPIENTS, id, order, page));
  };
}

export default new DocumentsService();
