const updateActivityHistoryList = (allActivityHistory, data) => {
  let appointemntIndex = allActivityHistory.results.findIndex(
    (item) => item?.content_object?.id == data?.id
  );

  allActivityHistory.results[appointemntIndex].content_object = data;
};

export const updateActivityList = (allActivityHistory, payload) => {
  !payload.cancel &&
    updateActivityHistoryList(allActivityHistory, payload.data);

  if (payload.cancel) {
    updateActivityHistoryList(allActivityHistory, payload.data.content_object);

    allActivityHistory.results.unshift(payload.data);
  }
};
