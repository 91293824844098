import navigationConfig from "configs/NavigationConfig";
import agencyNavigationConfig from "configs/AgencyNavigationConfig";

export const redirectPage = (user_access_list = []) => {
  const navConfig = user_access_list.includes("agency")
    ? agencyNavigationConfig
    : navigationConfig;

  const res = navConfig.find((item) =>
    user_access_list.some((sector) => sector === item.key)
  );

  return res?.submenu.length ? res?.submenu?.[0]?.path : res?.path;
};
