import produce from "immer";
import {
  ADD_NEW_NOTE,
  REMOVE_NOTE,
  SET_CONTACT_NOTES_BY_PAGE,
  SET_UPDATED_NOTE,
} from "redux/constants/Notes";

export const initialState = {
  contactNotes: {},
};

const notesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_CONTACT_NOTES_BY_PAGE:
        draft.contactNotes[payload.id] = {
          count: payload.data.count,
          page: payload.currentPage,
          current: payload.currentPage,
          next: payload.data.next,
          previous: payload.data.previous,
          results:
            payload.currentPage === 1
              ? payload.data.results
              : [...draft.contactNotes[payload.id].results, ...payload.data.results],
          isLoading: false,
        };
        break;
      case ADD_NEW_NOTE:
        draft.contactNotes[payload.data.contact.id].results.unshift(payload.data);
        draft.contactNotes[payload.data.contact.id].count += 1;
        break;
      case REMOVE_NOTE:
        let indexDelete = draft.contactNotes[payload.contactId].results.findIndex(
          (el) => el.id === payload.id
        );
        draft.contactNotes[payload.contactId].results = draft.contactNotes[
          payload.contactId
        ].results
          .slice(0, indexDelete)
          .concat(
            draft.contactNotes[payload.contactId].results.slice(
              indexDelete + 1,
              draft.contactNotes[payload.contactId].results.length
            )
          );
        break;
      case SET_UPDATED_NOTE:
        let indexItem = draft.contactNotes[payload.contact.id].results.findIndex(
          (el) => el.id === payload.id
        );
        draft.contactNotes[payload.contact.id].results[indexItem] = payload;
        draft.contactNotes[payload.contact.id].results = draft.contactNotes[
          payload.contact.id
        ].results.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        break;
      default:
        return state;
    }
  });

export default notesReducer;
