import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  CREATE_AWARD: "awards/",
  GET_AWARDS: "awards/?page=%s",
  GET_SINGLE_AWARD: "awards/%s/",
  EDIT_AWARD: "awards/%s/",
  DELETE_AWARD: "awards/%s/",
  ADD_AWARD_TO_USER: "awards/%s/",
  USER_LIST: "/potential_users/awards/%s/?page=%s&search=%s",
  SINGLE_USER_AWARDS: "users/%s/awards/%s/loyalty/",
  ADD_POINTS: "users/%s/awards/%s/points/%s/",
  REDEEM_AWARD: "users/%s/awards/%s/redeem_award/",
  ARCHIVE_AWARD: "award/%s/arhive/",
  GET_ARCHIVED_AWARDS: "award/?page=%s",
  ACTIVATE_AWARD: "award/%s/activate/",
  GET_SINGLE_ARCHIVED_AWARD: "award/%s/",
  GET_AWARD_ACTIVE_POINTS: "awards/active/collectable/?page=%s",
  REENROLL_TO_AWARD: "reward/%s/re-enroll/",
  AVAILABLE_AWARDS:"awards/%s/available/?page=%s"
};

class AwardsService extends ApiService {
  getAwards = ({ payload }) => {
    return this.apiClient.get(format(ENDPOINTS.GET_AWARDS, payload));
  };

  createAwards = (payload) => {
    return this.apiClient.post(ENDPOINTS.CREATE_AWARD, payload);
  };

  getSingleAward = ({ payload }) => {
    return this.apiClient.get(format(ENDPOINTS.GET_SINGLE_AWARD, payload));
  };

  editAward = (payload) => {
    return this.apiClient.put(format(ENDPOINTS.EDIT_AWARD, payload.id), payload);
  };

  addAwardToUser = (payload) => {
    return this.apiClient.patch(format(ENDPOINTS.ADD_AWARD_TO_USER, payload.awardId), payload);
  };

  getUserList = (payload) => {
    const { awardId, page, search } = payload;
    return this.apiClient.get(format(ENDPOINTS.USER_LIST, awardId, page, search));
  };

  getSingleUserAwards = (payload) => {
    return this.apiClient.get(format(ENDPOINTS.SINGLE_USER_AWARDS, payload?.id, payload?.organization));
  };

  addPoints = (payload) => {
    const { userId, awardId, pointsId } = payload;
    return this.apiClient.patch(format(ENDPOINTS.ADD_POINTS, userId, awardId, pointsId), {
      new_points: payload.new_points,
      amount_spent: payload?.amount_spent,
    });
  };

  redeemAward = (payload) => {
    const { userId, pointsId } = payload;
    return this.apiClient.post(format(ENDPOINTS.REDEEM_AWARD, userId, pointsId), {
      redeem_code: payload.redeemCode,
    });
  };

  deleteAward = (payload) => {
    return this.apiClient.delete(format(ENDPOINTS.DELETE_AWARD, payload));
  };

  archiveAward = (payload) => {
    return this.apiClient.patch(format(ENDPOINTS.ARCHIVE_AWARD, payload));
  };

  getArchivedAwards = ({ payload }) => {
    return this.apiClient.get(format(ENDPOINTS.GET_ARCHIVED_AWARDS, payload));
  };

  activteAward = (payload, awardId) => {
    return this.apiClient.patch(format(ENDPOINTS.ACTIVATE_AWARD, awardId), payload);
  };

  getSingleArchivedAward = (payload) => {
    return this.apiClient.get(format(ENDPOINTS.GET_SINGLE_ARCHIVED_AWARD, payload));
  };

  getAwardsActivePoints = ({ payload }) => {
    return this.apiClient.get(format(ENDPOINTS.GET_AWARD_ACTIVE_POINTS, payload));
  };

  reEnrollToAward = (payload) => {
    return this.apiClient.patch(format(ENDPOINTS.REENROLL_TO_AWARD, payload));
  };

  getAvailableAwards=({id, page}) => {
    return this.apiClient.get(format(ENDPOINTS.AVAILABLE_AWARDS, id, page));
  };
}

export default new AwardsService();
