import ApiService from "./ApiService";
import { format } from "util";
import { stringify } from "querystring";

const ENDPOINTS = {
  GET_COMMUNICATION_ACTIVITIES:
    "contacts/%s/activities/?page=%s&perPage=%s&search=%s",
  SEND_SMS: "twilio/send-message/",
  SEND_EMAIL: "email/send-email/",
  GET_CHILDREN: "email/%s/children/",
  GET_TOKEN_PHONE_CALL: "phonecall/token/",
  END_CALL: "phonecall/call-end/",
  GET_HISTORY_ACTIVITIES: "contacts/%s/history/?page=%s&perPage=%s&search=%s",
  GET_LOYALTY_ACTIVITIES: "loyalty/%s/activities/?page=%s&perPage=%s&search=%s",
  SEND_FACEBOOK_MESSAGE: "messenger/send_message/",
  SEND_INSTAGRAM_MESSAGE: "instagram/send_message/",
  GET_ACTIVITY_SUMMARY: "summary/",
};

class ActivityService extends ApiService {
  getCommunicationActivities = async (page, perPage, id, search) => {
    search = search.toString().trim();
    return await this.apiClient.get(
      format(ENDPOINTS.GET_COMMUNICATION_ACTIVITIES, id, page, perPage, search)
    );
  };

  sendSMS = async (data) => {
    return await this.apiClient.post(ENDPOINTS.SEND_SMS, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  sendEmail = async (data) => {
    return await this.apiClient.post(ENDPOINTS.SEND_EMAIL, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  getChildrenEmail = async (id) => {
    return await this.apiClient.get(format(ENDPOINTS.GET_CHILDREN, id));
  };

  getActivityHistory = async (page, perPage, id, content) => {
    content = content.toString().trim();
    return await this.apiClient.get(
      format(ENDPOINTS.GET_HISTORY_ACTIVITIES, id, page, perPage, content)
    );
  };

  getLoyaltyActivityHistory = async (page, perPage, id, content) => {
    content = content.toString().trim();
    return await this.apiClient.get(
      format(ENDPOINTS.GET_LOYALTY_ACTIVITIES, id, page, perPage, content)
    );
  };

  getTokenPhoneCall = async () => {
    return await this.apiClient.get(ENDPOINTS.GET_TOKEN_PHONE_CALL);
  };

  endCall = async () => {
    return await this.apiClient.get(ENDPOINTS.END_CALL);
  };

  sendFacebookMsg = (payload) => {
    return this.apiClient.post(ENDPOINTS.SEND_FACEBOOK_MESSAGE, payload);
  };

  sendInstagramMsg = (payload) => {
    return this.apiClient.post(ENDPOINTS.SEND_INSTAGRAM_MESSAGE, payload);
  };

  getActivitySummary = (payload) => {
    return this.apiClient.get(
      `${ENDPOINTS.GET_ACTIVITY_SUMMARY}?${stringify(payload)}`
    );
  };
}

export default new ActivityService();
