import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  GET_ALL_PER_PAGE_DOMAINS: "domain/?page=%s&perPage=%s",
  GET_ALL_LANDING_PAGE_PER_PAGE: "landing-page/?page=%s&perPage=%s&search=%s",
  GET_LANDING_PAGE_TEMPLATES: "default-template/",
  POST_DOMAIN: "domain/",
  POST_LANDING_PAGE: "landing-page/",
  DOMAIN: "domain/%s/",
  CLONE_LANDING_PAGE: "landing-page/%s/clone-page/",
  LANDING_PAGE: "landing-page/%s/",
  STEP: "landing-page/%s/step/%s/",
  STEPS_BY_PAGE: "landing-page/%s/step/",
  REORDER_STEPS: "landing-page/%s/step/reorder/",
  CLONE_STEP: "landing-page/%s/clone-step/",
  CREATE_LANDING_PAGE_CONTENT: "landing_page_content/",
  UPDATE_LANDING_PAGE_CONTENT: "landing_page_content/%s/"
};

class WebPageService extends ApiService {
  postNewDomain = (data) => {
    return this.apiClient.post(ENDPOINTS.POST_DOMAIN, data);
  };

  getDomainsForPage = ({ page, perPage }) => {
    return this.apiClient.get(
      format(ENDPOINTS.GET_ALL_PER_PAGE_DOMAINS, page, perPage)
    );
  };

  deleteDomain = (id) => {
    return this.apiClient.delete(format(ENDPOINTS.DOMAIN, id));
  };

  updateDomain = (data) => {
    return this.apiClient.put(format(ENDPOINTS.DOMAIN, data.id), data.payload);
  };

  getLandingPageForPage = ({ page, perPage, search }) => {
    return this.apiClient.get(
      format(ENDPOINTS.GET_ALL_LANDING_PAGE_PER_PAGE, page, perPage, search)
    );
  };

  cloneLandingPage = ({ id, data }) => {
    return this.apiClient.post(format(ENDPOINTS.CLONE_LANDING_PAGE, id), data);
  };

  postLandingPage = (data) => {
    return this.apiClient.post(ENDPOINTS.POST_LANDING_PAGE, data);
  };

  getLandingPageById = ({ id }) => {
    return this.apiClient.get(format(ENDPOINTS.LANDING_PAGE, id));
  };

  deleteLandingPage = (id) => {
    return this.apiClient.delete(format(ENDPOINTS.LANDING_PAGE, id));
  };

  updateLandingPage = (data) => {
    return this.apiClient.put(
      format(ENDPOINTS.LANDING_PAGE, data.id),
      data.payload
    );
  };

  getStepById = (data) => {
    return this.apiClient.get(format(ENDPOINTS.STEP, data.pageId, data.id));
  };

  updateStep = (data) => {
    return this.apiClient.put(
      format(ENDPOINTS.STEP, data.pageId, data.id),
      data.payload
    );
  };

  getAllStepsByPage = (id) => {
    return this.apiClient.get(format(ENDPOINTS.STEPS_BY_PAGE, id));
  };

  deleteStep = ({ id, pageId }) => {
    return this.apiClient.delete(format(ENDPOINTS.STEP, pageId, id));
  };

  reorderSteps = (data, id) => {
    return this.apiClient.patch(format(ENDPOINTS.REORDER_STEPS, id), data);
  };

  cloneStep = (data) => {
    return this.apiClient.post(
      format(ENDPOINTS.CLONE_STEP, data.landing_page),
      data
    );
  };

  addNewStep = ({ id, data }) => {
    return this.apiClient.post(format(ENDPOINTS.STEPS_BY_PAGE, id), data);
  };

  getLandingPageTemplates = () => {
    return this.apiClient.get(format(ENDPOINTS.GET_LANDING_PAGE_TEMPLATES));
  }

  createLandingPageContent = (data) => {
    return this.apiClient.post(ENDPOINTS.CREATE_LANDING_PAGE_CONTENT, data);
  }

  updateLandingPageContent = (data, id) => {
    return this.apiClient.put(format(ENDPOINTS.UPDATE_LANDING_PAGE_CONTENT, id), data);
  }
}

export default new WebPageService();
