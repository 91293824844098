import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  EVENTS: "campaigns/%s/events/",
  REORDER_EVENTS: "campaigns/%s/events/reorder/",
  CHANGE_EVENT: "campaigns/%s/events/%s/",
  COUNTDOWN_EVENT: "campaigns/countdown/%s/events/",
  CHANGE_COUNTDOWN_EVENT: "campaigns/countdown/%s/events/%s/",
};

class EventService extends ApiService {
  getEvents = async (id) => {
    return await this.apiClient.get(format(ENDPOINTS.EVENTS, id));
  };
  reorderEvents = async (data, id) => {
    return await this.apiClient.patch(
      format(ENDPOINTS.REORDER_EVENTS, id),
      data
    );
  };

  postNewEvent = async (id, data) => {
    return await this.apiClient.post(format(ENDPOINTS.EVENTS, id), data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  deleteEvent = async (campaignId, eventId) => {
    return await this.apiClient.delete(
      format(ENDPOINTS.CHANGE_EVENT, campaignId, eventId)
    );
  };

  changeEventTime = async (campaignId, eventId, data) => {
    return await this.apiClient.patch(
      format(ENDPOINTS.CHANGE_EVENT, campaignId, eventId),
      data
    );
  };

  postCountdownEvent = async ({ id, data }) => {
    return await this.apiClient.post(
      format(ENDPOINTS.COUNTDOWN_EVENT, id),
      data
    );
  };

  editCountdownEvent = async ({ campaignId, eventId, data }) => {
    return await this.apiClient.patch(
      format(ENDPOINTS.CHANGE_COUNTDOWN_EVENT, campaignId, eventId),
      data
    );
  };
}

export default new EventService();
