export const TAB_1 = "1";
export const TAB_2 = "2";
export const TAB_4 = "4";
export const TAB_5 = "5";
export const TAB_6 = "6";
export const TAB_7 = "7";
export const TAB_8 = "8";
export const TAB_9 = "9";

export const MAX_BUSINESS_NAME_LENGTH = 100;

export const SUPPORTING_NETWORKS = ["google", "facebook", "yelp"];
export const NETWORK_IMAGE_MAP = {
  google: "/img/googleLogo.png",
  facebook: "/img/facebookLogo.png",
  yelp: "/img/yelpLogo.png",
};

export const WEBSITE_MAX_LENGHT = 242;

export const MODE_TYPE = {
  PHONE_NUMBER: "Phone number",
  BRAND_REGISTRATION: "Brand registration",
};

export const TABS = {
  PHONE_NUMBER: "Phone number",
  BRAND_REGISTRATION: "Brand registration",
};

export const TAB_TO_MODE = {
  [TABS.PHONE_NUMBER]: MODE_TYPE.PHONE_NUMBER,
  [TABS.BRAND_REGISTRATION]: MODE_TYPE.BRAND_REGISTRATION,
};

export const ADDITIONAL_EMAIL_ADDRESS_MAX = 3;

export const PAYEMENT_PROCESSOR_GUIDE = "payment-processor-guide";
