import {
  GET_CONTACT_SETTINGS,
  SET_CONTACT_SETTINGS,
  UPDATE_CONTACT_SETTINGS,
  DELETE_CONTACT_SETTINGS,
  STORE_CONTACT_SETTINGS,
  SHOW_MESSAGE,
} from "../constants/ContactSettings";

export const getContactSettings = (payload) => {
  return {
    type: GET_CONTACT_SETTINGS,
    payload: payload,
  };
};

export const setContactSettings = (payload) => {
  return {
    type: SET_CONTACT_SETTINGS,
    payload: payload,
  };
};

export const updateContactSettings = (payload) => {
  return {
    type: UPDATE_CONTACT_SETTINGS,
    payload: payload,
  };
};

export const storeContactSettings = (payload) => {
  return {
    type: STORE_CONTACT_SETTINGS,
    payload: payload,
  };
};

export const deleteContactSettings = (payload) => {
  return {
    type: DELETE_CONTACT_SETTINGS,
    payload: payload,
  };
};

export const showMessage = (payload) => ({
  type: SHOW_MESSAGE,
  payload,
});
