import ApiService from "./ApiService";
import { format } from "util";
import { NUMBER_PER_PAGE } from "constants/DomainConstants";
import { ORDERING } from "constants/ApiConstant";

const ENDPOINTS = {
  GET_ALL_FORMS_PAGINATED: "forms/",
  GET_ALL_FORMS: "all-forms/",
  CREATE_NEW_FORM: "forms/",
  GET_SINGLE_FORM: "forms/%s/",
  UPDATE_FORM: "forms/%s/",
  DELETE_FORM: "forms/%s/",
  COPY_FORM: "forms/%s/copy-form/",
};

class FormBuilderService extends ApiService {
  getFormsPaginated = ({
    page = 1,
    perPage = NUMBER_PER_PAGE,
    search = "",
    order = "",
    orderField = "",
  }) =>
    this.apiClient.get(ENDPOINTS.GET_ALL_FORMS_PAGINATED, {
      params: {
        page,
        perPage,
        search,
        ordering: `${order === ORDERING.ASC ? "-" : ""}${orderField}`,
      },
    });

  getAllForms = () => {
    return this.apiClient.get(format(ENDPOINTS.GET_ALL_FORMS));
  };

  createForm = (data) => this.apiClient.post(ENDPOINTS.CREATE_NEW_FORM, data);

  getSingleForm = (data) =>
    this.apiClient.get(format(ENDPOINTS.GET_SINGLE_FORM, data));

  updateForm = (data, id) =>
    this.apiClient.put(format(ENDPOINTS.UPDATE_FORM, id), data);

  deleteForm = (id) => this.apiClient.delete(format(ENDPOINTS.DELETE_FORM, id));

  copyForm = (id, data) =>
    this.apiClient.post(format(ENDPOINTS.COPY_FORM, id), data);
}

export default new FormBuilderService();
