import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "routes";
import { makeSelectIsAuthenticated, makeSelectIsNewUser } from "selectors";
import { getLocalStorageItem } from "utils/localStorage";
import { makeSelectCurrentUser } from "redux/selectors/Users";
import { SELECTED_ORGANIZATION } from "constants/AuthConstants";

export function PrivatePatientRoute({ component: Component, type, ...rest }) {
  const isNewLoyaltyUser = useSelector(makeSelectIsNewUser());
  const isAuthenticated = useSelector(makeSelectIsAuthenticated());
  const user = useSelector(makeSelectCurrentUser());
  const loyalty_organization = user ? Object.values(user?.loyalty_organization) : [];
  const selectedOrganization = getLocalStorageItem(SELECTED_ORGANIZATION);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <>
            {loyalty_organization.length > 1 && !selectedOrganization && (
              <Redirect to={ROUTES.LOYALTY_SELECT_ORGANIZATION} />
            )}
            {isNewLoyaltyUser && <Redirect to={ROUTES.CREATE_PASSWORD_LOYALTY_USER} />}
            <Component {...props} />
          </>
        ) : (
          <Redirect to={ROUTES.LOYALTY_LOGIN} />
        )
      }
    />
  );
}

export default PrivatePatientRoute;
