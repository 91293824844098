import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import {
  GET_AVAILABLE_CONTACTS_FOR_LOYALTY_APP,
  POST_LOYALTY_USER,
  GET_LOYALTY_APP_CONTACTS,
} from "redux/constants/LoyaltyUsers";
import {
  getAvailableContactsForLoyaltyApp,
  getLoyaltyAppContacts,
  setAvailableContacts,
  setLoyaltyAppContacts,
} from "redux/actions/LoyaltyUsers";
import LoyaltyUsersService from "services/LoyaltyUsersService";
import { updateContactInformation } from "redux/actions/Contact";
import { FIRST_PAGE, NUMBER_PER_PAGE } from "constants/LoyaltyUsersConstants";

export function* getAvailableContactsSaga() {
  yield takeEvery(GET_AVAILABLE_CONTACTS_FOR_LOYALTY_APP, function* ({ payload }) {
    const { page, perPage, search, order, orderField } = payload;
    try {
      const contacts = yield call(
        LoyaltyUsersService.getAvailableContacts,
        page,
        perPage,
        search,
        order,
        orderField
      );
      const results = {
        data: contacts.data,
        order: order,
        orderField: orderField,
      };
      yield put(setAvailableContacts(results));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* postLoyaltyContactSaga() {
  yield takeEvery(POST_LOYALTY_USER, function* ({ payload, messageSuccess, getData }) {
    try {
      yield call(LoyaltyUsersService.createLoyaltyUser, payload);
      message.success(messageSuccess, 3);
      if (getData) {
        yield put(getAvailableContactsForLoyaltyApp(getData));
        yield put(
          getLoyaltyAppContacts({
            page: FIRST_PAGE,
            perPage: NUMBER_PER_PAGE,
            search: "",
          })
        );
      } else {
        yield put(updateContactInformation());
      }
    } catch (err) {
      message.error(err.response.data?.non_field_errors[0], 3);
      console.log(err);
    }
  });
}

export function* getLoyaltyAppContactsSaga() {
  yield takeEvery(GET_LOYALTY_APP_CONTACTS, function* ({ payload }) {
    const { page, perPage, search, order, orderField } = payload;
    try {
      const contacts = yield call(
        LoyaltyUsersService.getContactsInLoyaltyApp,
        page,
        perPage,
        search,
        order,
        orderField
      );
      const results = {
        data: contacts.data,
        order: order,
        orderField: orderField,
      };
      yield put(setLoyaltyAppContacts(results));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getAvailableContactsSaga), fork(postLoyaltyContactSaga), fork(getLoyaltyAppContactsSaga)]);
}
