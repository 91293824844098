export const CREATE_CHANGE_FUNNEL_TRIGGER = "CREATE_CHANGE_FUNNEL_TRIGGER";
export const SET_NEW_CHANGE_FUNNEL_TRIGGER = "SET_NEW_CHANGE_FUNNEL_TRIGGER";
export const GET_TRIGGERS_PAGINATION = "GET_TRIGGERS_PAGINATION";
export const SET_TRIGGERS_PAGINATION = "SET_TRIGGERS_PAGINATION";
export const DELETE_TRIGGER = "DELETE_TRIGGER";
export const GET_TRIGGER = "GET_TRIGGER";
export const SET_TRIGGER = "SET_TRIGGER";
export const EDIT_CHANGE_FUNNEL_TRIGGER = "EDIT_CHANGE_FUNNEL_TRIGGER";
export const SET_UPDATED_TRIGGER = "SET_UPDATED_TRIGGER";
export const CREATE_REACTIVATION_TRIGGER = "CREATE_REACTIVATION_TRIGGER";
export const EDIT_REACTIVATIONL_TRIGGER = "EDIT_REACTIVATIONL_TRIGGER";
export const GET_REACTIVATION_TRIGGER = "GET_REACTIVATION_TRIGGER";
export const UPDATE_SINGLE_TRIGGER = "UPDATE_SINGLE_TRIGGER";
