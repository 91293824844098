import {
  POST_NEW_SMS_TEMPLATE,
  DELETE_SMS_TEMPLATE,
  GET_ALL_SMS_TEMPLATES,
  SET_ALL_SMS_TEMPLATES,
  UPDATE_SMS_TEMPLATE_LIST,
  UPDATE_SMS_TEMPLATE,
  GET_SMS_TEMPLATE_BY_ID,
  PUSH_SMS_TEMPLATE_TO_LIST,
  TEST_SEND_SMS,
  SET_SELECTED_SMS_TEMPLATE,
  SEND_MASS_SMS,
  POST_NEW_TEMPLATE_AND_SEND_NOW,
  UPDATE_SMS_TEMPLATE_AND_SEND,
  CREATE_NEW_DEFAULT_TEMPLATE,
} from "./../constants/SmsBuilder";

export const postNewTemplate = (payload, messageSuccess, resetModal) => ({
  type: POST_NEW_SMS_TEMPLATE,
  payload,
  messageSuccess,
  resetModal,
});

export const deleteTemplate = (payload, pageNumber) => ({
  type: DELETE_SMS_TEMPLATE,
  payload,
  pageNumber,
});

export const getTemplatesForPage = (payload) => ({
  type: GET_ALL_SMS_TEMPLATES,
  payload,
});

export const setAllTemplates = (payload) => ({
  type: SET_ALL_SMS_TEMPLATES,
  payload,
});

export const updateTemplate = (id, payload, messageSuccess, resetModal) => ({
  type: UPDATE_SMS_TEMPLATE,
  id,
  payload,
  messageSuccess,
  resetModal,
});

export const updateTemplateList = (payload) => ({
  type: UPDATE_SMS_TEMPLATE_LIST,
  payload,
});

export const getTemplateById = (payload) => ({
  type: GET_SMS_TEMPLATE_BY_ID,
  payload,
});

export const pushTemplateToList = (payload) => ({
  type: PUSH_SMS_TEMPLATE_TO_LIST,
  payload,
});

export const testSendSms = (payload, messageSuccess, messageError) => ({
  type: TEST_SEND_SMS,
  payload,
  messageSuccess,
  messageError,
});

export const setSelectedTemplate = (payload) => ({
  type: SET_SELECTED_SMS_TEMPLATE,
  payload,
});

export const sendMassSMS = (payload, messageSuccess) => ({
  type: SEND_MASS_SMS,
  payload,
  messageSuccess,
});

export const postAndSendNowTemplate = (
  payload,
  messageSuccess,
  resetModal,
  setSendSMSModallVisible
) => ({
  type: POST_NEW_TEMPLATE_AND_SEND_NOW,
  payload,
  messageSuccess,
  resetModal,
  setSendSMSModallVisible,
});

export const updateAndSendNowTemplate = (
  id,
  payload,
  messageSuccess,
  resetModal,
  setSendSMSModallVisible
) => ({
  type: UPDATE_SMS_TEMPLATE_AND_SEND,
  id,
  payload,
  messageSuccess,
  resetModal,
  setSendSMSModallVisible,
});

export const createSpecialSmsTemplate = (payload, successMessage) => ({
  type: CREATE_NEW_DEFAULT_TEMPLATE,
  payload,
  successMessage
});
