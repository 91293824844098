export const GET_EVENTS = "GET_EVENTS";
export const SET_EVENTS = "SET_EVENTS";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const REORDER_EVENTS = "REORDER_EVENTS";
export const POST_EVENT = "POST_EVENT";
export const PUSH_NEW_EVENT = "PUSH_NEW_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const REMOVE_EVENT = "REMOVE_EVENT";
export const CHANGE_EVENT_DATA = "CHANGE_EVENT_DATA";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const SET_LOADING = "SET_LOADING";
export const UNSHIFT_NEW_EVENT = "UNSHIFT_NEW_EVENT";
