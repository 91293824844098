class Listener {
  constructor() {
    this.listeners = [];
  }

  addListener(listener) {
    this.listeners.push(listener);
  }

  clear() {
    this.listeners = [];
  }

  notify() {
    for (const el of this.listeners) {
      el();
    }
    this.clear();
  }
}

const listener = new Listener();

export default listener;
