import produce from "immer";
import { SET_TREATMENTS, SET_NEW_TREATMENT, SET_TREATMENT_IMAGES } from 'redux/constants/Treatment';

export const initialState = {
    treatments: [],
    newTreatment: null,
    treatmentImages: null
}

const treatmentReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        const { type, payload } = action;
        switch(type) {
            case SET_TREATMENTS:
                draft.treatments = payload;
                break;
            case SET_NEW_TREATMENT:
                draft.newTreatment = payload;
                break;
            case SET_TREATMENT_IMAGES:
                draft.treatmentImages = payload;
                break;
            default:
                return state;
        }
    });

export default treatmentReducer;