import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { Spin } from "antd";
import { makeSelectCurrentUser } from "redux/selectors/Users";
import { makeSelectIsAuthenticated } from "selectors";
import ErrorTwo from "views/auth-views/errors/error-page-2";

export function RoleRoute({ children, role, redirectRoute, ...props }) {
  const user = useSelector(makeSelectCurrentUser());
  const [roleHasPermission, setRoleHasPermission] = useState(user?.role?.access_type == role);
  const isAuthenticated = useSelector(makeSelectIsAuthenticated());

  useEffect(() => {
    setRoleHasPermission(role.includes(user?.role?.access_type ));
  }, [user]);

  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated ? (
          user?.role ? (
            roleHasPermission ? (
              children
            ) : (
              <ErrorTwo />
            )
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "600px",
              }}
            >
              <Spin size="large" />
            </div>
          )
        ) : (
          <Redirect to={redirectRoute} />
        )
      }
    />
  );
}
export default RoleRoute;
