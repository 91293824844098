import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { PUBLIC_PREFIX_PATH } from "configs/AppConfig";
import { PublicRoute } from "shared/PublicRoute";

export const PublicViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <PublicRoute
          path={`${PUBLIC_PREFIX_PATH}/virtual-consult/`}
          component={lazy(() => import(`./VirtualConsultPage`))}
        />
        <PublicRoute
          path={`${PUBLIC_PREFIX_PATH}/scheduler/:id`}
          component={lazy(() =>
            import(`../app-views/CalendarPage/Scheduler/PublicScheduler`)
          )}
          exact
        />
        <PublicRoute
          path={`${PUBLIC_PREFIX_PATH}/scheduler/link/expired`}
          component={lazy(() =>
            import(`../app-views/CalendarPage/Scheduler/PublicSchedulerExpired`)
          )}
          exact
        />
        <PublicRoute
          path={`${PUBLIC_PREFIX_PATH}/booking/scheduler`}
          component={lazy(() => import(`./BookingScheduler`))}
        />
      </Switch>
    </Suspense>
  );
};

export default PublicViews;
