export const GET_ALL_FORMS_PAGINATED = "GET_ALL_FORMS_PAGINATED";
export const SET_ALL_FORMS = "SET_ALL_FORMS";
export const GET_SINGLE_FORM = "GET_SINGLE_FORM";
export const SET_SINGLE_FORM = "SET_SINGLE_FORM";
export const DELETE_FORM = "DELETE_FORM";
export const UPDATE_FORM = "UPDATE_FORM";
export const SET_LOADING = "SET_LOADING";
export const SET_FORMS_PAGE = "SET_FORMS_PAGE";
export const SET_FORMS_ORDER = "SET_FORMS_ORDER";
export const GET_ALL_FORMS = "GET_ALL_FORMS";
export const SET_ALL_FORMS_NO_PAGINATION = "SET_ALL_FORMS_NO_PAGINATION";
export const CREATE_FORM = "CREATE_FORM";
export const RESET_SINGLE_FORM = "RESET_SINGLE_FORM";
export const COPY_FORM = "COPY_FORM";
export const RENAME_FORM = "RENAME_FORM";
export const RENAME_FORM_SUCCESS = "RENAME_FORM_SUCCESS";
