import produce from "immer";
import { SET_ALL_PIPELINES, SET_SINGLE_PIPELINE } from "redux/constants/Pipeline";

export const initialState = {
  pipelinesList: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
  },
  pipeline: {}
};

const pipelinesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_ALL_PIPELINES:
        draft.pipelinesList = {
          count: payload?.data?.count,
          current: payload?.data?.current,
          next: payload?.data?.next,
          previous: payload?.data?.previous,
          total_pages: payload?.data?.total_pages,
          results: payload?.data?.results,
        };
        break;
      case SET_SINGLE_PIPELINE:
        draft.pipeline = payload;
        break;
      default:
        return state;
    }
  });
export default pipelinesReducer;
