const dev = {
  API_ENDPOINT_URL: process.env.REACT_APP_API_URL,
  API_ENDPOINT_IMG_URL: process.env.REACT_APP_API_IMAGE_URL,
  API_ENDPOINT_STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
  API_ENDPOINT_PREVIEW_LANDING_PAGE_PROTOCOL:
    process.env.REACT_APP_LANDING_PAGE_PROTOCOL,
  WEBSOCKETS_URL: process.env.REACT_APP_WEBSOCKETS_URL,
  S3_STATIC_URL: process.env.REACT_APP_S3_STATIC_URL,
  A_RECORD: process.env.REACT_APP_A_RECORD,
  CNAME_RECORD: process.env.REACT_APP_CNAME_RECORD,
  SHOW_EVENT_TIME_CONFIG: process.env.REACT_APP_SHOW_EVENT_TIME_CONFIG,
  SHOW_CUSTOM_TIME_CONFIG: process.env.REACT_APP_SHOW_CUSTOM_TIME_CONFIG,
  APP_ENV: process.env.REACT_APP_ENV,
};

const prod = {
  API_ENDPOINT_URL: process.env.REACT_APP_API_URL,
  API_ENDPOINT_IMG_URL: process.env.REACT_APP_API_IMAGE_URL,
  API_ENDPOINT_STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
  API_ENDPOINT_PREVIEW_LANDING_PAGE_PROTOCOL:
    process.env.REACT_APP_LANDING_PAGE_PROTOCOL,
  WEBSOCKETS_URL: process.env.REACT_APP_WEBSOCKETS_URL,
  S3_STATIC_URL: process.env.REACT_APP_S3_STATIC_URL,
  A_RECORD: process.env.REACT_APP_A_RECORD,
  CNAME_RECORD: process.env.REACT_APP_CNAME_RECORD,
  SHOW_EVENT_TIME_CONFIG: process.env.REACT_APP_SHOW_EVENT_TIME_CONFIG,
  SHOW_CUSTOM_TIME_CONFIG: process.env.REACT_APP_SHOW_CUSTOM_TIME_CONFIG,
  APP_ENV: process.env.REACT_APP_ENV,
};

const test = {
  API_ENDPOINT_URL: process.env.REACT_APP_API_URL,
  API_ENDPOINT_IMG_URL: process.env.REACT_APP_API_IMAGE_URL,
  API_ENDPOINT_STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
  API_ENDPOINT_PREVIEW_LANDING_PAGE_PROTOCOL:
    process.env.REACT_APP_LANDING_PAGE_PROTOCOL,
  WEBSOCKETS_URL: process.env.REACT_APP_WEBSOCKETS_URL,
  S3_STATIC_URL: process.env.REACT_APP_S3_STATIC_URL,
  A_RECORD: process.env.REACT_APP_A_RECORD,
  CNAME_RECORD: process.env.REACT_APP_CNAME_RECORD,
  SHOW_EVENT_TIME_CONFIG: process.env.REACT_APP_SHOW_EVENT_TIME_CONFIG,
  SHOW_CUSTOM_TIME_CONFIG: process.env.REACT_APP_SHOW_CUSTOM_TIME_CONFIG,
  APP_ENV: process.env.REACT_APP_ENV,
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
