import { message } from "antd";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  getAllDocuments,
  getContactDocuments,
  setAllDocuments,
  setContactDocuments,
  setLoader,
} from "redux/actions/Documents";
import {
  DELETE_DOCUMENT,
  GET_ALL_DOCUMENTS,
  GET_CONTACT_DOCUMENTS,
  RENAME_DOCUMENT,
  SEND_MASS_DOCUMENT,
  UPLOAD_DOCUMENT,
} from "redux/constants/Documents";
import { FIRST_PAGE } from "constants/CommonConstants";
import DocumentsService from "services/DocumentsService";

export function* getAllDocumentsSaga() {
  yield takeEvery(GET_ALL_DOCUMENTS, function* ({ payload }) {
    try {
      const results = yield call(DocumentsService.getAllDocuments, payload);
      yield put(setLoader(false));
      yield put(setAllDocuments(results));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getContactDocumentsSaga() {
  yield takeEvery(GET_CONTACT_DOCUMENTS, function* ({ payload, page }) {
    try {
      const results = yield call(
        DocumentsService.getContactDocuments,
        payload,
        page.page
      );
      yield put(setLoader(false));
      yield put(setContactDocuments(results));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* uploadDocumentSaga() {
  yield takeEvery(UPLOAD_DOCUMENT, function* ({ payload, initData }) {
    try {
      yield call(DocumentsService.uploadDocument, payload);

      let results;
      const contactId = payload.get("contact");

      if (contactId) {
        results = yield call(
          DocumentsService.getContactDocuments,
          contactId,
          FIRST_PAGE
        );
        yield put(setContactDocuments(results));
      } else {
        results = yield call(DocumentsService.getAllDocuments, initData);
        yield put(setAllDocuments(results));
      }

      yield put(setLoader(false));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* renameDocumentSaga() {
  yield takeEvery(RENAME_DOCUMENT, function* ({ payload }) {
    const { id, data, successMessage, page, contactId } = payload;
    try {
      yield call(DocumentsService.renameDocument, id, data);
      contactId
        ? yield put(getContactDocuments(contactId, { page: page }))
        : yield put(getAllDocuments({ page: page }));
      message.success(successMessage, 3);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deleteDocumentSaga() {
  yield takeEvery(
    DELETE_DOCUMENT,
    function* ({ id, page, successMessage, contactId }) {
      try {
        yield call(DocumentsService.deleteDocument, id);
        if (!contactId) {
          const results = yield call(DocumentsService.getAllDocuments, {
            page: page,
          });
          yield put(setLoader(false));
          yield put(setAllDocuments(results));
        } else {
          yield put(getContactDocuments(contactId, { page: page }));
        }
        message.success(successMessage, 3);
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* sendMassDocumentSaga() {
  yield takeEvery(SEND_MASS_DOCUMENT, function* ({ payload, successMessage }) {
    try {
      yield call(DocumentsService.sendMassDoucment, payload);
      message.success(successMessage, 3);
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllDocumentsSaga),
    fork(uploadDocumentSaga),
    fork(renameDocumentSaga),
    fork(deleteDocumentSaga),
    fork(sendMassDocumentSaga),
    fork(getContactDocumentsSaga),
  ]);
}
