import {
  DELETE_PICTURES,
  GET_CONTACT_PICTURES,
  SET_ALL_PICTURES,
  UPLOAD_PICTURE,
} from "redux/constants/Pictures";

export const getContactPictures = (payload, page) => ({
  type: GET_CONTACT_PICTURES,
  payload,
  page,
});

export const setAllPictures = (payload) => ({
  type: SET_ALL_PICTURES,
  payload,
});

export const deletePictures = (payload, contactId, page, successMessage) => ({
  type: DELETE_PICTURES,
  payload,
  contactId,
  page,
  successMessage,
});

export const uploadPicture = (payload, initData, contactId) => ({
  type: UPLOAD_PICTURE,
  payload,
  initData,
  contactId,
});
