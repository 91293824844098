import { all, takeEvery, fork, call } from "redux-saga/effects";
import { GET_LONG_LIVED_FB_USER_ACCESS_TOKEN } from "redux/constants/SocialMediaPermissions";
import SocialMediaPermissionsService from "services/SocialMediaPermissions";
import { message } from "antd";

export function* longLivedUserAccessToken() {
  yield takeEvery(
    GET_LONG_LIVED_FB_USER_ACCESS_TOKEN,
    function* ({ payload, successMessage }) {
      try {
        yield call(
          SocialMediaPermissionsService.getLongLivedUserAccessToken,
          payload
        );
        message.success(successMessage, 3);
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(longLivedUserAccessToken)]);
}
