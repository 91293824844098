import {
  SET_CAMPAIGNS,
  GET_CAMPAIGNS,
  SHOW_MESSAGE,
  GET_FOLDERS,
  SET_FOLDERS,
  CREATE_FOLDER,
  ADD_FOLDER,
  CREATE_CAMPAIGN,
  ADD_CAMPAIGN,
  DELETE_FOLDER,
  EDIT_FOLDER,
  CHANGE_FOLDER,
  HANDLE_DELETE_FOLDER,
  DELETE_CAMPAIGN,
  HANDLE_DELETE_CAMPAIGN,
  EDIT_CAMPAIGN,
  CHANGE_CAMPAIGN,
  GET_SINGLE_CAMPAIGN,
  SET_SINGLE_CAMPAIGN,
  GET_DISCOUNT,
  SET_DISCOUNT,
  SET_LOADING,
  CREATE_DISCOUNT,
  GET_AVAILABLE_CAMPAIGNS,
  SET_AVAILABLE_CAMPAIGNS,
  POST_NEXT_ACTION,
  GET_NEXT_ACTION_BY_CAMPAIGN,
  SET_NEXT_ACTION_BY_CAMPAIGN,
  DELETE_NEXT_ACTION,
  REMOVE_NEXT_ACTION,
  ADD_NEXT_ACTION,
  EDIT_NEXT_ACTION,
  PUT_NEXT_ACTION,
  GET_PUBLISHED_CAMPAIGNS,
  SET_PUBLISHED_CAMPAIGNS,
  POST_REACTIVATION_NEXT_ACTION,
  ADD_REACTIVATION_NEXT_ACTION,
  GET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN,
  SET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN,
  UPDATE_REACTIVATION_NEXT_ACTION,
  PUT_REACTIVATION_NEXT_ACTION,
  DELETE_REACTIVATION_NEXT_ACTION,
  REMOVE_REACTIVATION_NEXT_ACTION,
  GET_REACTIVATION_CAMPAIGNS,
  EDIT_CAMPAIGN_TIME_CONFIG,
  CREATE_CAMPAIGN_TIME_CONFIG,
  DELETE_MULTIPLE_CAMPAIGN,
  GET_CONTACTS_ON_CAMPAIGN,
  SET_CONTACTS_ON_CAMPAIGN,
  DELETE_CONTACT_FROM_CAMPAIGN,
  PATCH_CONTACT_STATUS_CAMPAIGN,
  UPDATE_CONTACT_STATUS_CAMPAIGN,
  SET_TIME_CONFIG_TO_CAMPAIGN
} from "../constants/Campaign";

export const setCampaigns = (payload) => ({
  type: SET_CAMPAIGNS,
  payload,
});

export const getCampaigns = (payload) => ({
  type: GET_CAMPAIGNS,
  payload,
});

export const showMessage = (payload) => ({
  type: SHOW_MESSAGE,
  payload,
});

export const setFolders = (payload) => ({
  type: SET_FOLDERS,
  payload,
});

export const getFolders = (payload) => ({
  type: GET_FOLDERS,
  payload,
});

export const createFolder = (payload, callback) => ({
  type: CREATE_FOLDER,
  payload,
  callback,
});

export const addFolder = (payload) => ({
  type: ADD_FOLDER,
  payload,
});

export const createCampaign = (payload, callback) => ({
  type: CREATE_CAMPAIGN,
  payload,
  callback,
});

export const addCampaign = (payload) => ({
  type: ADD_CAMPAIGN,
  payload,
});

export const deleteFolder = (payload) => ({
  type: DELETE_FOLDER,
  payload,
});

export const editFolder = (payload, id, callback) => ({
  type: EDIT_FOLDER,
  payload,
  id,
  callback,
});

export const changeFolder = (payload) => ({
  type: CHANGE_FOLDER,
  payload,
});

export const handleDeleteFolder = (payload) => ({
  type: HANDLE_DELETE_FOLDER,
  payload,
});

export const deleteCampaign = (payload) => ({
  type: DELETE_CAMPAIGN,
  payload,
});

export const deleteMultipleCampaign = (payload) => ({
  type: DELETE_MULTIPLE_CAMPAIGN,
  payload,
});

export const handleDeleteCampaign = (payload) => ({
  type: HANDLE_DELETE_CAMPAIGN,
  payload,
});

export const editCampaign = (payload, id, setModalVisibility = ()=>{}) => ({
  type: EDIT_CAMPAIGN,
  payload,
  id,
  setModalVisibility
});

export const changeCampaign = (payload) => ({
  type: CHANGE_CAMPAIGN,
  payload,
});

export const getCampaign = (payload) => ({
  type: GET_SINGLE_CAMPAIGN,
  payload,
});

export const setCampaign = (payload) => ({
  type: SET_SINGLE_CAMPAIGN,
  payload,
});

export const getDiscounts = () => ({
  type: GET_DISCOUNT,
});

export const setDiscounts = (payload) => ({
  type: SET_DISCOUNT,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const createDiscount = (payload) => ({
  type: CREATE_DISCOUNT,
  payload,
});

export const getAvailableCampaigns = (payload) => ({
  type: GET_AVAILABLE_CAMPAIGNS,
  payload,
});

export const setAvailableCampaigns = (payload) => ({
  type: SET_AVAILABLE_CAMPAIGNS,
  payload,
});

export const postNextAction = (payload, id, removeSavedField) => ({
  type: POST_NEXT_ACTION,
  payload,
  id,
  removeSavedField,
});

export const getNextActionByCampaign = (id) => ({
  type: GET_NEXT_ACTION_BY_CAMPAIGN,
  id,
});

export const setNextActionByCampaign = (payload) => ({
  type: SET_NEXT_ACTION_BY_CAMPAIGN,
  payload,
});

export const updateNextAction = (payload, campaignId, nextActionId) => ({
  type: POST_NEXT_ACTION,
  payload,
  campaignId,
  nextActionId,
});

export const deleteNextAction = (campaignId, nextActionId) => ({
  type: DELETE_NEXT_ACTION,
  campaignId,
  nextActionId,
});

export const removeNextAction = (payload) => ({
  type: REMOVE_NEXT_ACTION,
  payload,
});

export const addNextAction = (payload) => ({
  type: ADD_NEXT_ACTION,
  payload,
});

export const editNextAction = (campaignId, nextActionId, payload) => ({
  type: EDIT_NEXT_ACTION,
  campaignId,
  nextActionId,
  payload,
});

export const putNextAction = (payload) => ({
  type: PUT_NEXT_ACTION,
  payload,
});

export const getPublishedCampaigns = (payload) => ({
  type: GET_PUBLISHED_CAMPAIGNS,
  payload,
});

export const setPublishedCampaigns = (payload) => ({
  type: SET_PUBLISHED_CAMPAIGNS,
  payload,
});

export const postReactivationNextAction = (payload, id, removeSavedField) => ({
  type: POST_REACTIVATION_NEXT_ACTION,
  payload,
  id,
  removeSavedField
});

export const addReactivationNextAction = (payload) => ({
  type: ADD_REACTIVATION_NEXT_ACTION,
  payload,
});

export const getReactivationNextActionByCampaign = (id) => ({
  type: GET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN,
  id,
});

export const setReactivationNextActionByCampaign = (payload) => ({
  type: SET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN,
  payload,
});

export const updateReactivationNextAction = (campaignId, nextActionId, payload) => ({
  type: UPDATE_REACTIVATION_NEXT_ACTION,
  payload,
  campaignId,
  nextActionId,
});

export const putReactivationNextAction = (payload) => ({
  type: PUT_REACTIVATION_NEXT_ACTION,
  payload,
});

export const deleteReactivationNextAction = (campaignId, nextActionId) => ({
  type: DELETE_REACTIVATION_NEXT_ACTION,
  campaignId,
  nextActionId,
});

export const removeReactivationNextAction = (payload) => ({
  type: REMOVE_REACTIVATION_NEXT_ACTION,
  payload,
});

export const getReactivationCampaigns = () => ({
  type: GET_REACTIVATION_CAMPAIGNS,
});

export const editCampaignTimeConfig = (payload, campaignId, configId, actionMessage) => ({
  type: EDIT_CAMPAIGN_TIME_CONFIG,
  payload,
  campaignId,
  configId,
  actionMessage
});

export const createCampaignTimeConfig = (payload, campaignId, configId, actionMessage) => ({
  type: CREATE_CAMPAIGN_TIME_CONFIG,
  payload,
  campaignId,
  configId,
  actionMessage
});

export const getContactsOnCampaign = (payload) => ({
  type: GET_CONTACTS_ON_CAMPAIGN,
  payload,
});

export const setContactsOnCampaign = (payload) => ({
  type: SET_CONTACTS_ON_CAMPAIGN,
  payload,
});

export const deleteContactOnCampaign = (payload) => ({
  type: DELETE_CONTACT_FROM_CAMPAIGN,
  payload,
});

export const patchContactOnCampaign = (payload) => ({
  type: PATCH_CONTACT_STATUS_CAMPAIGN,
  payload,
});

export const updateContactOnCampaign = (payload) => ({
  type: UPDATE_CONTACT_STATUS_CAMPAIGN,
  payload,
});

export const setTimeConfigToCampaign = (payload) => ({
  type: SET_TIME_CONFIG_TO_CAMPAIGN,
  payload,
})