// @ts-nocheck
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  GET_CAMPAIGNS,
  GET_FOLDERS,
  CREATE_FOLDER,
  CREATE_CAMPAIGN,
  DELETE_FOLDER,
  EDIT_FOLDER,
  DELETE_CAMPAIGN,
  EDIT_CAMPAIGN,
  GET_SINGLE_CAMPAIGN,
  GET_DISCOUNT,
  CREATE_DISCOUNT,
  GET_AVAILABLE_CAMPAIGNS,
  POST_NEXT_ACTION,
  GET_NEXT_ACTION_BY_CAMPAIGN,
  DELETE_NEXT_ACTION,
  EDIT_NEXT_ACTION,
  GET_PUBLISHED_CAMPAIGNS,
  POST_REACTIVATION_NEXT_ACTION,
  GET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN,
  UPDATE_REACTIVATION_NEXT_ACTION,
  DELETE_REACTIVATION_NEXT_ACTION,
  GET_REACTIVATION_CAMPAIGNS,
  EDIT_CAMPAIGN_TIME_CONFIG,
  CREATE_CAMPAIGN_TIME_CONFIG,
  DELETE_MULTIPLE_CAMPAIGN,
  GET_CONTACTS_ON_CAMPAIGN,
  PATCH_CONTACT_STATUS_CAMPAIGN,
  DELETE_CONTACT_FROM_CAMPAIGN,
} from "../constants/Campaign";
import {
  setCampaigns,
  showMessage,
  setFolders,
  addFolder,
  addCampaign,
  handleDeleteFolder,
  changeFolder,
  handleDeleteCampaign,
  changeCampaign,
  setCampaign,
  setDiscounts,
  setLoading,
  setAvailableCampaigns,
  setNextActionByCampaign,
  removeNextAction,
  addNextAction,
  putNextAction,
  setPublishedCampaigns,
  setReactivationNextActionByCampaign,
  addReactivationNextAction,
  removeReactivationNextAction,
  putReactivationNextAction,
  getCampaigns,
  setContactsOnCampaign,
  updateContactOnCampaign,
  getContactsOnCampaign,
  setTimeConfigToCampaign
} from "../actions/Campaign";
import { message } from "antd";
import CampaignService from "services/CampaignService";
import { NUMBER_PER_PAGE } from "constants/ContactConstant";

export function* getCampaignsSaga() {
  yield takeEvery(GET_CAMPAIGNS, function* ({ payload }) {
    try {
      yield put(setLoading(true));
      const campaigns = yield call(CampaignService.getCampaigns, payload?.searchValue);
      yield put(setCampaigns(campaigns.data));
    } catch (err) {
      yield put(showMessage(err));
    } finally {
      yield put(setLoading(false));
    }
  });
}

export function* getFolders() {
  yield takeEvery(GET_FOLDERS, function* () {
    try {
      const folders = yield call(CampaignService.getFolders);
      yield put(setFolders(folders.data));
    } catch (err) {
      yield put(showMessage(err));
    }
  });
}

export function* createFolders() {
  yield takeEvery(CREATE_FOLDER, function* ({ payload, callback }) {
    try {
      const folder = yield call(CampaignService.createFolder, payload);
      yield put(addFolder(folder.data));
      callback();
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* createCampaign() {
  yield takeEvery(CREATE_CAMPAIGN, function* ({ payload, callback }) {
    try {
      const campaign = yield call(CampaignService.createCampaign, payload);
      yield put(addCampaign(campaign.data));
      callback();
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* deleteFolder() {
  yield takeEvery(DELETE_FOLDER, function* ({ payload }) {
    try {
      yield call(CampaignService.deleteFolder, payload);
      yield put(handleDeleteFolder({ id: payload }));
    } catch (err) {
      yield put(showMessage(err));
    }
  });
}

export function* editFolder() {
  yield takeEvery(EDIT_FOLDER, function* ({ payload, id, callback }) {
    try {
      yield call(CampaignService.editFolder, id, payload);
      yield put(changeFolder({ id: id, name: payload.name }));
      callback();
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* deleteCampaign() {
  yield takeEvery(DELETE_CAMPAIGN, function* ({ payload }) {
    try {
      yield call(CampaignService.deleteCampaign, payload);
      yield put(handleDeleteCampaign({ id: payload }));
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* deleteMultipleCampaignSaga() {
  yield takeEvery(DELETE_MULTIPLE_CAMPAIGN, function* ({ payload }) {
    try {
      yield call(CampaignService.deleteMultipleCampaign, payload);
      yield put(getCampaigns());
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      };
    };
  })
}

export function* editCampaign() {
  yield takeEvery(EDIT_CAMPAIGN, function* ({ payload, id, setModalVisibility }) {
    try {
      const campaign = yield call(CampaignService.editCampaign, id, payload);
      yield put(changeCampaign({ id: id, campaign: campaign.data }));
      setModalVisibility(false);
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
      if ("campaign_id" in err.response.data) {
        let errMessage = err.response.data.campaign_id[0];
        message.error(
          errMessage.charAt(0).toUpperCase() + errMessage.slice(1),
          3
        );
      }
      if ("websites" in err.response.data) {
        message.error(err.response.data.websites[0], 3);
        setModalVisibility(true);
      }
    }
  });
}
export function* getCampaign() {
  yield takeEvery(GET_SINGLE_CAMPAIGN, function* ({ payload }) {
    try {
      const campaign = yield call(CampaignService.getCampaign, payload);
      yield put(setCampaign(campaign.data));
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* getDiscounts() {
  yield takeEvery(GET_DISCOUNT, function* () {
    try {
      const discounts = yield call(CampaignService.getAllDiscounts);
      yield put(setDiscounts(discounts.data));
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* createDiscounts() {
  yield takeEvery(CREATE_DISCOUNT, function* ({payload}) {
    try {
      const discount = yield call(CampaignService.createDiscount, payload);
      yield put(setDiscounts(discount.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getAvailableCampaignSaga() {
  yield takeEvery(GET_AVAILABLE_CAMPAIGNS, function* ({ payload }) {
    try {
      const respons = yield call(
        CampaignService.getAvailableCampaigns,
        payload
      );
      yield put(setAvailableCampaigns(respons.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* postNextReviewActionSaga() {
  yield takeEvery(
    POST_NEXT_ACTION,
    function* ({ payload, id, removeSavedField }) {
      try {
        const response = yield call(
          CampaignService.postNextReviewAction,
          payload,
          id
        );
        yield put(addNextAction(response.data));
        removeSavedField();
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors[0], 3);
        }
      }
    }
  );
}

export function* getNextActionByCampaignSaga() {
  yield takeEvery(GET_NEXT_ACTION_BY_CAMPAIGN, function* ({ id }) {
    try {
      const response = yield call(CampaignService.getNextActionByCampaign, id);
      yield put(setNextActionByCampaign(response.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deleteNextActionSaga() {
  yield takeEvery(DELETE_NEXT_ACTION, function* ({ campaignId, nextActionId }) {
    try {
      yield call(CampaignService.deleteNextAction, campaignId, nextActionId);
      yield put(removeNextAction(nextActionId));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* editNextActionSaga() {
  yield takeEvery(
    EDIT_NEXT_ACTION,
    function* ({ campaignId, nextActionId, payload }) {
      try {
        let response = yield call(
          CampaignService.editNextAction,
          campaignId,
          nextActionId,
          payload
        );
        yield put(putNextAction(response.data));
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors[0], 3);
        }
      }
    }
  );
}

export function* getPublishedCampaigns() {
  yield takeEvery(GET_PUBLISHED_CAMPAIGNS, function* () {
    try {
      yield put(setLoading(true));
      const campaigns = yield call(CampaignService.getPublishedCampaigns);
      yield put(setPublishedCampaigns(campaigns.data));
    } catch (err) {
      yield put(showMessage(err));
    } finally {
      yield put(setLoading(false));
    }
  });
}
export function* postReactivationNextActionSaga() {
  yield takeEvery(
    POST_REACTIVATION_NEXT_ACTION,
    function* ({ payload, id, removeSavedField }) {
      try {
        const response = yield call(
          CampaignService.postReactivationNextAction,
          payload,
          id
        );
        yield put(addReactivationNextAction(response.data));
        removeSavedField();
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors[0], 3);
        }
      }
    }
  );
}

export function* getReactivationNextActionByCampaignSaga() {
  yield takeEvery(GET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN, function* ({ id }) {
    try {
      const response = yield call(CampaignService.getReactivationNextActionByCampaign, id);
      yield put(setReactivationNextActionByCampaign(response.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* updateReactivationNextActionSaga() {
  yield takeEvery(
    UPDATE_REACTIVATION_NEXT_ACTION,
    function* ({ campaignId, nextActionId, payload }) {
      try {
        let response = yield call(
          CampaignService.editReactivationNextAction,
          campaignId,
          nextActionId,
          payload
        );
        yield put(putReactivationNextAction(response.data));
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors[0], 3);
        }
      }
    }
  );
}

export function* deleteReactivationNextActionSaga() {
  yield takeEvery(DELETE_REACTIVATION_NEXT_ACTION, function* ({ campaignId, nextActionId }) {
    try {
      yield call(CampaignService.deleteReactivationNextAction, campaignId, nextActionId);
      yield put(removeReactivationNextAction(nextActionId));
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* getReactivationCampaigns() {
  yield takeEvery(GET_REACTIVATION_CAMPAIGNS, function* () {
    try {
      yield put(setLoading(true));
      const campaigns = yield call(CampaignService.getReactivationCampaigns);
      yield put(setCampaigns(campaigns.data));
    } catch (err) {
      yield put(showMessage(err));
    } finally {
      yield put(setLoading(false));
    }
  });
}

export function* editCampaignTimeConfig() {
  yield takeEvery(EDIT_CAMPAIGN_TIME_CONFIG, function* ({payload, campaignId, configId, actionMessage}) {
    try {
      yield call(CampaignService.editCampaignTimeConfig, payload, campaignId, configId);
      message.success(actionMessage, 3);
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      };
      console.log(err);
    }
  });
}

export function* createCampaignTimeConfig() {
  yield takeEvery(CREATE_CAMPAIGN_TIME_CONFIG, function* ({ payload, campaignId, actionMessage }) {
    try {
      let results = yield call(CampaignService.createCampaignTimeConfig, payload, campaignId);
      message.success(actionMessage, 3);
      yield put(setTimeConfigToCampaign(results.data))
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      };
      console.log(err);
    };
  });
}

export function* getContactsOnCampaignSaga() {
  yield takeEvery(GET_CONTACTS_ON_CAMPAIGN, function* ({ payload }) {
    try {
      const response = yield call(CampaignService.getContactsOnCampaign, payload);
      yield put(setContactsOnCampaign(response));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* patchContactOnCampaignSaga() {
  yield takeEvery(PATCH_CONTACT_STATUS_CAMPAIGN, function* ({ payload }) {
    try {
      const response = yield call(
        CampaignService.patchContactStatusOnCampaign,
        payload.campaignId,
        payload.id
      );
      yield put(updateContactOnCampaign(response.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deleteContactOnCampaignSaga() {
  yield takeEvery(DELETE_CONTACT_FROM_CAMPAIGN, function* ({ payload }) {
    try {
      yield call(CampaignService.deleteContactStatusOnCampaign, payload.campaignId, payload.id);
      yield put(
        getContactsOnCampaign({
          campaignId: payload.campaignId,
          page: payload.pageNumber,
          perPage: NUMBER_PER_PAGE,
        })
      );
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCampaignsSaga),
    fork(getFolders),
    fork(createFolders),
    fork(createCampaign),
    fork(deleteFolder),
    fork(editFolder),
    fork(deleteCampaign),
    fork(editCampaign),
    fork(getCampaign),
    fork(getDiscounts),
    fork(createDiscounts),
    fork(getAvailableCampaignSaga),
    fork(postNextReviewActionSaga),
    fork(getNextActionByCampaignSaga),
    fork(deleteNextActionSaga),
    fork(editNextActionSaga),
    fork(getPublishedCampaigns),
    fork(postReactivationNextActionSaga),
    fork(getReactivationNextActionByCampaignSaga),
    fork(updateReactivationNextActionSaga),
    fork(deleteReactivationNextActionSaga),
    fork(getReactivationCampaigns),
    fork(editCampaignTimeConfig),
    fork(createCampaignTimeConfig),
    fork(deleteMultipleCampaignSaga),
    fork(getContactsOnCampaignSaga),
    fork(patchContactOnCampaignSaga),
    fork(deleteContactOnCampaignSaga),
  ]);
}
