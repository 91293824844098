import {
    GET_CHAT_WIDGET,
    UPDATE_CHAT_WIDGET, 
    SET_CHAT_WIDGET,
    SET_LOADING,
    SET_AVATAR
} from '../constants/ChatWidget';

export const getChatWidget = () => ({
    type: GET_CHAT_WIDGET
});

export const updateChatWidget = (payload, id, successMessage) => ({
    type: UPDATE_CHAT_WIDGET,
    payload,
    id,
    successMessage
});

export const setChatWidget = (payload) => ({
    type: SET_CHAT_WIDGET,
    payload
})

export const isLoading = (payload) => ({
    type: SET_LOADING,
    payload
})

export const setAvatar = (payload) => ({
    type: SET_AVATAR,
    payload
})
