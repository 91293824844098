import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  CHANGE_FUNNEL_TRIGGER: "trigger/funnel-change/",
  GET_FUNNEL_TRIGGER: "trigger/funnel-change/?page=%s&perPage=%s",
  FUNNEL_TRIGGER_ITEM: "trigger/funnel-change/%s/",
  REACTIVATION_TRIGGER: "trigger/campaign-change/",
  REACTIVATION_TRIGGER_ITEM: "trigger/campaign-change/%s/",
};

class TriggerService extends ApiService {
  createChangeFunnelTrigger = (data) => {
    return this.apiClient.post(ENDPOINTS.CHANGE_FUNNEL_TRIGGER, data);
  };

  getTriggersPagination = ({ page, perPage }) => {
    return this.apiClient.get(format(ENDPOINTS.GET_FUNNEL_TRIGGER, page, perPage));
  };

  deleteTrigger = (data) => {
    return this.apiClient.delete(format(ENDPOINTS.FUNNEL_TRIGGER_ITEM, data));
  };

  getTriggerById = (data) => {
    return this.apiClient.get(format(ENDPOINTS.FUNNEL_TRIGGER_ITEM, data));
  };

  editChangeFunnelTrigger = (id, data) => {
    return this.apiClient.patch(format(ENDPOINTS.FUNNEL_TRIGGER_ITEM, id), data);
  };

  createReactivationTrigger = (data) => {
    return this.apiClient.post(ENDPOINTS.REACTIVATION_TRIGGER, data);
  };

  editReactivationTrigger = (id, data) => {
    return this.apiClient.patch(format(ENDPOINTS.REACTIVATION_TRIGGER_ITEM, id), data);
  };

  getReactivationTriggerById = (id) => {
    return this.apiClient.get(format(ENDPOINTS.REACTIVATION_TRIGGER_ITEM, id));
  };
}

export default new TriggerService();
