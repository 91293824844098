import { message } from "antd";
import { NUMBER_PER_PAGE } from "constants/FunnelConstant";
import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import {
  getTriggersPagination,
  setTrigger,
  setTriggersPagination,
  setUpdateSingleTrigger,
  setUpdatedTrigger,
} from "redux/actions/Trigger";
import {
  CREATE_CHANGE_FUNNEL_TRIGGER,
  DELETE_TRIGGER,
  GET_TRIGGER,
  GET_TRIGGERS_PAGINATION,
  EDIT_CHANGE_FUNNEL_TRIGGER,
  CREATE_REACTIVATION_TRIGGER,
  EDIT_REACTIVATIONL_TRIGGER,
  GET_REACTIVATION_TRIGGER,
} from "redux/constants/Trigger";
import TriggerService from "services/TriggerService";

export function* createChangeFunnelTriggerSaga() {
  yield takeEvery(CREATE_CHANGE_FUNNEL_TRIGGER, function* ({ payload, closeModal }) {
    try {
      yield call(TriggerService.createChangeFunnelTrigger, payload);
      yield put(getTriggersPagination({ page: 1, perPage: NUMBER_PER_PAGE }));
      closeModal();
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
      if (err.response.data?.base_trigger) {
        message.error(err.response.data?.base_trigger?.non_field_errors[0], 3);
      }
    }
  });
}

export function* getTriggersPaginationSaga() {
  yield takeEvery(GET_TRIGGERS_PAGINATION, function* ({ payload }) {
    try {
      const results = yield call(TriggerService.getTriggersPagination, payload);
      yield put(setTriggersPagination(results.data));
    } catch (err) {
      console.log(err);
      message.error(err.response.data.message, 3);
    }
  });
}

export function* deleteTriggerSaga() {
  yield takeEvery(DELETE_TRIGGER, function* ({ payload }) {
    try {
      yield call(TriggerService.deleteTrigger, payload);
      yield put(getTriggersPagination({ page: 1, perPage: NUMBER_PER_PAGE }));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getTriggerByIdSaga() {
  yield takeEvery(GET_TRIGGER, function* ({ payload }) {
    try {
      const result = yield call(TriggerService.getTriggerById, payload);
      yield put(setTrigger(result.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* editChangeFunnelTriggerSaga() {
  yield takeEvery(EDIT_CHANGE_FUNNEL_TRIGGER, function* ({ id, payload, closeModal }) {
    try {
      const reponse = yield call(TriggerService.editChangeFunnelTrigger, id, payload);
      yield put(setUpdatedTrigger(reponse.data));
      closeModal();
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* createReactivationTriggerSaga() {
  yield takeEvery(CREATE_REACTIVATION_TRIGGER, function* ({ payload, closeModal }) {
    try {
      yield call(TriggerService.createReactivationTrigger, payload);
      yield put(getTriggersPagination({ page: 1, perPage: NUMBER_PER_PAGE }));
      closeModal();
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
      if (err.response.data?.base_trigger) {
        message.error(err.response.data?.base_trigger?.non_field_errors[0], 3);
      }
      if (err.response.data?.answer_trigger) {
        message.error(err.response.data?.answer_trigger?.[0].non_field_errors[0], 3);
      }
    }
  });
}

export function* editReactivationTriggerSaga() {
  yield takeEvery(EDIT_REACTIVATIONL_TRIGGER, function* ({ id, payload, closeModal }) {
    try {
      const reponse = yield call(TriggerService.editReactivationTrigger, id, payload);
      yield put(setUpdatedTrigger(reponse.data));
      yield put(setUpdateSingleTrigger(reponse.data))
      closeModal();
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
      if (err.response.data?.base_trigger) {
        message.error(err.response.data?.base_trigger?.non_field_errors[0], 3);
      }
      if (err.response.data?.answer_trigger) {
        message.error(err.response.data?.answer_trigger?.[0].non_field_errors[0], 3);
      }
    }
  });
}

export function* getReactivationTriggerByIdSaga() {
  yield takeEvery(GET_REACTIVATION_TRIGGER, function* ({ payload }) {
    try {
      const result = yield call(TriggerService.getReactivationTriggerById, payload);
      yield put(setTrigger(result.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createChangeFunnelTriggerSaga),
    fork(getTriggersPaginationSaga),
    fork(deleteTriggerSaga),
    fork(getTriggerByIdSaga),
    fork(editChangeFunnelTriggerSaga),
    fork(createReactivationTriggerSaga),
    fork(editReactivationTriggerSaga),
    fork(getReactivationTriggerByIdSaga),
  ]);
}
