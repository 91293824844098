// @ts-nocheck
import { all, takeEvery, put, fork, call, delay } from "redux-saga/effects";
import { push } from "connected-react-router";
import { message } from "antd";
import { format } from "util";
import { ROUTES } from "routes";
import { NUMBER_PER_PAGE } from "constants/DomainConstants";
import WebPageService from "../../services/WebPageService";
import {
  POST_NEW_DOMAIN,
  GET_ALL_DOMAINS_PER_PAGE,
  DELETE_DOMAIN,
  UPDATE_DOMAIN,
  GET_ALL_LANDING_PAGES_PER_PAGE,
  CLONE_LANDING_PAGE,
  POST_NEW_LANDING_PAGE,
  GET_LANDING_PAGE_BY_ID,
  GET_LANDING_PAGE_TEMPLATES,
  DELETE_LANDING_PAGE,
  UPDATE_LANDING_PAGE,
  UPDATE_STEP,
  GET_STEP_BY_ID,
  GET_ALL_STEPS_BY_PAGE,
  REORDER_STEPS,
  DELETE_STEP,
  CLONE_STEP,
  ADD_NEW_STEP,
  CREATE_LANDING_PAGE_CONTENT,
  UPDATE_LANDING_PAGE_CONTENT,
} from "./../constants/WebPage";
import {
  setAllDomains,
  getDomainsPerPage,
  updateDomainList,
  setAllLandingPages,
  getLandingPagePerPage,
  setLandingPage,
  setLandingPageTemplates,
  updateLandingPageInfo,
  setStep,
  setStepsByPage,
  updateStepList,
  removeStepFromList,
  pushStepToList,
  updateStepChatWidget,
  setLandingPageContentRequest,
} from "./../actions/WebPage";
import landingPageEditStateService from "helpers/setEditLandingPageData";
import { setNewTreatment } from "redux/actions/Treatment";

export function* postNewDomain() {
  yield takeEvery(
    POST_NEW_DOMAIN,
    function* ({ values, messageSuccess, resetModal, setIsSpinning }) {
      try {
        yield call(WebPageService.postNewDomain, values);
        message.success(messageSuccess, 3);
        yield put(getDomainsPerPage({ page: 1, perPage: NUMBER_PER_PAGE }));
        resetModal();
      } catch (err) {
        setIsSpinning(false);
        err.response.data.name
          ? message.error(err.response.data.name, 3)
          : message.error(err.response.data.non_field_errors[0], 3);
        console.log(err);
      }
    }
  );
}

export function* getDomainsPerPageSaga() {
  yield takeEvery(GET_ALL_DOMAINS_PER_PAGE, function* ({ payload }) {
    try {
      const results = yield call(WebPageService.getDomainsForPage, payload);
      yield put(setAllDomains(results));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deleteDomain() {
  yield takeEvery(DELETE_DOMAIN, function* ({ payload, pageNumber }) {
    try {
      yield call(WebPageService.deleteDomain, payload);
      yield put(getDomainsPerPage({ page: pageNumber, perPage: NUMBER_PER_PAGE }));
    } catch (err) {
      console.log(err);
      message.error(err.response.data.message, 3);
    }
  });
}

export function* updateDomain() {
  yield takeEvery(
    UPDATE_DOMAIN,
    function* ({ id, payload, messageSuccess, resetModal, setIsSpinning }) {
      try {
        const updateDomain = yield call(WebPageService.updateDomain, {
          id,
          payload,
        });
        message.success(messageSuccess, 3);
        resetModal();
        yield put(updateDomainList(updateDomain.data));
      } catch (err) {
        setIsSpinning(false);
        console.log(err);
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors, 3);
        }
      }
    }
  );
}

export function* getLandingPagePerPageSaga() {
  yield takeEvery(GET_ALL_LANDING_PAGES_PER_PAGE, function* ({ payload }) {
    try {
      const results = yield call(WebPageService.getLandingPageForPage, payload);
      yield put(setAllLandingPages(results));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* cloneLandingPageSaga() {
  yield takeEvery(
    CLONE_LANDING_PAGE,
    function* ({ id, values, messageSuccess, resetModal, searchData }) {
      try {
        yield call(WebPageService.cloneLandingPage, { id: id, data: values });
        message.success(messageSuccess, 3);
        yield put(
          getLandingPagePerPage({
            page: 1,
            perPage: NUMBER_PER_PAGE,
            search: searchData,
          })
        );
        resetModal();
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors, 3);
        }
        console.log(err);
      }
    }
  );
}
export function* postLandingPageSaga() {
  yield takeEvery(POST_NEW_LANDING_PAGE, function* ({ values, messageSuccess, resetModal }) {
    try {
      const response = yield call(WebPageService.postLandingPage, values);
      message.success(messageSuccess, 3);
      resetModal();
      yield put(push(format(ROUTES.SINGLE_LANDING_PAGE, response.data.id)));
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors, 3);
      }
      console.log(err);
    }
  });
}

export function* getSingleLandingPage() {
  yield takeEvery(GET_LANDING_PAGE_BY_ID, function* (id) {
    try {
      const response = yield call(WebPageService.getLandingPageById, id);
      yield put(setLandingPage(response.data));
      if (!landingPageEditStateService.isCreatedWithBuilder(response.data)) {
        const editData = landingPageEditStateService.setEditLandingPageData(response.data);
        yield put(setLandingPageContentRequest(editData));
      }
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors, 3);
      }
      console.log(err);
    }
  });
}

export function* deleteLandingPage() {
  yield takeEvery(DELETE_LANDING_PAGE, function* ({ payload }) {
    try {
      yield call(WebPageService.deleteLandingPage, payload);
      yield put(push(format(ROUTES.LANDING_PAGE)));
    } catch (err) {
      console.log(err);
      message.error(err.response.data.message, 3);
    }
  });
}

export function* updateLandingPage() {
  yield takeEvery(UPDATE_LANDING_PAGE, function* ({ id, payload, messageSuccess }) {
    try {
      const results = yield call(WebPageService.updateLandingPage, {
        id,
        payload,
      });
      message.success(messageSuccess, 3);
      yield put(updateLandingPageInfo(results.data));
      if (payload.enabled_chat_widget !== null) {
        yield put(updateStepChatWidget(payload.enabled_chat_widget));
      }
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors, 3);
      }
    }
  });
}

export function* updateStep() {
  yield takeEvery(UPDATE_STEP, function* ({ pageId, id, payload, messageSuccess, setIsLoading }) {
    try {
      const updateStep = yield call(WebPageService.updateStep, {
        pageId,
        id,
        payload,
      });
      message.success(messageSuccess, 3);
      yield put(updateStepList(updateStep.data));
      setIsLoading && setIsLoading(false);
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors, 3);
      } else if ("message" in err?.response?.data) {
        message.error(err.response.data.message, 3);
      }
    }
  });
}

export function* getStepById() {
  yield takeEvery(GET_STEP_BY_ID, function* ({ pageId, id }) {
    try {
      const step = yield call(WebPageService.getStepById, {
        pageId,
        id,
      });
      yield put(setStep(step.data));
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors, 3);
      }
    }
  });
}
export function* getStepsByPageSaga() {
  yield takeEvery(GET_ALL_STEPS_BY_PAGE, function* ({ payload }) {
    try {
      const results = yield call(WebPageService.getAllStepsByPage, payload);
      yield put(setStepsByPage(results.data));
      if (!landingPageEditStateService.isCreatedWithBuilder(results.data)) {
        const editData = landingPageEditStateService.setEditLandingPageData(
          results.data[0]?.landing_page
        );
        yield put(setLandingPageContentRequest(editData));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* reorderSteps() {
  yield takeEvery(REORDER_STEPS, function* ({ payload, id }) {
    try {
      const steps = yield call(WebPageService.reorderSteps, payload, id);
      yield put(setStepsByPage(steps.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deleteStep() {
  yield takeEvery(DELETE_STEP, function* ({ payload }) {
    try {
      yield call(WebPageService.deleteStep, payload);
      yield put(removeStepFromList(payload.id));
    } catch (err) {
      console.log(err);
      message.error(err.response.data.message, 3);
    }
  });
}

export function* cloneStepSaga() {
  yield takeEvery(CLONE_STEP, function* ({ payload, messageSuccess, resetModal, isTheSamePage }) {
    try {
      const response = yield call(WebPageService.cloneStep, payload);
      message.success(messageSuccess, 3);
      if (isTheSamePage) {
        yield put(pushStepToList(response.data));
      }
      resetModal();
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors, 3);
      }
      console.log(err);
    }
  });
}

export function* addNewStepSaga() {
  yield takeEvery(
    ADD_NEW_STEP,
    function* ({ pageId, payload, messageSuccess, resetModal, setOpenStep }) {
      try {
        const response = yield call(WebPageService.addNewStep, {
          id: pageId,
          data: payload,
        });
        message.success(messageSuccess, 3);
        yield put(pushStepToList(response.data));
        resetModal();
        setOpenStep(response.data);
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors, 3);
        }
        console.log(err.response);
      }
    }
  );
}

export function* getLandingPageTemplates() {
  yield takeEvery(GET_LANDING_PAGE_TEMPLATES, function* ({setTemplateListLoading}) {
    try {
      const response = yield call(WebPageService.getLandingPageTemplates);
      yield put(setLandingPageTemplates(response.data));
      setTemplateListLoading && setTemplateListLoading(false);
    } catch (err) {
      console.log(err);
      message.error(err.response.data.non_field_errors, 3);
    }
  });
}

export function* createLandingPageContent() {
  yield takeEvery(
    CREATE_LANDING_PAGE_CONTENT,
    function* ({ payload, successMessage, setLoaderActive }) {
      try {
        const { data } = yield call(WebPageService.createLandingPageContent, payload);
        yield put(push(format(ROUTES.SINGLE_LANDING_PAGE, data.id)));
        yield delay(100, message.success(successMessage));
        setLoaderActive(false);
        yield put(setNewTreatment(null))
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* updateLandingPageContent() {
  yield takeEvery(UPDATE_LANDING_PAGE_CONTENT, function* ({ payload, id, successMessage }) {
    try {
      const data = yield call(WebPageService.updateLandingPageContent, payload, id);
      yield delay(3000, message.success(successMessage));
      if (!landingPageEditStateService.isCreatedWithBuilder(data.data)) {
        yield put(updateLandingPageInfo(data.data));
        const editData = landingPageEditStateService.setEditLandingPageData(data.data);
        yield put(setLandingPageContentRequest(editData));
      }
      yield put(setNewTreatment(null))
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(postNewDomain),
    fork(getDomainsPerPageSaga),
    fork(deleteDomain),
    fork(updateDomain),
    fork(getLandingPagePerPageSaga),
    fork(cloneLandingPageSaga),
    fork(postLandingPageSaga),
    fork(getSingleLandingPage),
    fork(deleteLandingPage),
    fork(updateLandingPage),
    fork(updateStep),
    fork(getStepById),
    fork(getStepsByPageSaga),
    fork(reorderSteps),
    fork(deleteStep),
    fork(cloneStepSaga),
    fork(addNewStepSaga),
    fork(getLandingPageTemplates),
    fork(createLandingPageContent),
    fork(updateLandingPageContent),
  ]);
}
