import {
  GET_ALL_DOMAINS_PER_PAGE,
  POST_NEW_DOMAIN,
  SET_ALL_DOMAINS,
  DELETE_DOMAIN,
  UPDATE_DOMAIN,
  UPDATE_DOMAIN_LIST,
  GET_ALL_LANDING_PAGES_PER_PAGE,
  GET_LANDING_PAGE_TEMPLATES,
  SET_LANDING_PAGE_TEMPLATES,
  SET_ALL_LANDING_PAGES,
  CLONE_LANDING_PAGE,
  POST_NEW_LANDING_PAGE,
  GET_LANDING_PAGE_BY_ID,
  SET_LANDING_PAGE,
  DELETE_LANDING_PAGE,
  UPDATE_LANDING_PAGE,
  UPDATE_LANDING_PAGE_INFO,
  UPDATE_STEP,
  GET_STEP_BY_ID,
  SET_STEP,
  SET_ALL_STEPS_BY_PAGE,
  GET_ALL_STEPS_BY_PAGE,
  REORDER_STEPS,
  DELETE_STEP,
  UPDATE_STEP_LIST,
  REMOVE_STEP_FROM_LIST,
  CLONE_STEP,
  PUSH_STEP_TO_LIST,
  ADD_NEW_STEP,
  UPDATE_STEP_CHAT_WIDGET,
  SET_LANDING_PAGE_REQUEST,
  CREATE_LANDING_PAGE_CONTENT,
  UPDATE_LANDING_PAGE_CONTENT,
} from "./../constants/WebPage";

export const postNewDomain = (values, messageSuccess, resetModal, setIsSpinning) => ({
  type: POST_NEW_DOMAIN,
  values,
  messageSuccess,
  resetModal,
  setIsSpinning
});

export const getDomainsPerPage = (payload) => ({
  type: GET_ALL_DOMAINS_PER_PAGE,
  payload,
});

export const setAllDomains = (payload) => ({
  type: SET_ALL_DOMAINS,
  payload,
});

export const deleteDomain = (payload, pageNumber) => ({
  type: DELETE_DOMAIN,
  payload,
  pageNumber,
});

export const updateDomain = (id, payload, messageSuccess, resetModal, setIsSpinning) => ({
  type: UPDATE_DOMAIN,
  id,
  payload,
  messageSuccess,
  resetModal,
  setIsSpinning
});

export const updateDomainList = (payload) => ({
  type: UPDATE_DOMAIN_LIST,
  payload,
});

export const getLandingPagePerPage = (payload) => ({
  type: GET_ALL_LANDING_PAGES_PER_PAGE,
  payload,
});

export const setAllLandingPages = (payload) => ({
  type: SET_ALL_LANDING_PAGES,
  payload,
});

export const cloneLandingPage = (
  id,
  values,
  messageSuccess,
  resetModal,
  searchData
) => ({
  type: CLONE_LANDING_PAGE,
  id,
  values,
  messageSuccess,
  resetModal,
  searchData,
});

export const postNewLandingPage = (values, messageSuccess, resetModal) => ({
  type: POST_NEW_LANDING_PAGE,
  values,
  messageSuccess,
  resetModal,
});

export const getLandingPage = (id) => ({
  type: GET_LANDING_PAGE_BY_ID,
  id,
});

export const setLandingPage = (payload) => ({
  type: SET_LANDING_PAGE,
  payload,
});

export const deleteLandingPage = (payload) => ({
  type: DELETE_LANDING_PAGE,
  payload,
});

export const upadateLandingPage = (id, payload, messageSuccess) => ({
  type: UPDATE_LANDING_PAGE,
  id,
  payload,
  messageSuccess,
});

export const updateLandingPageInfo = (payload) => ({
  type: UPDATE_LANDING_PAGE_INFO,
  payload,
});

export const updateStep = (
  pageId,
  id,
  payload,
  messageSuccess,
  setIsLoading
) => ({
  type: UPDATE_STEP,
  pageId,
  id,
  payload,
  messageSuccess,
  setIsLoading,
});

export const getStepById = (pageId, id) => ({
  type: GET_STEP_BY_ID,
  pageId,
  id,
});

export const setStep = (payload) => ({
  type: SET_STEP,
  payload,
});

export const getStepsByPage = (payload) => ({
  type: GET_ALL_STEPS_BY_PAGE,
  payload,
});

export const setStepsByPage = (payload) => ({
  type: SET_ALL_STEPS_BY_PAGE,
  payload,
});

export const reorderSteps = (payload, id) => ({
  type: REORDER_STEPS,
  payload,
  id,
});

export const deleteStep = (payload) => ({
  type: DELETE_STEP,
  payload,
});

export const updateStepList = (payload) => ({
  type: UPDATE_STEP_LIST,
  payload,
});

export const removeStepFromList = (payload) => ({
  type: REMOVE_STEP_FROM_LIST,
  payload,
});

export const cloneStep = (
  payload,
  messageSuccess,
  resetModal,
  isTheSamePage
) => ({
  type: CLONE_STEP,
  payload,
  messageSuccess,
  resetModal,
  isTheSamePage,
});

export const pushStepToList = (payload) => ({
  type: PUSH_STEP_TO_LIST,
  payload,
});

export const getLandingPageTemplates = (setTemplateListLoading) => ({
  type: GET_LANDING_PAGE_TEMPLATES,
  setTemplateListLoading,
});

export const setLandingPageTemplates = (payload) => ({
  type: SET_LANDING_PAGE_TEMPLATES,
  payload,
});

export const addNewStep = (
  pageId,
  payload,
  messageSuccess,
  resetModal,
  setOpenStep
) => ({
  type: ADD_NEW_STEP,
  pageId,
  payload,
  messageSuccess,
  resetModal,
  setOpenStep,
});

export const updateStepChatWidget = (payload) => ({
  type: UPDATE_STEP_CHAT_WIDGET,
  payload,
});


export const setLandingPageContentRequest = (payload) => ({
  type: SET_LANDING_PAGE_REQUEST,
  payload
});

export const createLandingPageContent = (payload, successMessage, setLoaderActive) => ({
  type: CREATE_LANDING_PAGE_CONTENT,
  payload,
  setLoaderActive,
  successMessage,
});

export const updateLandingPageContent = (payload, id, successMessage) => ({
  type: UPDATE_LANDING_PAGE_CONTENT,
  payload,
  id,
  successMessage 
}) 