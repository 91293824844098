export const GET_ALL_DOCUMENTS = "GET_ALL_DOCUMENTS";
export const SET_ALL_DOCUMENTS = "SET_ALL_DOCUMENTS";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const SET_UPLOAD_DOCUMENT = "SET_UPLOAD_DOCUMENT";
export const RENAME_DOCUMENT = "RENAME_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const SEND_MASS_DOCUMENT = "SEND_MASS_DOCUMENT";
export const GET_CONTACT_DOCUMENTS = "GET_CONTACT_DOCUMENTS";
export const SET_CONTACT_DOCUMENTS = "SET_CONTACT_DOCUMENTS";
export const SET_LOADER = "SET_LOADER";
export const GET_LOADER = "GET_LOADER";
