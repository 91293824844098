export const API_BASE_URL = "http://localhost:8000";

export const TIMER_DECISION = {
  STOP: "STOP",
  START: "START",
};

export const ORDERING = {
  DESC: "descend",
  ASC: "ascend",
};
