import produce from "immer";
import {
  SET_BUSINESS_SUGESTIONS,
  SET_RATINGS,
  SET_REVIEWS,
  SET_SLUG_INFORMATION,
} from "redux/constants/Reviews";
import suggestionsHelper from 'helpers/businessSuggestionStateHelper';


export const initialState = {
  business_sugestions: suggestionsHelper.generateInitialBussinessSuggestions(),
  ratings: [],
  reviews: [],
  slugs: [],
};

const reviewsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_BUSINESS_SUGESTIONS:
        const oldData = { ...draft.business_sugestions };
        draft.business_sugestions = suggestionsHelper.overrideBussinessSuggestions(payload, oldData);
        break;
      case SET_RATINGS:
        draft.ratings = payload;
        break;
      case SET_REVIEWS:
        draft.reviews = payload;
        break;
      case SET_SLUG_INFORMATION:
        draft.slugs = payload;
        break;
      default:
        return state;
    }
  });

export default reviewsReducer;
