import { createSelector } from "reselect";
import { initTheme } from "./redux/reducers/Theme";

const selectApp = (state) => state.theme || initTheme;
const selectAuth = (state) => state.auth || initTheme;

const isLightTheme = () =>
  createSelector(selectApp, (substate) =>
    substate.currentTheme === "light" ? true : false
  );

const makeSelectIsAuthenticated = () =>
  createSelector(selectAuth, (substate) => !!substate.token);

const makeSelectIsNewUser = () =>
  createSelector(selectAuth, (substate) => !!substate.isNewUser);

export { isLightTheme, makeSelectIsAuthenticated, makeSelectIsNewUser };
