import produce from "immer";
import {
  SET_NEW_CHANGE_FUNNEL_TRIGGER,
  SET_TRIGGER,
  SET_TRIGGERS_PAGINATION,
  SET_UPDATED_TRIGGER,
  UPDATE_SINGLE_TRIGGER,
} from "redux/constants/Trigger";

export const initialState = {
  newTreatment: null,
  funnelChangeTriggers: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
  },
  singleTrigger: null,
};

const triggerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_NEW_CHANGE_FUNNEL_TRIGGER:
        draft.funnelChangeTriggers = payload;
        break;
      case SET_TRIGGERS_PAGINATION:
        draft.funnelChangeTriggers = {
          count: payload.count,
          current: payload.current,
          next: payload.next,
          previous: payload.previous,
          total_pages: payload.total_pages,
          results: payload.results,
          isLoading: false,
        };
        break;
      case SET_TRIGGER:
        draft.singleTrigger = payload;
        break;
      case SET_UPDATED_TRIGGER:
        let indexChangeAction = draft.funnelChangeTriggers.results.findIndex(
          (el) => el.connected_trigger_id === payload.id && el.trigger_type == payload.trigger_type
        );
        draft.funnelChangeTriggers.results[indexChangeAction].name = payload.name;
        draft.singleTrigger = null;
        break;
      case UPDATE_SINGLE_TRIGGER:
        draft.singleTrigger = payload;
        break;
      default:
        return state;
    }
  });

export default triggerReducer;
