import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import {
  GET_STRIPE_CONNECT_URL,
  GET_STRIPE_ACCOUNT_ID,
  CREATE_INVOICE_URL,
  GET_TAXES,
  CREATE_TAX,
  DELETE_TAX,
  GET_PRODUCTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  CREATE_PRICE,
  EDIT_PRICE,
  DELETE_PRICE,
  CHANGE_TYPE,
  LIST_PRICES,
} from "redux/constants/PaymentIntegrations";
import PaymentIntegrationService from "services/PaymentIntegrationService";
import {
  setStripeConnectURL,
  setStripeAccountId,
  setInvoiceURL,
  setTaxes,
  setProducts,
  setLoader,
  setPrice,
} from "redux/actions/PaymentIntegrations";
import { message } from "antd";
import unpackAndShowErrors from "helpers/djangoErrorUnapacker";

export function* getStripeConnectURL() {
  yield takeEvery(GET_STRIPE_CONNECT_URL, function* () {
    try {
      const response = yield call(
        PaymentIntegrationService.getStripeConnectURL
      );
      yield put(setStripeConnectURL(response?.data?.connect_url));
    } catch (err) {
      message.error(err.response.data);
    }
  });
}

export function* connectToStripe() {
  yield takeEvery(GET_STRIPE_ACCOUNT_ID, function* ({ payload }) {
    try {
      const response = yield call(
        PaymentIntegrationService.getStripeUserAccountId,
        payload
      );
      yield put(setStripeAccountId(response?.data));
    } catch (err) {
      message.error(err.response.data);
    }
  });
}

export function* getTaxData() {
  yield takeEvery(GET_TAXES, function* ({ payload }) {
    try {
      const response = yield call(
        PaymentIntegrationService.getTaxData,
        payload
      );
      yield put(setTaxes(response?.data));
    } catch (err) {
      message.error(err.response.data);
    }
  });
}

export function* createTax() {
  yield takeEvery(CREATE_TAX, function* ({ payload }) {
    try {
      yield call(PaymentIntegrationService.createTax, payload);
      const response = yield call(PaymentIntegrationService.getTaxData, {
        page: 1,
      });
      yield put(setTaxes(response?.data));
      yield put(setLoader(false));
    } catch (err) {
      yield put(setLoader(false));
      const keyError = Object.keys(err.response.data)?.[0];
      if (keyError) {
        message.error(err.response.data[keyError], 3);
      }
    }
  });
}

export function* deleteTax() {
  yield takeEvery(DELETE_TAX, function* ({ payload }) {
    try {
      yield call(PaymentIntegrationService.deleteTax, payload);
      const response = yield call(PaymentIntegrationService.getTaxData, {
        page: 1,
      });
      yield put(setTaxes(response?.data));
      yield put(setLoader(false));
    } catch (err) {
      yield put(setLoader(false));
      const keyError = Object.keys(err.response.data)?.[0];
      if (keyError) {
        message.error(err.response.data[keyError], 3);
      }
    }
  });
}

export function* createStripeInvoiceUrl() {
  yield takeEvery(CREATE_INVOICE_URL, function* ({ payload }) {
    try {
      const response = yield call(
        PaymentIntegrationService.createInvoiceURL,
        payload
      );
      yield put(setInvoiceURL(response?.data?.hosted_invoice_url));
    } catch (err) {
      yield put(setLoader(false));
      message.error(err?.response?.data, 3);
    }
  });
}

export function* getProducts() {
  yield takeEvery(GET_PRODUCTS, function* ({ payload }) {
    try {
      const response = yield call(
        PaymentIntegrationService.getProducts,
        payload
      );
      yield put(setProducts(response?.data));
    } catch (err) {
      message.error(err.response.data);
    }
  });
}

export function* createProducts() {
  yield takeEvery(
    CREATE_PRODUCT,
    function* ({ payload, successMessage, setPage }) {
      try {
        yield call(PaymentIntegrationService.createProduct, payload);
        const response = yield call(PaymentIntegrationService.getProducts, {
          page: 1,
          type: payload.get("type"),
        });
        yield put(setProducts(response?.data));
        yield put(setLoader(false));
        message.success(successMessage, 3);
        setPage(1);
      } catch (err) {
        yield put(setLoader(false));
        const keyError = Object.keys(err.response.data)?.[0];
        if (keyError) {
          message.error(err.response.data[keyError], 3);
        }
      }
    }
  );
}

export function* editProducts() {
  yield takeEvery(
    UPDATE_PRODUCT,
    function* ({ payload, productId, successMessage, page }) {
      try {
        yield call(PaymentIntegrationService.editProduct, payload, productId);
        const response = yield call(PaymentIntegrationService.getProducts, {
          page: page,
          type: payload.get("type"),
        });
        yield put(setProducts(response?.data));
        yield put(setLoader(false));
        message.success(successMessage, 3);
      } catch (err) {
        yield put(setLoader(false));
        const keyError = Object.keys(err.response.data)?.[0];
        if (keyError) {
          message.error(err.response.data[keyError], 3);
        }
      }
    }
  );
}

export function* deleteProducts() {
  yield takeEvery(DELETE_PRODUCT, function* ({ payload }) {
    try {
      yield call(PaymentIntegrationService.deleteProduct, payload?.id);
      const response = yield call(PaymentIntegrationService.getProducts, {
        page: 1,
        type: payload?.type,
      });
      yield put(setProducts(response?.data));
    } catch (err) {
      yield put(setLoader(false));
      const keyError = Object.keys(err.response.data)?.[0];
      if (keyError) {
        message.error(err.response.data[keyError], 3);
      }
    }
  });
}

export function* listPrices() {
  yield takeEvery(LIST_PRICES, function* ({ productData }) {
    try {
      const response = yield call(
        PaymentIntegrationService.listPrices,
        productData
      );

      yield put(setPrice(response?.data?.results));
      yield put(setLoader(false));
    } catch (err) {
      yield put(setLoader(false));
      const keyError = Object.keys(err.response.data)?.[0];
      if (keyError) {
        message.error(err.response.data[keyError], 3);
      }
    }
  });
}

export function* createPrice() {
  yield takeEvery(
    CREATE_PRICE,
    function* ({ payload, productData, successMessage }) {
      try {
        yield call(PaymentIntegrationService.createPrice, payload, productData);
        const response = yield call(
          PaymentIntegrationService.listPrices,
          productData
        );
        yield put(setPrice(response?.data?.results));
        message.success(successMessage, 3);
      } catch (err) {
        yield put(setLoader(false));
        const keyError = Object.keys(err.response.data)?.[0];
        if (keyError) {
          message.error(err.response.data[keyError], 3);
        }
      }
    }
  );
}

export function* editPrice() {
  yield takeEvery(
    EDIT_PRICE,
    function* ({ payload, productData, successMessage, priceId }) {
      try {
        yield call(
          PaymentIntegrationService.editPrice,
          payload,
          productData,
          priceId
        );
        const response = yield call(
          PaymentIntegrationService.listPrices,
          productData
        );
        yield put(setPrice(response?.data?.results));
        message.success(successMessage, 3);
      } catch (err) {
        yield put(setLoader(false));
        const keyError = Object.keys(err.response.data)?.[0];
        if (keyError) {
          message.error(err.response.data[keyError], 3);
        }
      }
    }
  );
}

export function* deletePrice() {
  yield takeEvery(
    DELETE_PRICE,
    function* ({ priceId, productData, successMessage }) {
      try {
        yield call(PaymentIntegrationService.deletePrice, productData, priceId);
        const response = yield call(
          PaymentIntegrationService.listPrices,
          productData
        );
        yield put(setPrice(response?.data?.results));
        message.success(successMessage, 3);
      } catch (err) {
        yield put(setLoader(false));
        const keyError = Object.keys(err.response.data)?.[0];
        if (keyError) {
          message.error(err.response.data[keyError], 3);
        }
      }
    }
  );
}

export function* changeTypeOfProduct() {
  yield takeEvery(CHANGE_TYPE, function* ({ payload, successMessage }) {
    try {
      yield call(PaymentIntegrationService.changeProductType, payload?.id);
      const response = yield call(PaymentIntegrationService.getProducts, {
        search: "",
        page: 1,
        type: payload?.type,
      });
      message.success(successMessage, 3);
      yield put(setLoader(false));
      yield put(setProducts(response?.data));
    } catch (err) {
      yield put(setLoader(false));
      unpackAndShowErrors(err?.response?.data);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getStripeConnectURL),
    fork(connectToStripe),
    fork(getTaxData),
    fork(createTax),
    fork(deleteTax),
    fork(createStripeInvoiceUrl),
    fork(getProducts),
    fork(createProducts),
    fork(editProducts),
    fork(deleteProducts),
    fork(createPrice),
    fork(editPrice),
    fork(deletePrice),
    fork(listPrices),
    fork(changeTypeOfProduct),
  ]);
}
