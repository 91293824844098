import { API_PREVIEW_LANDING_PAGE_PROTOCOL } from "configs/AppConfig";

export const URL = `${API_PREVIEW_LANDING_PAGE_PROTOCOL}%s%s`;

export const MODE_TYPE = {
  STEPS: "steps",
  SETTINGS: "settings",
  OVERVIEW: "overview",
  PUBLISHING: "publishing",
};

export const TABS = {
  STEPS: "steps",
  SETTINGS: "settings",
  OVERVIEW: "overview",
  PUBLISHING: "publishing",
};

export const TAB_TO_MODE = {
  [TABS.STEPS]: MODE_TYPE.STEPS,
  [TABS.SETTINGS]: MODE_TYPE.SETTINGS,
  [TABS.OVERVIEW]: MODE_TYPE.OVERVIEW,
  [TABS.PUBLISHING]: MODE_TYPE.PUBLISHING,
};

export const KEY_DOMAIN = "?key=4";

export const REGEX_URL =
  "^((?:https?://)(?:[-a-zA-Z0-9%]+.)*[-a-zA-Z0-9%]+\\.(?:jpeg|ico|png|gif|svg)$)$";

export const POPUP = "popup";
export const NEXT = "next";

export const MAX_SIZE_IMG = 3000000;

export const KEY_LOADER = "updatable";

export const TREATMENT_NAME_MAX_LENGTH = 200
export const TREATMENT_IMAGE_MAX_SIZE = 1024 * 1024 * 5;

export const BULLET_TEXT_MAX_LENGTH = 100;
export const MAX_NUMBER_OF_BULLETS = 5;
export const MAX_NUMBER_OF_COMMENTS = 5;

export const LANDING_PAGE_DOCTOR_PERFORMER = 'Doctor';
export const LANDING_PAGE_PRACTICE_PERFORMER = 'Practice';

export const LANDING_PAGE_IMAGE_TITLE_MAX_LENGTH = 100;
export const LANDING_PAGE_IMAGE_DESCRIPTION_MAX_LENGTH = 255;

export const MAX_NUMBER_OF_IMAGES = 2;