import {
  CREATE_AWARD,
  GET_AWARDS,
  SET_AWARDS,
  GET_SINGLE_AWARD,
  SET_SINGLE_AWARD,
  EDIT_AWARD,
  ADD_AWARD_TO_USER,
  GET_USER_LIST,
  SET_AWARD_USER_LIST,
  ADD_USER_TO_LATEST,
  GET_SINGLE_USER_AWARDS,
  SET_SINGLE_USER_AWARDS,
  ADD_POINTS,
  SET_NEW_POINTS,
  REDEEM_AWARD,
  SET_REDEEMED_AWARD,
  DELETE_AWARD,
  ARCHIVE_AWARD,
  GET_ARCHIVED_AWARDS,
  SET_ARCHIVED_AWARDS,
  ACTIVATE_AWARD,
  SET_SINGLE_ARHIVED_AWARD,
  CHANGE_STATUS_ARHIVED_AWARD,
  GET_SINGLE_ARHIVED_AWARD,
  GET_AWARDS_ACTIVE_POINTS,
  REENROLL_TO_AWARD,
  GET_AVAILABLE_AWARDS,
  SET_AVAILABLE_AWARDS
} from "redux/constants/Awards";

export const getAwards = (payload) => ({
  type: GET_AWARDS,
  payload,
});

export const createAward = (payload, successMessage) => ({
  type: CREATE_AWARD,
  payload,
  successMessage,
});

export const setAwards = (payload) => ({
  type: SET_AWARDS,
  payload,
});

export const getSingleAward = (payload) => ({
  type: GET_SINGLE_AWARD,
  payload,
});

export const setSingleAward = (payload) => ({
  type: SET_SINGLE_AWARD,
  payload,
});

export const editAward = (payload, successMessage) => ({
  type: EDIT_AWARD,
  payload,
  successMessage,
});

export const addAwardToUser = (payload, successMessage) => ({
  type: ADD_AWARD_TO_USER,
  payload,
  successMessage,
});

export const getUserList = (payload) => ({
  type: GET_USER_LIST,
  payload,
});

export const setUserList = (payload) => ({
  type: SET_AWARD_USER_LIST,
  payload,
});

export const addUserToLatestUsers = (payload) => ({
  type: ADD_USER_TO_LATEST,
  payload,
});

export const getSingleUserAwards = (payload) => ({
  type: GET_SINGLE_USER_AWARDS,
  payload,
});

export const setSingleUserAwards = (payload) => ({
  type: SET_SINGLE_USER_AWARDS,
  payload,
});

export const addPoints = (payload, successMessage, failMessage) => ({
  type: ADD_POINTS,
  payload,
  successMessage,
  failMessage,
});

export const setNewPoints = (payload) => ({
  type: SET_NEW_POINTS,
  payload,
});

export const redeemAward = (payload, successMessage, isModalVisible) => ({
  type: REDEEM_AWARD,
  payload,
  successMessage,
  isModalVisible,
});

export const setRedeemedAward = (payload) => ({
  type: SET_REDEEMED_AWARD,
  payload,
});

export const deleteAward = (payload, successMessage) => ({
  type: DELETE_AWARD,
  payload,
  successMessage,
});

export const archiveAward = (payload, successMessage, setPage) => ({
  type: ARCHIVE_AWARD,
  payload,
  successMessage,
  setPage,
});

export const getArchivedAwards = (payload) => ({
  type: GET_ARCHIVED_AWARDS,
  payload,
});

export const setArchivedAwards = (payload) => ({
  type: SET_ARCHIVED_AWARDS,
  payload,
});

export const activateAward = (payload, awardId, successMessage) => ({
  type: ACTIVATE_AWARD,
  payload,
  awardId,
  successMessage,
});

export const setSingleArchivedAward = (payload) => ({
  type: SET_SINGLE_ARHIVED_AWARD,
  payload,
});

export const changeStatusArhivedAword = (payload) => ({
  type: CHANGE_STATUS_ARHIVED_AWARD,
  payload,
});

export const getSingleArchivedAward = (payload) => ({
  type: GET_SINGLE_ARHIVED_AWARD,
  payload,
});

export const getAwardsActivePoints = (payload) => ({
  type: GET_AWARDS_ACTIVE_POINTS,
  payload,
});

export const reEnrollToAward = (payload, successMessage) => ({
  type: REENROLL_TO_AWARD,
  payload,
  successMessage,
});

export const getAvailableAwards=(payload)=>({
  type: GET_AVAILABLE_AWARDS,
  payload
})

export const setAvailableAwards=(payload)=>({
  type: SET_AVAILABLE_AWARDS,
  payload
})