import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  GET_CONTACT_SETTINGS,
  STORE_CONTACT_SETTINGS,
  DELETE_CONTACT_SETTINGS,
} from "../constants/ContactSettings";
import {
  setContactSettings,
  showMessage,
  updateContactSettings,
} from "../actions/ContactSettings";
import ContactSettingsService from "services/ContactSettingsService";

export function* getContactsSettings() {
  yield takeEvery(GET_CONTACT_SETTINGS, function* () {
    try {
      const contactsSettings = yield call(
        ContactSettingsService.getContactSettings
      );

      yield put(setContactSettings(contactsSettings));
    } catch (err) {
      yield put(showMessage(err));
    }
  });
}

export function* storeContactsSettings() {
  yield takeEvery(STORE_CONTACT_SETTINGS, function* ({ payload }) {
    try {
      const contactSettings = yield call(
        ContactSettingsService.setContactSettings,
        payload
      );
      yield put(setContactSettings(contactSettings));
    } catch (err) {
      yield put(showMessage(err));
    }
  });
}

export function* deleteContactSettings() {
  yield takeEvery(DELETE_CONTACT_SETTINGS, function* ({ payload }) {
    try {
      yield call(ContactSettingsService.deleteContactSettings, payload.id);
      yield put(updateContactSettings(payload.data));
    } catch (err) {
      yield put(showMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getContactsSettings),
    fork(deleteContactSettings),
    fork(storeContactsSettings),
  ]);
}
