export const POST_NEW_TEMPLATE = "POST_NEW_TEMPLATE";
export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
export const SET_ALL_TEMPLATES = "SET_ALL_TEMPLATES";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const UPDATE_TEMPLATE_CONTENT = "UPDATE_TEMPLATE_CONTENT";
export const UPDATE_TEMPLATE_LIST = "UPDATE_TEMPLATE_LIST";
export const GET_TEMPLATE_BY_ID = "GET_TEMPLATE_BY_ID";
export const SET_TEMPLATE_BY_ID = "SET_TEMPLATE_BY_ID";
export const PUSH_TEMPLATE_TO_LIST = "PUSH_TEMPLATE_TO_LIST";
export const TEST_SEND_EMAIL = "TEST_SEND_EMAIL";
export const GET_EMAIL_FORM_TEMPLATES = "GET_EMAIL_FORM_TEMPLATES";
export const SET_EMAIL_FORM_TEMPLATES = "SET_EMAIL_FORM_TEMPLATES";
export const CREATE_EMAIL_FORM_TEMPLATE = "CREATE_EMAIL_FORM_TEMPLATE";
export const GET_TEMPLATE_LIST_CONTENT = "GET_TEMPLATE_LIST_CONTENT";
export const SEND_MASS_EMAIL = "SEND_MASS_EMAIL";
export const SET_SINGLE_TEMPLATE_ID = "SET_SINGLE_TEMPLATE_ID";
export const GET_OVERALL_EMAIL_STATS = "GET_OVERALL_EMAIL_STATS";
export const SET_OVERALL_EMAIL_STATS = "SET_OVERALL_EMAIL_STATS";
export const GET_EMAIL_STATS = "GET_EMAIL_STATS";
export const SET_EMAIL_STATS = "SET_EMAIL_STATS";
export const GET_EMAIL_CONTACTS = "GET_EMAIL_CONTACTS";
export const SET_EMAIL_CONTACTS = "SET_EMAIL_CONTACTS";
