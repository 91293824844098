import {
  GET_AVAILABLE_CONTACTS_FOR_LOYALTY_APP,
  GET_LOYALTY_APP_CONTACTS,
  POST_LOYALTY_USER,
  SET_AVAILABLE_CONTACTS_FOR_LOYALTY_APP,
  SET_AVAILABLE_CONTACTS_ORDER,
  SET_LOYALTY_APP_CONTACTS,
  SET_CONTACTS_IN_LOYALTY_APP_ORDER,
} from "redux/constants/LoyaltyUsers";

export const getAvailableContactsForLoyaltyApp = (payload) => ({
  type: GET_AVAILABLE_CONTACTS_FOR_LOYALTY_APP,
  payload,
});

export const setAvailableContacts = (payload) => ({
  type: SET_AVAILABLE_CONTACTS_FOR_LOYALTY_APP,
  payload,
});

export const postNewLoyaltyUser = (payload, messageSuccess, getData) => ({
  type: POST_LOYALTY_USER,
  payload,
  messageSuccess,
  getData,
});

export const setAvailableContactsOrder = (payload) => ({
  type: SET_AVAILABLE_CONTACTS_ORDER,
  payload,
});

export const setLoyaltyAppContacts = (payload) => ({
  type: SET_LOYALTY_APP_CONTACTS,
  payload,
});

export const getLoyaltyAppContacts = (payload) => ({
  type: GET_LOYALTY_APP_CONTACTS,
  payload,
});

export const setContactsInLoyatyAppOrder = (payload) => ({
  type: SET_CONTACTS_IN_LOYALTY_APP_ORDER,
  payload,
});
