export const BUSINESS_NAME_MAX_LEN = 60;
export const FIRST_NAME_MAX_LEN = 30;
export const LAST_NAME_MAX_LEN = 60;
export const EMAIL_MAX_LENGTH = 150;
export const BUSINESS_TITLE_MAX_LEN = 50;

export const BUSINESS_TYPE = {
  SOLE_PROPRIETORSHIP: "Sole Proprietorship",
  PARTNERSHIP: "Partnership",
  LIMITED_LIAB_CORP: "Limited Liability Corporation",
  CO_OPERATIVE: "Co-operative",
  NON_PROFIT_CORP: "Non-profit Corporation",
  CORPORATION: "Corporation",
};

export const JOB_POSITION = {
  DIRECTOR: "Director",
  GM: "GM",
  VP: "VP",
  CEO: "CEO",
  CFO: "CFO",
  GENERAL_COUNSEL: "General Counsel",
  OTHER: "Other",
};

export const PANELS = {
  BUSINESS_INFORMATIONS: "Business_Information",
  AUTHORIZED_REPRESENTATIVE: "Authorized_Representative",
  ADDRESS: "address",
};

export const BRAND_STATUS = {
  COMPLETE: "COMPLETE",
  INCOMPLETE: "INCOMPLETE",
  APPROVED: "APPROVED",
  FAILED: "FAILED",
  IN_REVIEW: "IN REVIEW",
  PENDING: "PENDING"
};

export const BRAND_FIELD_NAME = {
  BUSINESS: "registrated_business",
  AUTH_REP: "authorized_representative",
  ADDRESS: "address",
};

export const EIN_LOOKUP_SERVIC_URL = "https://www.hipaaspace.com/ein/ein_verification/";
