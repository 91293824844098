import {
  POST_NEW_TEMPLATE,
  DELETE_TEMPLATE,
  GET_ALL_TEMPLATES,
  SET_ALL_TEMPLATES,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_LIST,
  GET_TEMPLATE_BY_ID,
  PUSH_TEMPLATE_TO_LIST,
  TEST_SEND_EMAIL,
  GET_EMAIL_FORM_TEMPLATES,
  SET_EMAIL_FORM_TEMPLATES,
  CREATE_EMAIL_FORM_TEMPLATE,
  SET_TEMPLATE_BY_ID,
  UPDATE_TEMPLATE_CONTENT,
  GET_TEMPLATE_LIST_CONTENT,
  SEND_MASS_EMAIL,
  SET_SINGLE_TEMPLATE_ID,
  GET_OVERALL_EMAIL_STATS,
  SET_OVERALL_EMAIL_STATS,
  GET_EMAIL_STATS,
  SET_EMAIL_STATS,
  GET_EMAIL_CONTACTS,
  SET_EMAIL_CONTACTS,
} from "./../constants/HtmlBuilder";

export const postNewTemplate = (payload, messageSuccess, resetModal) => ({
  type: POST_NEW_TEMPLATE,
  payload,
  messageSuccess,
  resetModal,
});

export const sendMassEmail = (payload, messageSuccess) => ({
  type: SEND_MASS_EMAIL,
  payload,
  messageSuccess,
});

export const deleteTemplate = (payload, pageNumber) => ({
  type: DELETE_TEMPLATE,
  payload,
  pageNumber,
});

export const getAllTemplates = (payload) => ({
  type: GET_ALL_TEMPLATES,
  payload,
});

export const setAllTemplates = (payload) => ({
  type: SET_ALL_TEMPLATES,
  payload,
});

export const getTemplateContent = (payload) => ({
  type: GET_TEMPLATE_LIST_CONTENT,
  payload,
});

export const updateTemplate = (payload, messageSuccess, resetModal) => ({
  type: UPDATE_TEMPLATE,
  payload,
  messageSuccess,
  resetModal,
});

export const updateTemplateContent = (payload, messageSuccess, redirect) => ({
  type: UPDATE_TEMPLATE_CONTENT,
  payload,
  messageSuccess,
  redirect,
});

export const updateTemplateList = (payload) => ({
  type: UPDATE_TEMPLATE_LIST,
  payload,
});

export const getTemplateById = (payload) => ({
  type: GET_TEMPLATE_BY_ID,
  payload,
});

export const setTemplateById = (payload) => ({
  type: SET_TEMPLATE_BY_ID,
  payload,
});

export const pushTemplateToList = (payload) => ({
  type: PUSH_TEMPLATE_TO_LIST,
  payload,
});

export const testSendEmail = (payload, messageSuccess) => ({
  type: TEST_SEND_EMAIL,
  payload,
  messageSuccess,
});

export const getEmailFormTemplates = () => ({
  type: GET_EMAIL_FORM_TEMPLATES,
});

export const setEmailFormTemplates = (payload) => ({
  type: SET_EMAIL_FORM_TEMPLATES,
  payload,
});

export const createEmailFormTemplate = (
  payload,
  successMessage,
  setCreationDisabled,
  setIsLoading,
  redirect
) => ({
  type: CREATE_EMAIL_FORM_TEMPLATE,
  payload,
  successMessage,
  setCreationDisabled,
  setIsLoading,
  redirect,
});

export const setCreatedTemplateId = (payload) => ({
  type: SET_SINGLE_TEMPLATE_ID,
  payload,
});

export const getOvarallEmailStats = (payload) => ({
  type: GET_OVERALL_EMAIL_STATS,
  payload,
});

export const setOvarallEmailStats = (payload) => ({
  type: SET_OVERALL_EMAIL_STATS,
  payload,
});

export const getEmailStats = (payload) => ({
  type: GET_EMAIL_STATS,
  payload,
});

export const setEmailStats = (payload) => ({
  type: SET_EMAIL_STATS,
  payload,
});

export const getEmailContacts = (payload) => ({
  type: GET_EMAIL_CONTACTS,
  payload,
});

export const setEmailContacts = (payload) => ({
  type: SET_EMAIL_CONTACTS,
  payload,
});
