import {
  GET_BRAND_REGISTRATION,
  POST_BRAND_REGISTRATION,
  RESUBMIT_BRAND_REGISTRATION,
  SET_BRAND_REGISTRATION,
  SUBMIT_BRAND_REGISTRATION,
  UPDATE_BRAND_REGISTRATION,
} from "redux/constants/TwilioBrandRegostration";

export const postBrandData = (payload) => ({
  type: POST_BRAND_REGISTRATION,
  payload,
});

export const setBrandData = (payload) => ({
  type: SET_BRAND_REGISTRATION,
  payload,
});

export const getBrandData = () => ({
  type: GET_BRAND_REGISTRATION,
});

export const updateBrandData = (payload, id) => ({
  type: UPDATE_BRAND_REGISTRATION,
  payload,
  id,
});

export const submitBrandRegistration = () => ({
  type: SUBMIT_BRAND_REGISTRATION,
});

export const resubmitBrandRegistration = () => ({
  type: RESUBMIT_BRAND_REGISTRATION,
});
