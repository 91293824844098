export const SET_FUNNELS = "SET_FUNNELS";
export const GET_FUNNELS = "GET_FUNNELS";
export const CREATE_FUNNEL = "CREATE_FUNNEL";
export const UPDATE_FUNNELS = "UPDATE_FUNNELS";
export const DELETE_FUNNEL = "DELETE_FUNNEL";
export const EDIT_FUNNEL = "EDIT_FUNNEL";
export const GET_FUNNEL_CONTACTS = "GET_FUNNEL_CONTACTS";
export const SET_FUNNEL_CONTACTS = "SET_FUNNEL_CONTACTS";
export const REORDER_FUNNELS = "REORDER_FUNNELS";
export const UPDATE_NAME_FUNNEL = "UPDATE_NAME_FUNNEL";
export const REORDER_CONTACTS = "REORDER_CONTACTS";
export const SET_ORDER_CONTACTS = "SET_ORDER_CONTACTS";
export const GET_REACTIVATION_FUNNELS = "GET_REACTIVATION_FUNNELS";
export const CLEARE_FUNNELS_DATA = "CLEARE_FUNNELS_DATA";
