import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import {
  APP_LOYALTY_PREFIX_PATH,
  APP_PREFIX_PATH,
  AUTH_LOYALTY_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  PUBLIC_PREFIX_PATH,
  UNSUBSCRIBE_PATH,
  AGENCY_PREFIX_PATH,
} from "configs/AppConfig";
import UnsubscribeLayout from "layouts/unsubscribe-layout";
import PublicLayout from "layouts/public-layout";
import AppLoyaltyLayout from "layouts/app-loyalty-layout";
import RoleRoute from "shared/RoleRoute";
import { ROLE } from "constants/RoleConstants";
import AuthLoyatyViews from "./loyalty-app/auth-loyaty-views";
import AgencyLayout from "layouts/agency-layout";
import { ROUTES } from "routes";

export const Views = (props) => {
  const { location } = props;
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={APP_PREFIX_PATH} />
      </Route>
      <Route path={AUTH_PREFIX_PATH}>
        <AuthLayout />
      </Route>
      <Route path={AUTH_LOYALTY_PREFIX_PATH}>
        <AuthLoyatyViews />
      </Route>
      <Route path={UNSUBSCRIBE_PATH}>
        <UnsubscribeLayout />
      </Route>
      <Route path={PUBLIC_PREFIX_PATH}>
        <PublicLayout />
      </Route>
      <RoleRoute
        path={APP_PREFIX_PATH}
        role={[ROLE.OWNER, ROLE.EMPLPOYEE]}
        redirectRoute={ROUTES.LOGIN}
      >
        <AppLayout location={location} />
      </RoleRoute>
      <RoleRoute
        path={APP_LOYALTY_PREFIX_PATH}
        role={[ROLE.PATIENT]}
        redirectRoute={ROUTES.LOYALTY_LOGIN}
      >
        <AppLoyaltyLayout location={location} />
      </RoleRoute>
      <RoleRoute
        path={AGENCY_PREFIX_PATH}
        role={[ROLE.AGENCY_MANAGER]}
        redirectRoute={ROUTES.LOGIN}
      >
        <AgencyLayout location={location} />
      </RoleRoute>
    </Switch>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { token } = auth;
  return { locale, token };
};

export default withRouter(connect(mapStateToProps)(Views));
