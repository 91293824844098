import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useLocation } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { signOut } from "redux/actions/Auth";
import { makeSelectCurrentUser } from "redux/selectors/Users";
import { makeSelectIsAuthenticated, makeSelectIsNewUser } from "selectors";
import { createStructuredSelector } from "reselect";
import { getLocalStorageItem, setLocalStorageItem } from "utils/localStorage";
import { ROUTES } from "routes";
import { ROLE_MANDATORY_ORGANIZATION } from "constants/RoleConstants";
import ErrorTwo from "views/auth-views/errors/error-page-2";

export function PrivateRoute({ component: Component, type, section, ...rest }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(makeSelectCurrentUser());
  const isAuthenticated = useSelector(makeSelectIsAuthenticated());
  const isNewUser = useSelector(makeSelectIsNewUser());
  const organizationNotExists = getLocalStorageItem("organizationNotExists");
  const activeSubscriptionNotExists = getLocalStorageItem(
    "activeSubscriptionNotExists"
  );
  const cardNotValid = getLocalStorageItem("cardNotValid");
  const [userHasPermission, setUserHasPermission] = useState(true);

  useEffect(() => {
    if (!!section) {
      setUserHasPermission(user?.app_access_list?.includes(section));
    }
  }, [Component]);

  useEffect(() => {
    const previousLink = location.pathname + location.search;
    const referrer = getLocalStorageItem("referrer");
    if (referrer === null && !isAuthenticated) {
      setLocalStorageItem("referrer", previousLink);
    }
  }, []);

  useEffect(() => {
    if (isNewUser && !type) {
      dispatch(signOut());
    }
  }, [dispatch, isNewUser, type]);

  const missingPaymentData =
    !organizationNotExists &&
    !user.is_premium_user &&
    (activeSubscriptionNotExists || cardNotValid);

  const missingOrganization =
    user &&
    organizationNotExists &&
    ROLE_MANDATORY_ORGANIZATION.includes(user?.role?.access_type);

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          <>
            {missingOrganization && <Redirect to={ROUTES.SETTINGS} />}
            {missingPaymentData && <Redirect to={`${ROUTES.SETTINGS}?key=2`} />}
            {userHasPermission ? <Component {...props} /> : <ErrorTwo />}
          </>
        ) : (
          <Redirect to={ROUTES.LOGIN} />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PrivateRoute);
