export const POST_NEW_SMS_TEMPLATE = "POST_NEW_SMS_TEMPLATE";
export const GET_ALL_SMS_TEMPLATES = "GET_ALL_SMS_TEMPLATES";
export const SET_ALL_SMS_TEMPLATES = "SET_ALL_SMS_TEMPLATES";
export const DELETE_SMS_TEMPLATE = "DELETE_SMS_TEMPLATE";
export const UPDATE_SMS_TEMPLATE = "UPDATE_SMS_TEMPLATE";
export const UPDATE_SMS_TEMPLATE_LIST = "UPDATE_SMS_TEMPLATE_LIST";
export const GET_SMS_TEMPLATE_BY_ID = "GET_SMS_TEMPLATE_BY_ID";
export const PUSH_SMS_TEMPLATE_TO_LIST = "PUSH_SMS_TEMPLATE_TO_LIST";
export const TEST_SEND_SMS = "TEST_SEND_SMS";
export const SET_SELECTED_SMS_TEMPLATE = "SET_SELECTED_SMS_TEMPLATE";
export const SEND_MASS_SMS = "SEND_MASS_SMS";
export const POST_NEW_TEMPLATE_AND_SEND_NOW = "POST_NEW_TEMPLATE_AND_SEND_NOW";
export const UPDATE_SMS_TEMPLATE_AND_SEND = "UPDATE_SMS_TEMPLATE_AND_SEND";
export const CREATE_NEW_DEFAULT_TEMPLATE = "CREATE_NEW_DEFAULT_TEMPLATE"
