import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { GET_BUSINESS_SUGESTIONS,
CREATE_SLUG_INFORMATION,
GET_RATINGS,
GET_REVIEWS,
CREATE_REVIEWS_FROM_SLUG,
PICK_RATING_DATA
} from "redux/constants/Reviews";
import ReviewsService from "../../services/ReviewsService";
import { setBusinessSugestions, setRatings, setReviews, setSlugInformation } from "redux/actions/Reviews";
import { message } from "antd";

export function* getBusinessSugetions() {
    yield takeEvery(GET_BUSINESS_SUGESTIONS, function* ({payload}) {
        try {
            const response = yield call(ReviewsService.findBusiness, payload);
            yield put(setBusinessSugestions(response.data));
        } catch (err) {
            message.error(err.response.data);
        }
    })
}

export function* createSlugs() {
    yield takeEvery(CREATE_SLUG_INFORMATION, function* ({payload, successMessage, setShowGetData}) {
        try {
            const response = yield call(ReviewsService.createSlugInformation, payload);
            yield put(setSlugInformation(response.data));
            message.success(successMessage, 2)
            setShowGetData(true);
        } catch (err) {
            console.log(err);
        }
    })
}

export function* getRatingsData() {
    yield takeEvery(GET_RATINGS, function* () {
        try {
            const response = yield call(ReviewsService.getRatings);
            yield put(setRatings(response.data));
        } catch (err) {
            console.log(err);
        }
    })
}

export function* getReviewsData() {
    yield takeEvery(GET_REVIEWS, function* () {
        try {
            const response = yield call(ReviewsService.getReviews);
            yield put(setReviews(response.data));
        } catch (err) {
            console.log(err);
        }
    })
}

export function* createReviewsFromSlug() {
    yield takeEvery(CREATE_REVIEWS_FROM_SLUG, function* ({payload}) {
        try {
            yield call(ReviewsService.createReviewsFromSlug, payload);
            yield call(ReviewsService.createRatingsFromSlug, payload);
        } catch (err) {
            message.error(err.response.data);            
        }
    })
}

export function* pickRatingData() {
    yield takeEvery(PICK_RATING_DATA, function* ({payload, setShowForm, successMessage}) {
        try {
            yield call(ReviewsService.pickRatingData, payload);
            setShowForm(false);
            message.success(successMessage, 3)
        } catch (err) {
            message.error(err.response.data);            
        }
    })
}



export default function* rootSaga() {
    yield all([
        fork(getBusinessSugetions),
        fork(createSlugs),
        fork(getRatingsData),
        fork(getReviewsData),
        fork(createReviewsFromSlug),
        fork(pickRatingData)
    ])
}