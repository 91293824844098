import {
  GET_TRANSACTIONS,
  SET_TRANSACTIONS,
  SET_TABLE_LOADING,
} from "redux/constants/Reporting";

export const getTransactions = (payload) => ({
  type: GET_TRANSACTIONS,
  payload,
});

export const setTransactions = (payload) => ({
  type: SET_TRANSACTIONS,
  payload,
});

export const setTableLoading = (payload) => ({
  type: SET_TABLE_LOADING,
  payload,
});
