import ApiService from "./ApiService";

const ENDPOINTS = {
    FIND_BUSINESS: "slugs/find_business/",
    CREATE_SLUGS: "slugs/",
    GET_RATINGS: "ratings/",
    GET_REVIEWS: "reviews/",
    CREATE_RATINGS_FROM_SLUG: "ratings/zembra/ratings/",
    CREATE_REVIEWS_FROM_SLUG: "reviews/zembra/reviews/",
    PICK_RATING_DATA: "ratings/pick_rating_data/",
}

class ReviewsService extends ApiService {
    findBusiness = (data) => {
        return this.apiClient.post(ENDPOINTS.FIND_BUSINESS, data);
    }

    createSlugInformation = (data) => {
        return this.apiClient.post(ENDPOINTS.CREATE_SLUGS, data);
    }

    getRatings = () => {
        return this.apiClient.get(ENDPOINTS.GET_RATINGS);
    }

    getReviews = () => {
        return this.apiClient.get(ENDPOINTS.GET_REVIEWS);
    }

    createRatingsFromSlug = (data) => {
        return this.apiClient.post(ENDPOINTS.CREATE_RATINGS_FROM_SLUG, data);
    }

    createReviewsFromSlug = (data) => {
        return this.apiClient.post(ENDPOINTS.CREATE_REVIEWS_FROM_SLUG, data);
    }

    pickRatingData = (data) => {
        return this.apiClient.post(ENDPOINTS.PICK_RATING_DATA, data);
    }

}

export default new ReviewsService();