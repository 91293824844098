import produce from "immer";
import { SET_CHAT_WIDGET, SET_LOADING, SET_AVATAR } from "../constants/ChatWidget";

export const initialState = {
    chatWidgetSettings: {},
    isLoading: true
}

const chatWidgetReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        const { type, payload } = action;
        switch(type) {
            case SET_CHAT_WIDGET:
                draft.chatWidgetSettings = payload;
                break;
            case SET_LOADING:
                draft.isLoading = payload;
                break;
            case SET_AVATAR:
                draft.chatWidgetSettings['avatar'] = payload;
                break;
            default:
                return state;
        }
    });

export default chatWidgetReducer;