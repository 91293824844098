// @ts-nocheck
/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import { updateActivityList } from "helpers/updateActivityList";
import {
  SET_COMMUNICATION_ACTIVITY,
  UPDATE_COMMUNICATION_ACTIVITY,
  SET_CHILDREN_EMAIL,
  SET_TOKEN_PHONE_CALL,
  ADD_DATA_TO_ACTIVITY,
} from "../constants/Activity";
import produce from "immer";

export const initialState = {
  communicationActivities: {},
  children: {},
  tokenPhoneCall: "",
};

const activityReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case SET_COMMUNICATION_ACTIVITY:
        draft.communicationActivities[payload.id] = {
          count: payload.data.count,
          page: payload.data.current,
          current: payload.data.current,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results:
            payload.data.current === 1
              ? payload.data.results
              : [
                  ...draft.communicationActivities[payload.id].results,
                  ...payload.data.results,
                ],
          isLoading: false,
        };
        break;

      case ADD_DATA_TO_ACTIVITY:
        if (draft.communicationActivities[payload.id] !== undefined) {
          const message = draft.communicationActivities[
            payload.id
          ].results.find((message) => message.id == payload.data.id);
          if (!message) {
            draft.communicationActivities[payload.id].results.unshift(
              payload.data
            );
            draft.communicationActivities[payload.id].count += 1;
          }
        }
        break;

      case UPDATE_COMMUNICATION_ACTIVITY:
        if (payload.data.contact.id in draft.communicationActivities) {
          const allActivities =
            draft.communicationActivities[payload.data.contact.id];
          updateActivityList(allActivities, payload);
        }
        break;

      case SET_CHILDREN_EMAIL:
        draft.children[payload.id] = { data: payload.data };
        break;
      case SET_TOKEN_PHONE_CALL:
        draft.tokenPhoneCall = payload;
    }
  });

export default activityReducer;
