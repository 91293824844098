import ApiService from "./ApiService";
import { format } from "util";
import { stringify } from "querystring";

const ENDPOINTS = {
  GET_SMS_TEMPLATES: "templates/?type=sms",
  POST_TEMPLATE: "templates/sms-template/",
  TEMPLATE_ITEM: "templates/%s/",
  PATCH_TEMPLATE: "templates/%s/update/",
  TEST_SMS_TEMPLATE: "twilio/test-sms/",
  SEND_MASS_SMS: "mass-sms/send/",
  DEFAULT_SMS_TEMPLATES: "default-sms-templates/?page=%s",
  POST_SPECIAL_TEMPLATE: "default-sms-templates/",
  GET_MASS_SMS_INFO: "mass-sms/%s/mass-sms-info/?ordering=%s&page=%s",
  COPY_SMS_TEMPLATE: "templates/%s/copy-sms-template/",
};

class SmsBuilderService extends ApiService {
  getTemplatesForPage = async (payload) => {
    return await this.apiClient.get(
      format(`${ENDPOINTS.GET_SMS_TEMPLATES}&${stringify(payload)}`)
    );
  };

  getTemplateById = async (data) => {
    return await this.apiClient.get(format(ENDPOINTS.TEMPLATE_ITEM, data));
  };

  postNewTemplate = async (data) => {
    return await this.apiClient.post(ENDPOINTS.POST_TEMPLATE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  updateTemplate = async (data) => {
    return await this.apiClient.patch(
      format(ENDPOINTS.PATCH_TEMPLATE, data.id),
      data.payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  deleteTemplate = async (id) => {
    return await this.apiClient.delete(format(ENDPOINTS.TEMPLATE_ITEM, id));
  };

  testSendSms = async (data) => {
    return await this.apiClient.post(ENDPOINTS.TEST_SMS_TEMPLATE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  sendMassSms = (data) => {
    return this.apiClient.post(ENDPOINTS.SEND_MASS_SMS, data);
  };

  getDefaultSmsTemplates = async ({ page }) => {
    return await this.apiClient.get(
      format(ENDPOINTS.DEFAULT_SMS_TEMPLATES, page)
    );
  };

  postNewSpecialTemplate = async (data) => {
    return await this.apiClient.post(ENDPOINTS.POST_SPECIAL_TEMPLATE, data);
  };

  getMassSmsInfo = async (id, order, page) => {
    return await this.apiClient.get(
      format(ENDPOINTS.GET_MASS_SMS_INFO, id, order, page)
    );
  };

  copySmsTemplate = (payload) => {
    return this.apiClient.post(
      format(ENDPOINTS.COPY_SMS_TEMPLATE, payload.id),
      payload.data
    );
  };
}

export default new SmsBuilderService();
