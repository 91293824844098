import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { 
    GET_NOTIFICATIONS,
    UPDATE_ALL_NOTIFICATIONS,
    UPDATE_SINGLE_NOTIFICATION,
} from '../constants/NotificationSettings';
import { setNotifications, setSingleNotification } from '../actions/NotificationSettings';
import NotificationService from '../../services/NotificationService';

export function* getNotifications() {
    yield takeEvery(GET_NOTIFICATIONS, function* () {
        try {
            const response = yield call(NotificationService.getNotifications);
            yield put(setNotifications(response.data));
        }catch (err) {
            console.log(err);
        }
    });
}

export function* updateAllNotifications() {
    yield takeEvery(UPDATE_ALL_NOTIFICATIONS, function* ({payload}) {
        try {
            const response = yield call(NotificationService.updateAllNotifications, payload);
            yield put(setNotifications(response.data));
        }catch (err) {
            console.log(err);
        }
    })
}

export function* updateSingleNotification() {
    yield takeEvery(UPDATE_SINGLE_NOTIFICATION, function* ({payload}){
        try {
            const response = yield call(NotificationService.updateSingleNotification, payload);
            yield put(setSingleNotification(response.data));
        }catch (err) {
            console.log(err);
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getNotifications),
        fork(updateAllNotifications),
        fork(updateSingleNotification)
    ])
}