import {
  GET_COMMUNICATION_ACTIVITY,
  SET_COMMUNICATION_ACTIVITY,
  SEND_SMS,
  UPDATE_COMMUNICATION_ACTIVITY,
  SEND_EMAIL,
  GET_CHILDREN_EMAIL,
  SET_CHILDREN_EMAIL,
  GET_HISTORY_ACTIVITY,
  SET_HISTORY_ACTIVITY,
  UPDATE_HISTORY_ACTIVITY,
  GET_TOKEN_PHONE_CALL,
  SET_TOKEN_PHONE_CALL,
  CALL_END,
  ADD_DATA_TO_ACTIVITY,
  PUSH_NEW_STATUS_CHANGE,
  SET_NEW_ACTIVITY,
  PUSH_NEW_AMOUNT_SPENT,
  SEND_FACEBOOK_MESSAGE,
  SEND_INSTAGRAM_MESSAGE,
  GET_LOYALTY_ACTIVITY,
} from "../constants/Activity";

export const getCommunicationActivity = (payload) => ({
  type: GET_COMMUNICATION_ACTIVITY,
  payload: payload,
});

export const setCommunicationActivity = (payload) => ({
  type: SET_COMMUNICATION_ACTIVITY,
  payload,
});

export const sendSMS = (payload) => ({
  type: SEND_SMS,
  payload,
});

export const addDataToActivity = (payload) => ({
  type: ADD_DATA_TO_ACTIVITY,
  payload,
});

export const updateActivity = (payload) => ({
  type: UPDATE_COMMUNICATION_ACTIVITY,
  payload,
});

export const sendEmail = (payload) => ({
  type: SEND_EMAIL,
  payload,
});

export const getChildrenEmail = (payload) => ({
  type: GET_CHILDREN_EMAIL,
  payload,
});

export const setChildrenEmail = (payload) => ({
  type: SET_CHILDREN_EMAIL,
  payload,
});

export const getHistoryActivity = (payload) => ({
  type: GET_HISTORY_ACTIVITY,
  payload: payload,
});

export const getLoyaltyActivity = (payload) => ({
  type: GET_LOYALTY_ACTIVITY,
  payload: payload,
})

export const setHistoryActivity = (payload) => ({
  type: SET_HISTORY_ACTIVITY,
  payload,
});

export const updateHistoryActivity = (payload) => ({
  type: UPDATE_HISTORY_ACTIVITY,
  payload,
});

export const getTokenPhoneCall = (payload) => ({
  type: GET_TOKEN_PHONE_CALL,
  payload,
});

export const setTokenPhoneCall = (payload) => ({
  type: SET_TOKEN_PHONE_CALL,
  payload,
});

export const callEnd = () => ({
  type: CALL_END,
});

export const pushNewStatusChange = (payload) => ({
  type: PUSH_NEW_STATUS_CHANGE,
  payload,
});

export const setNewActivity = (payload) => ({
  type: SET_NEW_ACTIVITY,
  payload,
});

export const pushNewAmountSpent = (payload) => ({
  type: PUSH_NEW_AMOUNT_SPENT,
  payload,
});

export const sendFacebookMessage = (payload) => ({
  type: SEND_FACEBOOK_MESSAGE,
  payload,
});

export const sendInstagramMessage = (payload) => ({
  type: SEND_INSTAGRAM_MESSAGE,
  payload,
});
