import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { setTransactions, setTableLoading } from "redux/actions/Reporting";
import { GET_TRANSACTIONS } from "redux/constants/Reporting";
import ReportingService from "services/ReportingService";

export function* getTransactionsSaga() {
  yield takeEvery(GET_TRANSACTIONS, function* ({ payload }) {
    try {
      const response = yield call(ReportingService.getTransactions, payload);
      yield put(setTransactions(response?.data));
      yield put(setTableLoading(false));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getTransactionsSaga)]);
}
