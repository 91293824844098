import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
  ContactsOutlined,
  FunnelPlotOutlined,
  CalendarOutlined,
  SettingOutlined,
  MailOutlined,
  CommentOutlined,
  UsergroupAddOutlined,
  DashboardOutlined,
  TrophyOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import translations from "./translations";
import AppLocale from "./../lang/index";
import BetaTag from "components/shared-components/BetaTag/BetaTag";

const chosenLang = AppLocale.en.locale;

const dashBoardNavTree = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: translations[13][chosenLang],
    breadcrumb: false,
    submenu: [],
    icon: DashboardOutlined,
  },
  {
    key: "contacts",
    path: `${APP_PREFIX_PATH}/contacts`,
    title: translations[0][chosenLang],
    breadcrumb: false,
    submenu: [],
    icon: ContactsOutlined,
  },
  {
    key: "conversations",
    path: `${APP_PREFIX_PATH}/conversations`,
    title: translations[8][chosenLang],
    breadcrumb: false,
    submenu: [],
    icon: CommentOutlined,
  },
  {
    key: "funnels",
    path: `${APP_PREFIX_PATH}/funnels`,
    title: translations[1][chosenLang],
    breadcrumb: false,
    submenu: [],
    icon: FunnelPlotOutlined,
  },
  {
    key: "marketing",
    title: translations[2][chosenLang],
    breadcrumb: false,
    icon: MailOutlined,
    submenu: [
      {
        key: "campaigns",
        path: `${APP_PREFIX_PATH}/campaigns`,
        title: translations[3][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "embed-code",
        path: `${APP_PREFIX_PATH}/embed-code`,
        title: translations[16][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "email-builder",
        path: `${APP_PREFIX_PATH}/email-builder`,
        title: translations[4][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sms-builder",
        path: `${APP_PREFIX_PATH}/sms-builder`,
        title: translations[5][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "landing-page",
        path: `${APP_PREFIX_PATH}/landing-page`,
        title: translations[9][chosenLang],
        breadcrumb: false,
        submenu: [],
        icon: BetaTag,
      },
      {
        key: "forms",
        path: `${APP_PREFIX_PATH}/forms`,
        title: translations[10][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "chat-widget",
        path: `${APP_PREFIX_PATH}/chat-widget`,
        title: translations[11][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "virtual-consult",
        path: `${APP_PREFIX_PATH}/virtual-consult`,
        title: translations[26][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "my-practice",
    path: `${APP_PREFIX_PATH}/my-practice`,
    title: translations[12][chosenLang],
    breadcrumb: false,
    submenu: [
      {
        key: "practice-info",
        path: `${APP_PREFIX_PATH}/practice-info`,
        title: translations[14][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "emploree-calendar",
        path: `${APP_PREFIX_PATH}/employee-calendar`,
        title: translations[27][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "practice-staff",
        path: `${APP_PREFIX_PATH}/practice-staff`,
        title: translations[15][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "documents",
        path: `${APP_PREFIX_PATH}/documents`,
        title: translations[20][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "rooms",
        path: `${APP_PREFIX_PATH}/rooms`,
        title: translations[25][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
    ],
    icon: UsergroupAddOutlined,
  },
  {
    key: "scheduler",
    path: `${APP_PREFIX_PATH}/scheduler`,
    title: translations[6][chosenLang],
    breadcrumb: false,
    submenu: [],
    icon: CalendarOutlined,
  },
  {
    key: "reporting",
    path: `${APP_PREFIX_PATH}/reporting`,
    title: translations[21][chosenLang],
    breadcrumb: false,
    submenu: [
      {
        key: "transactions",
        path: `${APP_PREFIX_PATH}/transactions`,
        title: translations[22][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "treatments",
        path: `${APP_PREFIX_PATH}/treatments`,
        title: translations[28][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "marketing",
        path: `${APP_PREFIX_PATH}/marketing`,
        title: translations[23][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "loyalty-stats",
        path: `${APP_PREFIX_PATH}/loyalty-stats`,
        title: translations[24][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
    ],
    icon: BarChartOutlined,
  },
  {
    key: "loyalty-program",
    title: translations[17][chosenLang],
    breadcrumb: false,
    submenu: [
      {
        key: "awards",
        path: `${APP_PREFIX_PATH}/awards`,
        title: translations[18][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "loyalty-users",
        path: `${APP_PREFIX_PATH}/loyalty-users`,
        title: translations[19][chosenLang],
        breadcrumb: false,
        submenu: [],
      },
    ],
    icon: TrophyOutlined,
  },
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings`,
    title: translations[7][chosenLang],
    breadcrumb: false,
    submenu: [],
    icon: SettingOutlined,
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
