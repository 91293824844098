import produce from "immer";
import {
  SET_AWARDS,
  SET_SINGLE_AWARD,
  SET_AWARD_USER_LIST,
  ADD_USER_TO_LATEST,
  SET_SINGLE_USER_AWARDS,
  SET_NEW_POINTS,
  SET_REDEEMED_AWARD,
  SET_ARCHIVED_AWARDS,
  SET_SINGLE_ARHIVED_AWARD,
  CHANGE_STATUS_ARHIVED_AWARD,
  SET_AVAILABLE_AWARDS,
} from "redux/constants/Awards";
import { changeUsersAwards } from "helpers/changeUserAwardsState";

export const initialState = {
  awardsList: null,
  currentAward: null,
  userList: null,
  singleUserAwards: null,
  archivedAwards: null,
  singleArchivedAward: null,
  availableAwards: null,
};

const awardsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_AWARDS:
        draft.awardsList = payload;
        break;
      case SET_SINGLE_AWARD:
        draft.currentAward = payload;
        break;
      case SET_AWARD_USER_LIST:
        draft.userList = payload;
        break;
      case ADD_USER_TO_LATEST:
        const tempAward = draft?.currentAward;
        if (tempAward?.users) {
          tempAward.users = payload?.users;
          draft.currentAward = tempAward;
        }
        break;
      case SET_SINGLE_USER_AWARDS:
        draft.singleUserAwards = payload;
        break;
      case SET_NEW_POINTS:
        const tempUserAwards = draft?.singleUserAwards;
        changeUsersAwards(tempUserAwards, payload);
        draft.singleUserAwards = tempUserAwards;
        break;
      case SET_REDEEMED_AWARD:
        const { data, id } = payload;
        const userAwards = draft?.singleUserAwards;
        const pointsIndex = data?.corresponding_points.findIndex((el) => el.id == id);
        let award = data?.corresponding_points[pointsIndex];
        changeUsersAwards(userAwards, award, pointsIndex);
        draft.singleUserAwards = userAwards;
        break;
      case SET_ARCHIVED_AWARDS:
        draft.archivedAwards = payload;
        break;
      case SET_SINGLE_ARHIVED_AWARD:
        draft.singleArchivedAward = payload;
        break;
      case CHANGE_STATUS_ARHIVED_AWARD:
        draft.singleArchivedAward.status = payload;
        break;
      case SET_AVAILABLE_AWARDS:
        draft.availableAwards = payload;
        break;
      default:
        return state;
    }
  });

export default awardsReducer;
