export const GET_CAMPAIGNS = "GET_CAMPAIGN";
export const SET_CAMPAIGNS = "SET_CAMPAIGN";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const GET_FOLDERS = "GET_FOLDERS";
export const SET_FOLDERS = "SET_FOLDERS";
export const CREATE_FOLDER = "CREATE_FOLDER";
export const ADD_FOLDER = "ADD_FOLDER";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const EDIT_FOLDER = "EDIT_FOLDER";
export const CHANGE_FOLDER = "CHANGE_FOLDER";
export const HANDLE_DELETE_FOLDER = "HANDLE_DELETE_FOLDER";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const DELETE_MULTIPLE_CAMPAIGN = "DELETE_MULTIPLE_CAMPAIGN";
export const HANDLE_DELETE_CAMPAIGN = "HANDLE_DELETE_CAMPAIGN";
export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";
export const CHANGE_CAMPAIGN = "CHANGE_CAMPAIGN";
export const GET_SINGLE_CAMPAIGN = "GET_SINGLE_CAMPAIGN";
export const SET_SINGLE_CAMPAIGN = "SET_SINGLE_CAMPAIGN";
export const GET_DISCOUNT = "GET_DISCOUNT";
export const SET_DISCOUNT = "SET_DISCOUNT";
export const SET_LOADING = "SET_LOADING";
export const CREATE_DISCOUNT = "CREATE_DISCOUNT";
export const GET_AVAILABLE_CAMPAIGNS = "GET_AVAILABLE_CAMPAIGNS";
export const SET_AVAILABLE_CAMPAIGNS = "SET_AVAILABLE_CAMPAIGNS";
export const POST_NEXT_ACTION = "POST_NEXT_ACTION";
export const GET_NEXT_ACTION_BY_CAMPAIGN = "GET_NEXT_ACTION_BY_CAMPAIGN";
export const SET_NEXT_ACTION_BY_CAMPAIGN = "SET_NEXT_ACTION_BY_CAMPAIGN";
export const DELETE_NEXT_ACTION = "DELETE_NEXT_ACTION";
export const UPDATE_NEXT_ACTION = "UPDATE_NEXT_ACTION";
export const REMOVE_NEXT_ACTION = "REMOVE_NEXT_ACTION";
export const ADD_NEXT_ACTION = "ADD_NEXT_ACTION";
export const EDIT_NEXT_ACTION = "EDIT_NEXT_ACTION";
export const PUT_NEXT_ACTION = "PUT_NEXT_ACTION";
export const GET_PUBLISHED_CAMPAIGNS = "GET_PUBLISHED_CAMPAIGNS";
export const SET_PUBLISHED_CAMPAIGNS = "SET_PUBLISHED_CAMPAIGNS";
export const POST_REACTIVATION_NEXT_ACTION = "POST_REACTIVATION_NEXT_ACTION";
export const ADD_REACTIVATION_NEXT_ACTION = "ADD_REACTIVATION_NEXT_ACTION";
export const GET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN = "GET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN";
export const SET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN = "SET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN";
export const UPDATE_REACTIVATION_NEXT_ACTION = "UPDATE_REACTIVATION_NEXT_ACTION";
export const PUT_REACTIVATION_NEXT_ACTION = "PUT_REACTIVATION_NEXT_ACTION";
export const DELETE_REACTIVATION_NEXT_ACTION = "DELETE_REACTIVATION_NEXT_ACTION";
export const REMOVE_REACTIVATION_NEXT_ACTION = "REMOVE_REACTIVATION_NEXT_ACTION";
export const GET_REACTIVATION_CAMPAIGNS = "GET_REACTIVATION_CAMPAIGNS";
export const SET_REACTIVATION_CAMPAIGNS = "SET_REACTIVATION_CAMPAIGNS";
export const EDIT_CAMPAIGN_TIME_CONFIG = "EDIT_CAMPAIGN_TIME_CONFIG";
export const CREATE_CAMPAIGN_TIME_CONFIG = "CREATE_CAMPAIGN_TIME_CONFIG";
export const GET_CONTACTS_ON_CAMPAIGN = "GET_CONTACTS_ON_CAMPAIGN";
export const SET_CONTACTS_ON_CAMPAIGN = "SET_CONTACTS_ON_CAMPAIGN";
export const DELETE_CONTACT_FROM_CAMPAIGN = "DELETE_CONTACT_FROM_CAMPAIGN";
export const PATCH_CONTACT_STATUS_CAMPAIGN = "PATCH_CONTACT_STATUS_CAMPAIGN";
export const UPDATE_CONTACT_STATUS_CAMPAIGN = "UPDATE_CONTACT_STATUS_CAMPAIGN";
export const SET_TIME_CONFIG_TO_CAMPAIGN = "SET_TIME_CONFIG_TO_CAMPAIGN"