import ApiService from "./ApiService";
import { stringify } from "querystring";
import { format } from "util";

const ENDPOINTS = {
  PAYMENT_METHOD: "payment/",
  PAYMENT_DETAILS: "payment/details/",
  SUBSCRIBE: "subscription/",
  COUNTRIES: "payment/countries/",
  UPDATTE_BILLING_ADDRESS: "payment/change-billing-address/",
  CHANGE_CREDIT_CARD: "payment/change-credit-card/",
  TRANSACTIONS: "transaction/",
  GET_CUSTOMER_CARE_PRODUCTS: "all/products/service/?page=%s&search=%s",
};

class PaymentService extends ApiService {
  addPaymentMethod = (data) => {
    return this.apiClient.post(ENDPOINTS.PAYMENT_METHOD, data);
  };

  getPaymentDetails = () => {
    return this.apiClient.get(ENDPOINTS.PAYMENT_DETAILS);
  };

  subscribe = (data) => {
    return this.apiClient.post(ENDPOINTS.SUBSCRIBE, data);
  };

  getCountries = () => {
    return this.apiClient.get(ENDPOINTS.COUNTRIES);
  };

  updateBillingAddress = (data) => {
    return this.apiClient.post(ENDPOINTS.UPDATTE_BILLING_ADDRESS, data);
  };

  changeCreditCard = (data) => {
    return this.apiClient.post(ENDPOINTS.CHANGE_CREDIT_CARD, data);
  };

  getTransactions = (page, perPage) => {
    const queryStringParams = {
      page,
      per_page: perPage,
    };
    return this.apiClient.get(
      `${ENDPOINTS.TRANSACTIONS}?${stringify(queryStringParams)}`
    );
  };

  getCustomerCareProducts = (page, search)=>{
    return this.apiClient.get(format(ENDPOINTS.GET_CUSTOMER_CARE_PRODUCTS, page, search));
  }
}

export default new PaymentService();
