export const createAmountSpentActivity = (payload) => {
  const { id, contact, amount } = payload;
  const newActivity = {
    contact: {
      id: id,
      name: contact.data.name,
      created_at: contact.data.created_at,
    },
    content_object: {
      contact: {
        id: id,
        name: contact.data.name,
        created_at: contact.data.created_at,
      },
      date: new Date(),
      amount: amount.toFixed(2),
    },
    content_type: {
      id: 78,
      app_label: "analytics",
      model: "transactions",
    },
    date: new Date(),
  };
  return newActivity;
};
