import {
  GET_STRIPE_ACCOUNT_ID,
  SET_STRIPE_ACCOUNT_ID,
  GET_STRIPE_CONNECT_URL,
  SET_STRIPE_CONNECT_URL,
  CREATE_INVOICE_URL,
  SET_INVOICE_URL,
  GET_TAXES,
  SET_TAXES,
  CREATE_TAX,
  DELETE_TAX,
  SHOW_LOADER,
  GET_PRODUCTS,
  SET_PRODUCTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  CREATE_PRICE,
  EDIT_PRICE,
  DELETE_PRICE,
  SET_PRICE,
  SET_EDITED_PRICE,
  SET_PAYMENT_PROCESSOR,
  CHANGE_TYPE,
  LIST_PRICES,
} from "../constants/PaymentIntegrations";

export const getStripeAccountId = (payload) => ({
  type: GET_STRIPE_ACCOUNT_ID,
  payload,
});

export const setStripeAccountId = (payload) => ({
  type: SET_STRIPE_ACCOUNT_ID,
  payload,
});

export const getStripeConnectURL = () => ({
  type: GET_STRIPE_CONNECT_URL,
});

export const setStripeConnectURL = (payload) => ({
  type: SET_STRIPE_CONNECT_URL,
  payload,
});

export const getTaxes = (payload) => ({
  type: GET_TAXES,
  payload,
});

export const setTaxes = (payload) => ({
  type: SET_TAXES,
  payload,
});

export const createTax = (payload) => ({
  type: CREATE_TAX,
  payload,
});

export const deleteTax = (payload) => ({
  type: DELETE_TAX,
  payload,
});

export const createInvoiceURL = (payload) => ({
  type: CREATE_INVOICE_URL,
  payload,
});

export const setInvoiceURL = (payload) => ({
  type: SET_INVOICE_URL,
  payload,
});

export const setLoader = (payload) => ({
  type: SHOW_LOADER,
  payload,
});

export const getProducts = (payload) => ({
  type: GET_PRODUCTS,
  payload,
});

export const setProducts = (payload) => ({
  type: SET_PRODUCTS,
  payload,
});

export const createProduct = (
  payload,
  successMessage,
  item,
  page,
  setPage
) => ({
  type: CREATE_PRODUCT,
  payload,
  successMessage,
  setPage,
});

export const editProduct = (payload, successMessage, productId, page) => ({
  type: UPDATE_PRODUCT,
  payload,
  productId,
  successMessage,
  page,
});

export const deleteProduct = (payload) => ({
  type: DELETE_PRODUCT,
  payload,
});

export const listPrices = (productData) => ({
  type: LIST_PRICES,
  productData,
});

export const createPrice = (payload, productData, successMessage) => ({
  type: CREATE_PRICE,
  payload,
  productData,
  successMessage,
});

export const setPrice = (payload) => ({
  type: SET_PRICE,
  payload,
});

export const setEditedPrice = (payload) => ({
  type: SET_EDITED_PRICE,
  payload,
});

export const editPrice = (payload, productData, successMessage, priceId) => ({
  type: EDIT_PRICE,
  payload,
  productData,
  successMessage,
  priceId,
});

export const deletePrice = (productData, priceId, successMessage) => ({
  type: DELETE_PRICE,
  productData,
  priceId,
  successMessage,
});

export const setPaymentProcessor = (payload) => ({
  type: SET_PAYMENT_PROCESSOR,
  payload,
});

export const changeProductType = (payload, successMessage) => ({
  type: CHANGE_TYPE,
  payload,
  successMessage,
});
