import {
  CheckOutlined,
  EditOutlined,
  GiftOutlined,
  HourglassOutlined,
} from "@ant-design/icons";
import translations from "views/app-views/SingleContactPage/ActivityHistory/translations";

export const MIN_AMOUNT_SPENT = 1;
export const MAX_AMOUNT_SPENT = 100000;
export const MIN_POINTS = 1;
export const MAX_POINTS = 100000;
export const MIN_GIFT_AMOUNT = 5;
export const MAX_GIFT_AMOUNT = 1000;
export const MAX_LENGTH_DESCRIPTION = 255;
export const MAX_LENGTH_PROCEDURE = 100;
export const MAX_LENGTH_TITLE = 80;
export const PRGORESS_100 = 100;

export const COMPLETED = "Completed";
export const UNCOMPLETED = "Uncompleted";
export const REDEMEED = "Redeemed";
export const RESERVED = "Reserved";
export const EDITED = "Edited";
export const EXPIRED = "Expired";
export const INCOMPLETED = "Incompleted";
export const TRANSACTION = "Transaction";
export const AWARD_COMPLETED = "Award completed";
export const AWARD_REDEEMED = "Award redeemed";
export const AWARD_EDITED = "Award edited";
export const AWARD_EXIPIRED = "Award expired";
export const DEFAULT_AWARD_COMPLETED = "Default Award Completed";
export const DEFAULT_AWARD_REDEEMED = "Default Award Redeemed";

const LOYALTY_COMPLTED_COLOR = "#CE0538";
const LOYALTY_REDEEMED_COLOR = "#4287F5";
const LOYALTY_EDITED_COLOR = "#D1D100";
const LOYALTY_EXPIRED_COLOR = "#BF40BF";

export const AWARD_COLOR = {
  [AWARD_COMPLETED]: LOYALTY_COMPLTED_COLOR,
  [AWARD_REDEEMED]: LOYALTY_REDEEMED_COLOR,
  [AWARD_EDITED]: LOYALTY_EDITED_COLOR,
  [AWARD_EXIPIRED]: LOYALTY_EXPIRED_COLOR,
  [DEFAULT_AWARD_COMPLETED]: LOYALTY_COMPLTED_COLOR,
  [DEFAULT_AWARD_REDEEMED]: LOYALTY_REDEEMED_COLOR,
};

export const AWARD_STATUS = (formatMessage) => {
  return {
    [AWARD_COMPLETED]: formatMessage(translations.awardCompleted),
    [AWARD_REDEEMED]: formatMessage(translations.awardRedeemed),
    [AWARD_EDITED]: formatMessage(translations.awardEdited),
    [AWARD_EXIPIRED]: formatMessage(translations.awardExpired),
    [DEFAULT_AWARD_COMPLETED]: formatMessage(translations.defaultAwardCompleted),
    [DEFAULT_AWARD_REDEEMED]: formatMessage(translations.defaultAwardRedeemed),
  };
};

export const AWARD_ICON = {
  [AWARD_COMPLETED]: <CheckOutlined className="bg-white" />,
  [AWARD_REDEEMED]: <GiftOutlined className="bg-white" />,
  [AWARD_EDITED]: <EditOutlined className="bg-white" />,
  [AWARD_EXIPIRED]: <HourglassOutlined className="bg-white" />,
  [DEFAULT_AWARD_COMPLETED]: <CheckOutlined className="bg-white" />,
  [DEFAULT_AWARD_REDEEMED]: <GiftOutlined className="bg-white" />,
};

export const INFO_MESSAGE = {
  [AWARD_COMPLETED]: COMPLETED,
  [AWARD_REDEEMED]: REDEMEED,
  [AWARD_EDITED]: EDITED,
  [AWARD_EXIPIRED]: EXPIRED,
  [DEFAULT_AWARD_COMPLETED]: COMPLETED,
  [DEFAULT_AWARD_REDEEMED]: REDEMEED,
};

export const AWARD_STATUS_COLORS = {
  [COMPLETED]: "blue",
  [UNCOMPLETED]: "orange",
  [REDEMEED]: "green",
};

export const COLOR_1 = "#3e82f7"; // blue
export const COLOR_2 = "#04d182"; // cyan
export const COLOR_3 = "#CE0538"; // crismon
export const COLOR_4 = "#F58B00"; // orange
export const COLOR_5 = "#F5BB00"; // pure yellow
export const COLOR_6 = "#92E000"; // pure green
export const COLOR_7 = "#2AA10F"; // dark lime green

export const COLORS = [
  COLOR_1,
  COLOR_2,
  COLOR_3,
  COLOR_4,
  COLOR_5,
  COLOR_6,
  COLOR_7,
];

export const COMPLETED_TAG_COLOR = "#62C370";
export const UNCOMPLETED_TAG_COLOR = "#F5BB00";
export const REDEEMED_TAG_COLOR = "#CE0538";

export const TAG_STYLE = {
  [COMPLETED]: COMPLETED_TAG_COLOR,
  [UNCOMPLETED]: UNCOMPLETED_TAG_COLOR,
  [REDEMEED]: REDEEMED_TAG_COLOR,
};

export const TAG_STATUS = {
  [COMPLETED]: COMPLETED,
  [UNCOMPLETED]: INCOMPLETED,
  [REDEMEED]: REDEMEED,
};

export const AWARD_USERS_LIST_PAGINATION = 10;
export const AWARD_SIZE_OFFSET = 16;
export const POINTS_TRESHOLD = 10;
export const MAX_POINTS_TRESHOLD = 100;
export const MIN_POINTS_TRESHOLD = 1;
export const MAX_AWARD_CODE_LENGTH = 21;

export const AWARDS_PAGE_SIZE = 5;

export const MODE_TYPE_STATUS = {
  ACTIVE: "active",
  ARCHIVED: "archived",
};

export const TABS_STATUS = {
  ACTIVE: "active",
  ARCHIVED: "archived",
};

export const TAB_TO_MODE_STATUS = {
  [TABS_STATUS.ACTIVE]: MODE_TYPE_STATUS.ACTIVE,
  [TABS_STATUS.ARCHIVED]: MODE_TYPE_STATUS.ARCHIVED,
};

export const FIRST_PAGE = 1;

export const ARCHIVED = "archived";
export const ACTIVE = "active";

export const TABS_KEY = {
  OVERVIEW: "overview",
  ACTIVE: "active",
  COMPLETED: "completed",
  HISTORY: "history",
  RESERVED: "reserved",
};

export const PROGRESS_WIDTH = 130;
export const PROGRESS_STROKE_WIDTH = 8;

export const ACTIVITY_TYPE = {
  TRANSACTION: "transactions",
  AWARD: "award",
  UPDATED_AWARD: "updatedaward",
};

export const AWARD_CARD_STATUS = (formatMessage) => {
  return {
    [COMPLETED]: formatMessage(translations.completedStatus),
    [UNCOMPLETED]: formatMessage(translations.uncompletedStatus),
    [REDEMEED]: formatMessage(translations.redeemedStatus),
  };
};

export const MAX_YEAR = "9999";
