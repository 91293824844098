import reducers from "redux/reducers";
import { createSelector } from "reselect";

const selectFormBuilderDomain = (state) => state.formBuilder || reducers;

const makeSelectAllForms = createSelector(
  selectFormBuilderDomain,
  (substate) => substate
);

const makeSelectAllFormsNoPagination = createSelector(
  selectFormBuilderDomain,
  (substate) => substate.allForms
);

const makeSelectSelectedForm = createSelector(
  selectFormBuilderDomain,
  (substate) => substate.selectedForm
);

export {
  makeSelectAllForms,
  makeSelectSelectedForm,
  makeSelectAllFormsNoPagination,
};
