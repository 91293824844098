// @ts-nocheck
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  setOrganizationData,
  setPracticeInfo,
  updateOrganizationError,
} from "redux/actions/Organization";
import OrganizationService from "services/OrganizationService";
import {
  EDIT_FORWARDING_PHONE_NUMBER,
  GET_PRACTICE_INFO,
  POST_NEW_ORGANIZATION,
  POST_PRACTICE_INFO,
  UPDATE_ORGANIZATION,
  UPDATE_PRACTICE_INFO,
} from "redux/constants/Organization";
import { getCurrentUserData, updateUserInfo } from "redux/actions/Auth";
import { message } from "antd";
import { removeLocalStorageItem } from "utils/localStorage";
import { push, go } from "connected-react-router";

export function* organizationUpdate() {
  yield takeEvery(
    UPDATE_ORGANIZATION,
    function* ({ payload, id, messageSuccess }) {
      try {
        yield call(OrganizationService.updateOrganization, {
          payload: payload,
          id: id,
        });
        yield put(getCurrentUserData());
        yield put(updateOrganizationError([]));
        message.success(messageSuccess, 3);
      } catch (err) {
        yield put(updateOrganizationError(err));
        if (err?.response?.data?.address){
          let err_obj = err?.response?.data?.address
          let err_val = err_obj[Object.keys(err_obj)[0]]
          message.error(err_val.non_field_errors[0], 5);
        }
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors[0], 3);
        }
      }
    }
  );
}

export function* postOrganization() {
  yield takeEvery(
    POST_NEW_ORGANIZATION,
    function* ({ payload, messageSuccess, pushToRoute }) {
      try {
        const response = yield call(
          OrganizationService.postNewOrganization,
          payload
        );
        yield put(updateUserInfo(response));
        yield put(updateOrganizationError([]));
        removeLocalStorageItem("organizationNotExists");
        message.success(messageSuccess);
        yield put(push(pushToRoute));
        yield put(go())
      } catch (err) {
        yield put(updateOrganizationError(err));
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors[0], 3);
        }
      }
    }
  );
}

export function* postPracticeInfo() {
  yield takeEvery(POST_PRACTICE_INFO, function* ({ payload, messageSuccess }) {
    try {
      const response = yield call(
        OrganizationService.postPracticeInfo,
        payload
      );
      yield put(setPracticeInfo(response.data));
      message.success(messageSuccess);
    } catch (err) {
      console.log("error", err);
      message.error(err.response.data?.google_review_link?.[0], 3);
    }
  });
}

export function* getPracticeInfo() {
  yield takeEvery(GET_PRACTICE_INFO, function* ({ setIsLoading }) {
    try {
      const response = yield call(OrganizationService.getPracticeInfo);
      yield put(setPracticeInfo(response.data[0]));
      if (setIsLoading) {
        setIsLoading(false);
      }
    } catch (err) {
      console.log("error", err);
    }
  });
}

export function* updatePracticeInfo() {
  yield takeEvery(
    UPDATE_PRACTICE_INFO,
    function* ({ payload, messageSuccess, id, form }) {
      try {
        const response = yield call(
          OrganizationService.updatePracticeInfo,
          payload,
          id
        );
        message.success(messageSuccess);
        yield put(setPracticeInfo(response.data));
        form.setFieldsValue({ practice_caracteristics_list: null });
      } catch (err) {
        console.log("error", err);
        const keyError = Object.keys(err.response.data)[0];
        if (keyError) {
          message.error(err.response.data[keyError], 3);
        };
      }
    }
  );
}

export function* editForwardingPhoneNumberSaga() {
  yield takeEvery(EDIT_FORWARDING_PHONE_NUMBER, function* ({ payload, closeModal }) {
    try {
      const response = yield call(OrganizationService.editForwardingPhoneNumber, payload);
      yield put(setOrganizationData(response.data));
      closeModal();
    } catch (err) {
      console.log("error", err);
      message.error(err.response.data?.forwarding_number?.[0], 3);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(organizationUpdate),
    fork(postOrganization),
    fork(postPracticeInfo),
    fork(getPracticeInfo),
    fork(updatePracticeInfo),
    fork(editForwardingPhoneNumberSaga)
  ]);
}
