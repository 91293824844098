import { defineMessages } from "react-intl";

export const scope = "global";

export default defineMessages({
  email: {
    id: `${scope}.validations.email`,
    defaultMessage: "{label} must be a valid email",
  },
  required: {
    id: `${scope}.validations.required`,
    defaultMessage: "{label} is required",
  },
  oneOf: {
    id: `${scope}.validations.one_of`,
    defaultMessage: "Passwords must match",
  },
  matches: {
    id: `${scope}.validations.matches`,
    defaultMessage:
      "Must contain at least 8 characters, one uppercase, one lowercase, one number and a special character",
  },
  matchesPhone: {
    id: `${scope}.validations.matches.phone`,
    defaultMessage:
      "Phone number is not valid.Enter a valid phone number (e.g. +12125552368)",
  },
  signOut: {
    id: `${scope}.sign_out.text`,
    defaultMessage: "Sign Out",
  },
  maxLenght: {
    id: `${scope}.validations.max`,
    defaultMessage: "Ensure this field has no more than 200 characters.",
  },
  maxLenghtFirstName: {
    id: `${scope}.validations.first.name`,
    defaultMessage: "Ensure this field has no more than 30 characters.",
  },
  maxLenghtLastName: {
    id: `${scope}.validations.last.name`,
    defaultMessage: "Ensure this field has no more than 150 characters.",
  },
  maxLenghtNote: {
    id: `${scope}.validations.max`,
    defaultMessage: "Ensure this field has no more than 5000 characters.",
  },
  matchesWebsite: {
    id: `${scope}.validations.matches.website`,
    defaultMessage:
      "Website URL is not in valid format.Enter a valid format (e.g. example.com)",
  },
  editProfile: {
    id: `${scope}.label.edit_profile`,
    defaultMessage: "Edit profile",
  },
  changePassword: {
    id: `${scope}.label.change_password`,
    defaultMessage: "Change password",
  },
  maxLenghtAddressError: {
    id: `${scope}.address.error`,
    defaultMessage: "Ensure that this field has no more than 250 characters.",
  },
  matchesZipCode: {
    id: `${scope}.validations.matches.zip_code`,
    defaultMessage: "Enter a valid valid Zip Code.",
  },
  matchesAddress: {
    id: `${scope}.validations.matches.address`,
    defaultMessage: "Enter a valid address.",
  },
  matchesCity: {
    id: `${scope}.validations.matches.city`,
    defaultMessage: "Enter a valid City name.",
  },
  regionRequired: {
    id: `${scope}.validations.required.region`,
    defaultMessage: "Please select State.",
  },
  notificationIncomingCallMessage: {
    id: `${scope}.notification.incoming.call`,
    defaultMessage: "Incoming call",
  },
  notificationIncomingCallDescription: {
    id: `${scope}.notification.incoming.call.description`,
    defaultMessage: "You have incoming call from {callerInfo}",
  },
  linkBtn: {
    id: `${scope}.link.btn`,
    defaultMessage: "LINK",
  },
  maxWebSiteLenght: {
    id: `${scope}.validations.website.max`,
    defaultMessage: "Ensure this field has no more than 242 characters.",
  },
  maxAppointmentTitleLength: {
    id: `${scope}.validations.appointment.title`,
    defaultMessage: "Make sure title isn't longer than 255 characters.",
  },
  emptyTitleErrorMessage: {
    id: `${scope}.validations.appointment.empty`,
    defaultMessage: "Cannot be only empty characters.",
  },
  minNumberOfTeamMembersLength: {
    id: `${scope}.validations.teamMember.min`,
    defaultMessage: "Please select at least one team member.",
  },
  maxNumberOfTeamMembersLength: {
    id: `${scope}.validations.teamMember.max`,
    defaultMessage: "You can select maximum 5 team members.",
  },
  emailNameRegex: {
    id: `${scope}.emailName.regex`,
    defaultMessage:
      "Can contain only the following characters: a-z A-Z 0-9 # $ % & ' * + /= ? ^ ` { | } ~ -",
  },
  emailNameLength: {
    id: `${scope}.emailName.length`,
    defaultMessage: "The length of email name can be 64 characters long.",
  },
  maxLengthZipCode: {
    id: `${scope}.maxLengthZipCode.error`,
    defaultMessage: "Max number of characters is 12.",
  },
  minLengthZipCode: {
    id: `${scope}.minLengthZipCode.error`,
    defaultMessage: "Min number of characters is 5.",
  },
});
