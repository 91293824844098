import {
  GET_CONTACT_NOTES_BY_PAGE,
  SET_CONTACT_NOTES_BY_PAGE,
  POST_NEW_NOTE,
  ADD_NEW_NOTE,
  DELETE_NOTE,
  REMOVE_NOTE,
  UPDATE_NOTE,
  SET_UPDATED_NOTE,
} from "redux/constants/Notes";

export const getContactNotesByPage = (payload) => ({
  type: GET_CONTACT_NOTES_BY_PAGE,
  payload,
});

export const setContactNotes = (payload) => ({
  type: SET_CONTACT_NOTES_BY_PAGE,
  payload,
});

export const postNewNote = (data, callBack) => ({
  type: POST_NEW_NOTE,
  data,
  callBack,
});

export const addNewNote = (payload) => ({
  type: ADD_NEW_NOTE,
  payload,
});

export const deleteNote = (payload) => ({
  type: DELETE_NOTE,
  payload,
});

export const removeNote = (payload) => ({
  type: REMOVE_NOTE,
  payload,
});

export const updateNote = (payload, callBack) => ({
  type: UPDATE_NOTE,
  payload,
  callBack,
});

export const setUpdatedNote = (payload) => ({
  type: SET_UPDATED_NOTE,
  payload,
});
