export const EVENT_TYPE = {
  EMAIL: "email",
  SMS: "sms",
};

export const TIME_TYPE = {
  MINUTES: "minutes",
  HOURS: "hours",
  DAYS: "days",
};

export const TIME_STATUS = {
  AFTER: "after",
  IMMEDIATELY: "immediately",
};

export const REGEXP = /^[0-9]{1,6}$/;
export const DAYS = {
  MONDAY: { lable: "Mon", value: 1 },
  TUESDAY: { lable: "Tue", value: 2 },
  WEDNESDAY: { lable: "Wed", value: 3 },
  THURSDAY: { lable: "Thu", value: 4 },
  FRIDAY: { lable: "Fri", value: 5 },
  SATURDAY: { lable: "Sat", value: 6 },
  SUNDAY: { lable: "Sun", value: 7 },
};

export const DAYS_VALUES = [
  DAYS.MONDAY,
  DAYS.TUESDAY,
  DAYS.WEDNESDAY,
  DAYS.THURSDAY,
  DAYS.FRIDAY,
  DAYS.SATURDAY,
  DAYS.SUNDAY,
];

export const CUSTOM_TIME_START = {
  TIME_07_00: "07:00",
  TIME_07_30: "07:30",
  TIME_08_00: "08:00",
  TIME_08_30: "08:30",
  TIME_09_00: "09:00",
};

export const CUSTOM_TIME_END = {
  TIME_16_00: { value: "16:00", lable: "04:00 pm" },
  TIME_16_30: { value: "16:30", lable: "04:30 pm" },
  TIME_17_00: { value: "17:00", lable: "05:00 pm" },
  TIME_17_30: { value: "17:30", lable: "05:30 pm" },
  TIME_18_00: { value: "18:00", lable: "06:00 pm" },
};

export const DEFAULT_COLORS_DAYS = {
  1: "",
  2: "",
  3: "",
  4: "",
  5: "",
  6: "",
  7: "",
};

export const DAYS_OF_WEEK = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun",
};

export const DAY_SELECTED_COLOR = "white";
export const DAY_SELECTED_COLOR_TEXT = "#CE0538";
export const DAY_SELECTED_BORDER = "#CE0538";

export const CAMPAIGN_CONFIG_DAYS = [
  { label: "Mon", value: 1, selected: true },
  { label: "Tue", value: 2, selected: true },
  { label: "Wed", value: 3, selected: true },
  { label: "Thu", value: 4, selected: true },
  { label: "Fri", value: 5, selected: true },
  { label: "Sat", value: 6, selected: false },
  { label: "Sun", value: 7, selected: false },
];

export const MAX_EVENT_TIME_HOURS = 23;
export const ALLOWED_MINUTES = [0, 15, 30, 45];
