// @ts-nocheck
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  DELETE_EVENT,
  GET_EVENTS,
  POST_EVENT,
  REORDER_EVENTS,
  CHANGE_EVENT_DATA,
} from "../constants/Event";
import {
  setEvents,
  showMessage,
  pushNewEventToList,
  removeEvent,
  updateEventList,
} from "../actions/Event";
import EventService from "services/EventService";
import { message } from "antd";

export function* getEvents() {
  yield takeEvery(GET_EVENTS, function* ({ payload }) {
    try {
      const events = yield call(EventService.getEvents, payload);
      yield put(setEvents({ data: events.data, id: payload }));
    } catch (err) {
      yield put(showMessage(err));
    }
  });
}

export function* eventsReorder() {
  yield takeEvery(REORDER_EVENTS, function* ({ payload, id }) {
    try {
      const events = yield call(EventService.reorderEvents, payload, id);
      yield put(setEvents({ data: events.data, id: id }));
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors, 3);
      }
    }
  });
}

export function* postNewEvent() {
  yield takeEvery(
    POST_EVENT,
    function* ({ payload, id, resetModal, messageSuccess }) {
      try {
        const event = yield call(EventService.postNewEvent, id, payload);
        yield put(pushNewEventToList({ data: event.data, id: id }));
        resetModal();
        message.success(messageSuccess, 3);
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors, 3);
        }
        console.log(err);
      }
    }
  );
}

export function* deleteEvent() {
  yield takeEvery(DELETE_EVENT, function* ({ payload, id }) {
    try {
      yield call(EventService.deleteEvent, id, payload);
      yield put(removeEvent({ id: id, deleted_id: payload }));
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors, 3);
      }
    }
  });
}

export function* changeEventData() {
  yield takeEvery(
    CHANGE_EVENT_DATA,
    function* ({ payload, id, data, resetModal, messageSuccess }) {
      try {
        const event = yield call(
          EventService.changeEventTime,
          id,
          payload,
          data
        );
        yield put(updateEventList({ id, event }));
        resetModal && resetModal();
        message.success(messageSuccess, 3);
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors, 3);
        }
        console.log(err);
      }
    }
  );
}
export default function* rootSaga() {
  yield all([fork(getEvents)]);
  yield all([fork(eventsReorder)]);
  yield all([fork(postNewEvent)]);
  yield all([fork(deleteEvent)]);
  yield all([fork(changeEventData)]);
}
