import produce from "immer";
import {
  SET_DASHBOARD_DATA,
  SET_CONTACT_CONVERSIONS,
  SET_DASHBOARD_FILTER,
} from "../constants/Dashboard";

export const initialState = {
  dashboardStats: null,
  contactConversions: null,
  filterInfo: {
    dataIsFiltered: false,
    filterRange: null,
  },
};

const dashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_DASHBOARD_DATA:
        draft.dashboardStats = payload;
        break;
      case SET_CONTACT_CONVERSIONS:
        draft.contactConversions = payload;
        break;
      case SET_DASHBOARD_FILTER:
        const firstDateSet = !!payload?.[0];
        const secondDateSet = !!payload?.[1];

        const dataIsFiltered = firstDateSet && secondDateSet;
        const filterRange = firstDateSet && secondDateSet ? payload : null;

        draft.filterInfo = { dataIsFiltered, filterRange };
        break;
      default:
        return state;
    }
  });

export default dashboardReducer;
