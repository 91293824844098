import React from "react";
import { useSelector } from "react-redux";
import { APP_NAME } from "configs/AppConfig";
import Flex from "components/shared-components/Flex";

const ErrorTwo = () => {
  const theme = useSelector((state) => state.theme.currentTheme);
  return (
    <div className={`h-100 ${theme === "light" ? "bg-white" : ""}`}>
      <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
        <div>
          <img
            className="img-fluid"
            src={`/img/${theme === "light" ? "logo.png" : "logo-white.png"}`}
            alt=""
          />
        </div>
        <div className="container">
          <div className="text-center mb-5">
            <img className="img-fluid" src="/img/others/img-21.png" alt="" />
            <h1 className="font-weight-bold mb-4">
              Sorry, you are not authorized to access this page
            </h1>
          </div>
        </div>
        <Flex mobileFlex={false} justifyContent="center">
          <span>
            Copyright &copy; {`${new Date().getFullYear()}`}{" "}
            <span className="font-weight-semibold">{`${APP_NAME}`}</span>
          </span>
        </Flex>
      </div>
    </div>
  );
};

export default ErrorTwo;
