import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { UNSUBSCRIBE_PATH } from "configs/AppConfig";
import { PublicRoute } from "shared/PublicRoute";

export const UnsubscribeView = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <PublicRoute
          path={UNSUBSCRIBE_PATH}
          component={lazy(() => import(`./UnsubscribeContact`))}
        />
      </Switch>
    </Suspense>
  );
};

export default UnsubscribeView;
