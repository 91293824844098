// @ts-nocheck
import { all, takeEvery, put, fork, call, delay } from "redux-saga/effects";
import ConversationService from "../../services/ConversationService";
import {
  GET_CONVERSATION,
  GET_CONVERSATION_BY_CONTACT,
  MARK_MESSAGE_AS_READ,
} from "./../constants/Conversation";
import {
  setConversationForAllContacts,
  setConversationByContact,
  setMessageAsRead,
} from "./../actions/Conversation";

export function* getConversations() {
  yield takeEvery(GET_CONVERSATION, function* ({ payload }) {
    try {
      const response = yield call(
        ConversationService.getConversationAllContacts,
        payload
      );
      let data = {
        data: response.data,
        currentPage: payload.page,
      };
      yield put(setConversationForAllContacts(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getConversationByContact() {
  yield takeEvery(GET_CONVERSATION_BY_CONTACT, function* ({ payload }) {
    try {
      const response = yield call(
        ConversationService.getConversationByContact,
        payload
      );
      let data = {
        id: payload.id,
        data: response.data,
        currentPage: payload.page,
      };
      yield put(setConversationByContact(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* markMessageAsReadSaga() {
  yield takeEvery(MARK_MESSAGE_AS_READ, function* ({ payload }) {
    try {
      yield call(ConversationService.markMessageAsRead, payload);
      yield delay(700);
      yield put(setMessageAsRead(payload.message.id));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getConversations)]);
  yield all([fork(getConversationByContact)]);
  yield all([fork(markMessageAsReadSaga)]);
}
