export const GET_COMMUNICATION_ACTIVITY = "GET_COMMUNICATION_ACTIVITY";
export const SET_COMMUNICATION_ACTIVITY = "SET_COMMUNICATION_ACTIVITY";
export const SEND_SMS = "SEND_SMS";
export const UPDATE_COMMUNICATION_ACTIVITY = "UPDATE_COMMUNICATION_ACTIVITY";
export const SEND_EMAIL = "SEND_EMAIL";
export const GET_CHILDREN_EMAIL = "GET_CHILDREN_EMAIL";
export const SET_CHILDREN_EMAIL = "SET_CHILDREN_EMAIL";
export const GET_HISTORY_ACTIVITY = "GET_HISTORY_ACTIVITY";
export const SET_HISTORY_ACTIVITY = "SET_HISTORY_ACTIVITY";
export const UPDATE_HISTORY_ACTIVITY = "UPDATE_HISTORY_ACTIVITY";
export const GET_TOKEN_PHONE_CALL = "GET_TOKEN_PHONE_CALL";
export const SET_TOKEN_PHONE_CALL = "SET_TOKEN_PHONE_CALL";
export const CALL_END = "CALL_END";
export const ADD_DATA_TO_ACTIVITY = "ADD_DATA_TO_ACTIVITY";
export const PUSH_NEW_STATUS_CHANGE = "PUSH_NEW_STATUS_CHANGE";
export const SET_NEW_ACTIVITY = "SET_NEW_ACTIVITY";
export const PUSH_NEW_AMOUNT_SPENT = "PUSH_NEW_AMOUNT_SPENT";
export const SEND_FACEBOOK_MESSAGE = "SEND_FACEBOOK_MESSAGE";
export const SEND_INSTAGRAM_MESSAGE = "SEND_INSTAGRAM_MESSAGE";
export const GET_LOYALTY_ACTIVITY = "GET_LOYALTY_ACTIVITY";
