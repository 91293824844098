export const POST_NEW_DOMAIN = "POST_NEW_DOMAIN";
export const GET_ALL_DOMAINS_PER_PAGE = "GET_ALL_DOMAINS_PER_PAGE";
export const SET_ALL_DOMAINS = "SET_ALL_DOMAINS";
export const DELETE_DOMAIN = "DELETE_DOMAIN";
export const UPDATE_DOMAIN = "UPDATE_DOMAIN";
export const UPDATE_DOMAIN_LIST = "UPDATE_DOMAIN_LIST";
export const GET_ALL_LANDING_PAGES_PER_PAGE = "GET_ALL_LANDING_PAGES_PER_PAGE";
export const SET_ALL_LANDING_PAGES = "SET_ALL_LANDING_PAGES";
export const CLONE_LANDING_PAGE = "CLONE_LANDING_PAGE";
export const POST_NEW_LANDING_PAGE = "POST_NEW_LANDING_PAGE";
export const GET_LANDING_PAGE_BY_ID = "GET_LANDING_PAGE_BY_ID";
export const GET_LANDING_PAGE_TEMPLATES = "GET_LANDING_PAGE_TEMPLATES";
export const SET_LANDING_PAGE_TEMPLATES = "SET_LANDING_PAGE_TEMPLATES";
export const SET_LANDING_PAGE = "SET_LANDING_PAGE";
export const DELETE_LANDING_PAGE = "DELETE_LANDING_PAGE";
export const UPDATE_LANDING_PAGE = "UPDATE_LANDING_PAGE";
export const UPDATE_LANDING_PAGE_INFO = "UPDATE_LANDING_PAGE_INFO";
export const UPDATE_STEP = "UPDATE_STEP";
export const GET_STEP_BY_ID = "GET_STEP_BY_ID";
export const SET_STEP = "SET_STEP";
export const GET_ALL_STEPS_BY_PAGE = "GET_ALL_STEPS_BY_PAGE";
export const SET_ALL_STEPS_BY_PAGE = "SET_ALL_STEPS_BY_PAGE";
export const REORDER_STEPS = "REORDER_STEPS";
export const DELETE_STEP = "DELETE_STEP";
export const UPDATE_STEP_LIST = "UPDATE_STEP_LIST";
export const REMOVE_STEP_FROM_LIST = "REMOVE_STEP_FROM_LIST";
export const CLONE_STEP = "CLONE_STEP";
export const PUSH_STEP_TO_LIST = "PUSH_STEP_TO_LIST";
export const ADD_NEW_STEP = "ADD_NEW_STEP";
export const UPDATE_STEP_CHAT_WIDGET = "UPDATE_STEP_CHAT_WIDGET";
export const SET_LANDING_PAGE_REQUEST = "SET_LANDING_PAGE_REQUEST";
export const CREATE_LANDING_PAGE_CONTENT = "CREATE_LANDING_PAGE_CONTENT"
export const UPDATE_LANDING_PAGE_CONTENT = "UPDATE_LANDING_PAGE_CONTENT";