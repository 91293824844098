import { message } from "antd";

const unpackAndShowErrors = (data) => {
  const errorKeys = Object.keys(data);
  errorKeys.forEach((key) => {
    const error = data[key];
    if (Array?.isArray(error)) {
      message.error(error?.[0], 3);
    } else {
      const nestedKeys = Object.keys(error);
      nestedKeys?.forEach((key) => {
        const nestedError = error[key];
        message.error(nestedError?.[0], 3);
      });
    }
  });
};

export default unpackAndShowErrors;
