export const DEFAULT_ORDER_FIELD = "first_name";
export const DEFAULT_ORDERING = "first_name";

export const DOCTORS_TABLE_ITEM_ACTIONS = {
  EDIT: "edit-doctors",
  DELETE: "delete-doctor",
};

export const NUMBER_PER_PAGE = 10;
export const MAX_NUMBER_PROFILE_IMG = 1;
export const MAX_NUMBER_CASUAL_IMG = 3;

export const IMG_TYPE = {
  PROFILE: "profile_img",
  CASUAL: "casul_img",
};

export const PHONE_NUMBER_PATTERN =
  "[+[0-9]{3}[0-9]{3}[0-9]{4}|([0-9]{3})[0-9]{3}-[0-9]{4}]";

export const MAX_NUMBER_CHARACTERS = 255;
export const MAX_NUMBER_CHARACTERS_TEXT_AREA = 10000;
export const MAX_IMAGE_SIZE = 5000000;

export const UPLOAD_ACCEPT = ".png, .jpg, .jpeg, .gif, .bmp, .webp";
export const REMOVED_IMG = "removed";
export const FIELD_TYPE_NUMBER = "number";

export const SELECT_OTHER = "Other";

export const DOCTOR_SELECT_OPTION_FORMAT = "%s %s %s";

export const MAX_NUMBER_OF_FIELDS = 5;
export const MIN_NUMBER_OF_FIELDS = 1;

export const HTML_EMPTY_FIELD_TAG = "<p><br></p>";

export const MAX_NUMBER_CHARACTERS_PROMO = 100;

export const TITLE_DISPLAY_LENGTH = 1;

export const MODE_TYPE = {
  PROFILE: "Profile",
  SETTINGS: "Settings",
};

export const TABS = {
  PROFILE: "Profile",
  SETTINGS: "Settings",
};

export const TAB_TO_MODE = {
  [TABS.PROFILE]: MODE_TYPE.PROFILE,
  [TABS.SETTINGS]: MODE_TYPE.SETTINGS,
};
