import {
  GET_ALL_DOCUMENTS,
  RENAME_DOCUMENT,
  SET_ALL_DOCUMENTS,
  UPLOAD_DOCUMENT,
  DELETE_DOCUMENT,
  SEND_MASS_DOCUMENT,
  GET_CONTACT_DOCUMENTS,
  SET_LOADER,
  GET_LOADER,
  SET_CONTACT_DOCUMENTS,
} from "../constants/Documents";

export const getAllDocuments = (payload) => ({
  type: GET_ALL_DOCUMENTS,
  payload,
});

export const setAllDocuments = (payload) => ({
  type: SET_ALL_DOCUMENTS,
  payload,
});

export const uploadDocument = (payload, initData) => ({
  type: UPLOAD_DOCUMENT,
  payload,
  initData,
});

export const renameDocument = (payload) => ({
  type: RENAME_DOCUMENT,
  payload,
});

export const deleteDocument = (id, page, successMessage, contactId) => ({
  type: DELETE_DOCUMENT,
  id,
  page,
  successMessage,
  contactId,
});

export const sendMassDocument = (payload, successMessage) => ({
  type: SEND_MASS_DOCUMENT,
  payload,
  successMessage,
});

export const getContactDocuments = (payload, page) => ({
  type: GET_CONTACT_DOCUMENTS,
  payload,
  page,
});

export const setContactDocuments = (payload) => ({
  type: SET_CONTACT_DOCUMENTS,
  payload,
});

export const setLoader = (payload) => ({
  type: SET_LOADER,
  payload,
});

export const getLoader = (payload) => ({
  type: GET_LOADER,
  payload,
});
