import ApiService from "./ApiService";
import { format } from "util";
import { stringify } from "querystring";

const ENDPOINTS = {
  CAMPAIGNS: "campaigns/",
  FOLDERS: "folders/",
  CHANGE_FOLDER: "folders/%s/",
  CHANGE_CAMPAIGN: "campaigns/%s/",
  DISCOUNTS: "discount/",
  GET_NEXT_ACTION_CAMPAIGNS: "campaigns/%s/next-action/available/",
  NEXT_ACTION: "campaigns/%s/next-action/",
  NEXT_ACTION_ITEM: "campaigns/%s/next-action/%s/",
  PUBLISHED_CAMPAIGNS: "campaigns/published/",
  REACTIVATION_CAMPAIGN_NEXT_ACTION: "campaigns/%s/reactivation/next-action/",
  REACTIVATION_CAMPAIGN_NEXT_ACTION_ITEM:
    "campaigns/%s/reactivation/next-action/%s/",
  REACTIVATION_CAMPAIGNS: "campaigns/reactivation/",
  TIME_CONFIG: "campaigns/%s/time-config/%s/",
  CREATE_TIME_CONFIG: "campaigns/%s/time-config/",
  DELETE_MULTIPLE_CAMPAIGNS: "campaigns/delete-multiple/",
  CONTACTS_ON_CAMPAIGN: "campaigns/%s/contacts/",
  CONTACTS_ON_CAMPAIGN_ITEM: "campaigns/%s/contacts/%s/",
  ADD_CONTACT_TO_CAMPAIGN: "contacts/%s/add_to_campaign/",
  REMOVE_CONTACT_FROM_CAMPAIGN: "contacts/%s/campaign_remove/",
  CREATE_COUNTDOWN_CAMPAIGN: "campaign/countdown/",
  EDIT_COUNTDOWN_CAMPAIGN: "campaign/countdown/%s/",
  ON_FINISHED_NEXT_CAMPAIGN: "campaigns/%s/next-campaign/",
  ON_FINISHED_NEXT_CAMPAIGN_ITEM: "campaigns/%s/next-campaign/%s/",
  COPY_CAMPAIGN: "campaigns/%s/copy-campaign/",
};

class CampaignService extends ApiService {
  getCampaigns = async (search, filter) => {
    const queryStringParams = {
      search: search,
      category: filter,
    };
    return await this.apiClient.get(
      `${ENDPOINTS.CAMPAIGNS}?${stringify(queryStringParams)}`
    );
  };

  getFolders = async () => {
    return await this.apiClient.get(ENDPOINTS.FOLDERS);
  };
  createFolder = async (data) => {
    return await this.apiClient.post(ENDPOINTS.FOLDERS, data);
  };
  createCampaign = async (data) => {
    return await this.apiClient.post(ENDPOINTS.CAMPAIGNS, data);
  };
  deleteFolder = async (id) => {
    return await this.apiClient.delete(format(ENDPOINTS.CHANGE_FOLDER, id));
  };
  editFolder = async (id, data) => {
    return await this.apiClient.patch(
      format(ENDPOINTS.CHANGE_FOLDER, id),
      data
    );
  };

  deleteCampaign = async (id) => {
    return await this.apiClient.delete(format(ENDPOINTS.CHANGE_CAMPAIGN, id));
  };

  deleteMultipleCampaign = async (data) => {
    return await this.apiClient.post(ENDPOINTS.DELETE_MULTIPLE_CAMPAIGNS, data);
  };

  editCampaign = async (id, data) => {
    return await this.apiClient.patch(
      format(ENDPOINTS.CHANGE_CAMPAIGN, id),
      data
    );
  };

  getCampaign = async (id) => {
    return await this.apiClient.get(format(ENDPOINTS.CHANGE_CAMPAIGN, id));
  };

  copyCampaign = (payload) => {
    return this.apiClient.post(
      format(ENDPOINTS.COPY_CAMPAIGN, payload.id),
      payload.data
    );
  };
  getAllDiscounts = () => {
    return this.apiClient.get(ENDPOINTS.DISCOUNTS);
  };

  createDiscount = (data) => {
    return this.apiClient.post(ENDPOINTS.DISCOUNTS, data);
  };

  getAvailableCampaigns = (id) => {
    return this.apiClient.get(format(ENDPOINTS.GET_NEXT_ACTION_CAMPAIGNS, id));
  };

  postNextReviewAction = (payload, id) => {
    return this.apiClient.post(format(ENDPOINTS.NEXT_ACTION, id), payload);
  };

  getNextActionByCampaign = (id) => {
    return this.apiClient.get(format(ENDPOINTS.NEXT_ACTION, id));
  };

  deleteNextAction = (campaignId, nextActionId) => {
    return this.apiClient.delete(
      format(ENDPOINTS.NEXT_ACTION_ITEM, campaignId, nextActionId)
    );
  };

  editNextAction = (campaignId, nextActionId, data) => {
    return this.apiClient.patch(
      format(ENDPOINTS.NEXT_ACTION_ITEM, campaignId, nextActionId),
      data
    );
  };

  getPublishedCampaigns = () => {
    return this.apiClient.get(ENDPOINTS.PUBLISHED_CAMPAIGNS);
  };

  getReactivationNextActionByCampaign = (campaignId) => {
    return this.apiClient.get(
      format(ENDPOINTS.REACTIVATION_CAMPAIGN_NEXT_ACTION, campaignId)
    );
  };

  postReactivationNextAction = (payload, campaignId) => {
    return this.apiClient.post(
      format(ENDPOINTS.REACTIVATION_CAMPAIGN_NEXT_ACTION, campaignId),
      payload
    );
  };

  editReactivationNextAction = (campaignId, nextActionId, data) => {
    return this.apiClient.patch(
      format(
        ENDPOINTS.REACTIVATION_CAMPAIGN_NEXT_ACTION_ITEM,
        campaignId,
        nextActionId
      ),
      data
    );
  };

  deleteReactivationNextAction = (campaignId, nextActionId) => {
    return this.apiClient.delete(
      format(
        ENDPOINTS.REACTIVATION_CAMPAIGN_NEXT_ACTION_ITEM,
        campaignId,
        nextActionId
      )
    );
  };

  getReactivationCampaigns = async () => {
    return await this.apiClient.get(ENDPOINTS.REACTIVATION_CAMPAIGNS);
  };

  editCampaignTimeConfig = (payload, campaignId, configId) => {
    return this.apiClient.put(
      format(ENDPOINTS.TIME_CONFIG, campaignId, configId),
      payload
    );
  };

  createCampaignTimeConfig = (payload, campaignId) => {
    return this.apiClient.post(
      format(ENDPOINTS.CREATE_TIME_CONFIG, campaignId),
      payload
    );
  };

  getContactsOnCampaign = (data) => {
    const queryStringParams = {
      page: data?.page,
      per_page: data?.perPage,
    };
    return this.apiClient.get(
      format(
        `${ENDPOINTS.CONTACTS_ON_CAMPAIGN}?${stringify(queryStringParams)}`,
        data?.campaignId
      )
    );
  };

  patchContactStatusOnCampaign = (campaignId, id) => {
    return this.apiClient.patch(
      format(ENDPOINTS.CONTACTS_ON_CAMPAIGN_ITEM, campaignId, id)
    );
  };

  deleteContactStatusOnCampaign = (campaignId, id) => {
    return this.apiClient.delete(
      format(ENDPOINTS.CONTACTS_ON_CAMPAIGN_ITEM, campaignId, id)
    );
  };

  deleteCustomTimeOnCampaign = (campaignId, id) => {
    return this.apiClient.delete(format(ENDPOINTS.TIME_CONFIG, campaignId, id));
  };

  addContactToCampaign = (payload) => {
    return this.apiClient.post(
      format(ENDPOINTS.ADD_CONTACT_TO_CAMPAIGN, payload?.id),
      payload?.values
    );
  };

  removeContactFromCampaign = (payload) => {
    return this.apiClient.post(
      format(ENDPOINTS.REMOVE_CONTACT_FROM_CAMPAIGN, payload?.id),
      payload?.values
    );
  };

  postCountdownCampaign = (payload) => {
    return this.apiClient.post(ENDPOINTS.CREATE_COUNTDOWN_CAMPAIGN, payload);
  };

  editCountdownCampaign = (payload) => {
    return this.apiClient.patch(
      format(ENDPOINTS.EDIT_COUNTDOWN_CAMPAIGN, payload.id),
      { end_date: payload.end_date }
    );
  };

  postOnFinishedNextCamapign = (payload) => {
    return this.apiClient.post(
      format(ENDPOINTS.ON_FINISHED_NEXT_CAMPAIGN, payload.campaignId),
      payload.next_campaign
    );
  };

  editOnFinishedNextCamapign = (payload) => {
    return this.apiClient.patch(
      format(
        ENDPOINTS.ON_FINISHED_NEXT_CAMPAIGN_ITEM,
        payload.campaignId,
        payload.id
      ),
      payload.next_campaign
    );
  };

  deleteOnFinishedNextCamapign = (payload) => {
    return this.apiClient.delete(
      format(
        ENDPOINTS.ON_FINISHED_NEXT_CAMPAIGN_ITEM,
        payload.campaignId,
        payload.id
      ),
      payload.next_campaign
    );
  };
}

export default new CampaignService();
