import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { ROUTES } from "routes";
import AuthService from "./AuthService";
import listener from "../auth/Listener";
import { LOGOUT_PATH } from "constants/AuthConstants";

class HttpService {
  constructor(options = {}) {
    axios.defaults.withCredentials = true;
    this.client = axios.create(options);
    this.listener = listener;
    this.isRefreshing = false;
    this.handleErrorResponse = this.handleErrorResponse.bind(this);
    this.client.interceptors.response.use(
      this.handleSuccessResponse,
      this.handleErrorResponse
    );
    this.unauthorizedCallback = () => {};
  }

  attachHeaders(headers) {
    Object.assign(this.client.defaults.headers, headers);
  }

  removeHeaders(headerKeys) {
    headerKeys.forEach((key) => delete this.client.defaults.headers[key]);
  }

  handleSuccessResponse(response) {
    return response;
  }

  setNewAuthorizationHeader(error) {
    error.response.config.headers["Authorization"] =
      "Bearer " + AuthService.getToken();
  }

  async handleErrorResponse(error) {
    const { status } = error.response;

    switch (status) {
      case 401: {
        AuthService.destroySession();
        window.location.reload();
        break;
      }
      case 403: {
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          await AuthService.rotateRefreshToken();
          this.setNewAuthorizationHeader(error);
          this.isRefreshing = false;
          let req;
          if (error.response.config.url == LOGOUT_PATH) {
            req = AuthService.logout();
          } else {
            req = axios(error.response.config);
          }
          this.listener.notify();
          return req;
        } else {
          return new Promise((resolve, reject) => {
            this.listener.addListener(() => {
              this.setNewAuthorizationHeader(error);
              return axios(error.response.config).then(resolve).catch(reject);
            });
          });
        }
      }
      case 404: {
        window.location.href = ROUTES.NOT_FOUND;
        break;
      }
      default:
        break;
    }

    return Promise.reject(error);
  }

  setUnauthorizedCallback(callback) {
    this.unauthorizedCallback = callback;
  }
}

const options = {
  baseURL: API_BASE_URL,
};
const httpService = new HttpService(options);

export default httpService;
