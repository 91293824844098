// @ts-nocheck
/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import {
  SET_EVENTS,
  SHOW_MESSAGE,
  PUSH_NEW_EVENT,
  REMOVE_EVENT,
  UPDATE_EVENT,
  SET_LOADING,
  UNSHIFT_NEW_EVENT,
} from "../constants/Event";
import produce from "immer";

export const initialState = {
  events: {},
  loading: true,
};

const eventReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case SET_EVENTS:
        draft.events[payload.id] = payload.data;
        break;

      case SHOW_MESSAGE:
        draft.message = payload;
        break;
      case PUSH_NEW_EVENT:
        draft.events[payload.id].push(payload.data);
        break;
      case UNSHIFT_NEW_EVENT:
        draft.events[payload.id].unshift(payload.data);
        break;
      case UPDATE_EVENT:
        let indexChange = draft.events[payload.id].findIndex(
          (el) => el.id === payload.event.data.id
        );

        draft.events[payload.id][indexChange] = payload.event.data;
        break;
      case REMOVE_EVENT:
        let newList = draft.events[payload.id].filter(
          (el) => el.id !== payload.deleted_id
        );

        draft.events[payload.id] = newList;
        break;
      case SET_LOADING:
        draft.loading = payload;
        break;
    }
  });

export default eventReducer;
