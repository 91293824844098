import { CREATE_PIPELINE, DELETE_PIPELINE, GET_ALL_PIPELINES, GET_SALES_PIPELINE, GET_SINGLE_PIPELINE, SET_ALL_PIPELINES, SET_SINGLE_PIPELINE, UPDATE_PIPELINE } from "redux/constants/Pipeline";
  
  export const getAllPipelines = (payload) => ({
    type: GET_ALL_PIPELINES,
    payload,
  });

  export const setAllPipelines = (payload) => ({
    type: SET_ALL_PIPELINES,
    payload,
  });

  export const getSinglePipeline = (payload) => ({
    type: GET_SINGLE_PIPELINE,
    payload,
  });

  export const getSalesPipeline = () => ({
    type: GET_SALES_PIPELINE,
  })

  export const setPipeline = (payload) => ({
    type: SET_SINGLE_PIPELINE,
    payload,
  });

  export const createPipeline = (payload, initData, successMessage) => ({
      type: CREATE_PIPELINE,
      payload,
      initData,
      successMessage
  });

  export const deletePipeline = (payload, initData, successMessage) => ({
      type: DELETE_PIPELINE,
      payload,
      initData,
      successMessage
  });

  export const updatePipeline = (id, payload, initData, successMessage) => ({
    type: UPDATE_PIPELINE,
    id,
    payload,
    initData,
    successMessage,
  });

  