import { io } from "socket.io-client";
import authService from "./AuthService";
import jwt_decode from "jwt-decode";
import { WEBSOCKETS_URL } from "configs/AppConfig";

class WebsocketService {
  constructor() {
    this.loadInstance().catch(console.debug);
  }

  loadInstance = async () => {
    let token = "Bearer " + authService.getToken();
    let userId = jwt_decode(token)?.user_id;

    this.instance = io(WEBSOCKETS_URL, {
      extraHeaders: {
        Authorization: token,
        UserId: userId,
      },
    });

    this.instance.on("connect", () => {
      this.instance.emit("join", `user-${userId}`);
    });
  };

  listenAndExecuteEvent = (eventName, callBack) => {
    let token = authService.getToken();
    if (token && this.instance)
      this.instance.on(eventName, (eventData) => {
        callBack(eventData);
      });
  };
}

const websocketService = new WebsocketService();
export default websocketService;
