export const SIGNIN = "SIGNIN";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const AUTHENTICATED = "AUTHENTICATED";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const AUTH_TOKEN = "token";
export const SIGNIN_WITH_GOOGLE = "SIGNIN_WITH_GOOGLE";
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED =
  "SIGNIN_WITH_GOOGLE_AUTHENTICATED";
export const SIGNIN_WITH_FACEBOOK = "SIGNIN_WITH_FACEBOOK";
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED =
  "SIGNIN_WITH_FACEBOOK_AUTHENTICATED";

export const CONFIRM_RESET_PASSWORD = "CONFIRM_RESET_PASSWORD";
export const CONFIRM_RESET_PASSWORD_ERROR = "CONFIRM_RESET_PASSWORD_ERROR";

export const SEND_FORGOT_PASSWORD_EMAIL = "SEND_FORGOT_PASSWORD_EMAIL";
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS =
  "SEND_FORGOT_PASSWORD_EMAIL_SUCCESS";
export const SEND_FORGOT_PASSWORD_EMAIL_ERROR =
  "SEND_FORGOT_PASSWORD_EMAIL_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const CREATE_PASSWORD = "CREATE_PASSWORD";
export const CREATE_PASSWORD_ERROR = "CREATE_PASSWORD_ERROR";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const GET_CURRENT_USER_DATA = "GET_CURRENT_USER_DATA";
export const GET_CURRENT_USER_DATA_SUCCESS = "GET_CURRENT_USER_DATA_SUCCESS";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const VERIFICATION_SIGNIN = "VERIFICATION_SIGNIN";
export const VERIFICATION_ERROR = "VERIFICATION_ERROR";
export const SIGNIN_LOYATY_USER = "SIGNIN_LOYATY_USER"