/* eslint-disable default-case */
import produce from "immer";
import {
  SET_CONTACT_SETTINGS,
  UPDATE_CONTACT_SETTINGS,
  SHOW_MESSAGE,
} from "../constants/ContactSettings";

const initialState = {
  formFields: {
    data: [],
    isUpdated: false,
  },
  message: "",
};

const contactSettingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case SET_CONTACT_SETTINGS:
        draft.formFields.data = payload;
        draft.formFields.isUpdated = false;
        break;
      case UPDATE_CONTACT_SETTINGS:
        draft.formFields.data = payload;
        draft.formFields.isUpdated = true;
        break;
      case SHOW_MESSAGE:
        draft.message = payload;
        break;
    }
  });

export default contactSettingsReducer;
