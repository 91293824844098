import { 
GET_BUSINESS_SUGESTIONS,
SET_BUSINESS_SUGESTIONS, 
CREATE_SLUG_INFORMATION,
SET_SLUG_INFORMATION,
GET_RATINGS,
SET_RATINGS,
GET_REVIEWS,
SET_REVIEWS,
CREATE_REVIEWS_FROM_SLUG,
PICK_RATING_DATA,
} from 'redux/constants/Reviews';

export const getBusinessSugestions = (payload) => ({
    type: GET_BUSINESS_SUGESTIONS, 
    payload,
});

export const setBusinessSugestions = (payload) => ({
    type: SET_BUSINESS_SUGESTIONS,
    payload
});

export const createSlugInformation = (payload, successMessage, setShowGetData) => ({
    type: CREATE_SLUG_INFORMATION,
    payload,
    successMessage,
    setShowGetData
});

export const setSlugInformation = (payload) => ({
    type: SET_SLUG_INFORMATION,
    payload
});

export const getRatings = () => ({
    type: GET_RATINGS
});

export const setRatings = (payload) => ({
    type: SET_RATINGS,
    payload
});

export const getReviews = () => ({
    type: GET_REVIEWS
});

export const setReviews = (payload) => ({
    type: SET_REVIEWS,
    payload
});

export const getReviewsFromSlug = (payload) => ({
    type: CREATE_REVIEWS_FROM_SLUG,
    payload,
});

export const pickRatingData = (payload, setShowForm, successMessage) => ({
    type: PICK_RATING_DATA, 
    payload,
    setShowForm,
    successMessage,
})
