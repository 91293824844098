import React, { useState, useEffect } from "react";
import { notification, Button } from "antd";
import { ROUTES } from "routes";
import { format } from "util";
import { useIntl } from "react-intl";
import messages from "utils/messages";
import notificationAdapter from "notification-adapter/notificationAdapter";

export const CallNotification = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [callerInfo, setCallerInfo] = useState("");
  const [callerContactLink, setCallerContactLink] = useState("");
  const { formatMessage } = useIntl();

  const incomingCallFunction = (data) => {
    setCallerContactLink(data?.id);
    setIsNotification(true);
    setCallerInfo(data?.name || data?.phone_number);
  };

  const endCallFunction = () => {
    setCallerContactLink("");
    setIsNotification(false);
    setCallerInfo("");
    notification.close("incoming_call_notification");
  };

  useEffect(() => {
    notificationAdapter.onCallStart(incomingCallFunction);
    notificationAdapter.onCallEnd(endCallFunction);
  }, []);

  const noficationoLink = (
    <Button type="primary" onClick={() => setIsNotification(false)}>
      <a href={format(ROUTES.SINGLE_CONTACT, callerContactLink)}>
        {formatMessage(messages.linkBtn)}
      </a>
    </Button>
  );

  useEffect(() => {
    if (isNotification)
      notification.open({
        key: "incoming_call_notification",
        message: formatMessage(messages.notificationIncomingCallMessage),
        description: formatMessage(
          messages.notificationIncomingCallDescription,
          { callerInfo }
        ),
        btn: callerContactLink && noficationoLink,
        duration: null,
        onClose: () => setIsNotification(false),
      });
  }, [callerInfo]);
  return <></>;
};

export default CallNotification;
