import produce from "immer";
import {
  SET_STRIPE_CONNECT_URL,
  SET_STRIPE_ACCOUNT_ID,
  SET_INVOICE_URL,
  SET_TAXES,
  SHOW_LOADER,
  SET_PRODUCTS,
  SET_PRICE,
  SET_EDITED_PRICE,
  SET_PAYMENT_PROCESSOR,
} from "redux/constants/PaymentIntegrations";
import productManager from "helpers/stripeProductStataManager";

export const initialState = {
  stripe: {
    connectURL: null,
    userStripeAccount: null,
  },
  invoiceURL: null,
  showLoader: false,
  taxData: null,
  products: null,
  prices: null,
  paymentProcessor: null,
};

const paymentIntegrationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_STRIPE_CONNECT_URL:
        draft.stripe.connectURL = payload;
        break;
      case SET_STRIPE_ACCOUNT_ID:
        const user = payload?.active_subscription?.user;
        const account_id = user?.connected_account;
        if (account_id) {
          draft.stripe.userStripeAccount = account_id;
        }
        break;
      case SET_TAXES:
        draft.taxData = payload;
        break;
      case SHOW_LOADER:
        draft.showLoader = payload;
        break;
      case SET_INVOICE_URL:
        draft.invoiceURL = payload;
        draft.showLoader = false;
        break;
      case SET_PRODUCTS:
        draft.products = payload;
        draft.showLoader = false;
        break;
      case SET_PRICE:
        draft.prices = payload;
        break;
      case SET_EDITED_PRICE:
        draft.products = productManager.updateExistingPriceTemplate(
          payload,
          draft.products
        );
        break;
      case SET_PAYMENT_PROCESSOR:
        draft.paymentProcessor = payload;
        break;
      default:
        return state;
    }
  });

export default paymentIntegrationReducer;
