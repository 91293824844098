// @ts-nocheck
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  renameFormSuccess,
  setAllForms,
  setAllFormsNoPagination,
  setLoading,
  setSingleForm,
} from "redux/actions/FormBuilder";
import {
  CREATE_FORM,
  DELETE_FORM,
  GET_ALL_FORMS_PAGINATED,
  GET_SINGLE_FORM,
  SET_FORMS_ORDER,
  GET_ALL_FORMS,
  UPDATE_FORM,
  COPY_FORM,
  RENAME_FORM,
} from "redux/constants/FormBuilder";
import { makeSelectAllForms } from "redux/selectors/FormBuilder";
import FormBuilderService from "services/FormBuilderService";
import messages from "views/app-views/FormBuilderPage/messages";

function* getFormsPaginated({ payload }) {
  try {
    yield put(setLoading(true));
    const { data } = yield call(FormBuilderService.getFormsPaginated, payload);
    yield put(setAllForms(data));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading(false));
  }
}

export function* getAllFormsSaga() {
  yield takeEvery(GET_ALL_FORMS, function* () {
    try {
      const results = yield call(FormBuilderService.getAllForms);
      yield put(setAllFormsNoPagination(results.data));
    } catch (err) {
      console.log(err);
    }
  });
}

function* createFormSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(FormBuilderService.createForm, payload.data);
    yield call(payload.onSuccessCallback, messages.createSuccessful);
  } catch (err) {
    yield call(payload.onErrorCallback);
  } finally {
    yield put(setLoading(false));
  }
}

function* getSingleFormSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const { data } = yield call(FormBuilderService.getSingleForm, payload);
    yield put(setSingleForm(data));
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateFormSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(FormBuilderService.updateForm, payload.data, payload.id);
    yield call(payload.onSuccessCallback, messages.updateSuccessful);
  } catch (err) {
    yield call(payload.onErrorCallback);
  } finally {
    yield put(setLoading(false));
  }
}

function* deleteFormSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(FormBuilderService.deleteForm, payload.data);
    yield call(payload.afterDelete);
    const { order, orderField, search } = yield select(makeSelectAllForms);
    yield getFormsPaginated({ payload: { order, orderField, search } });
  } catch {
  } finally {
    yield put(setLoading(false));
  }
}

function* copyFormSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(FormBuilderService.copyForm, payload.id, payload.data);
    yield call(payload.afterCopy);
    const { order, orderField, search } = yield select(makeSelectAllForms);
    yield getFormsPaginated({ payload: { order, orderField, search } });
  } catch (err) {
    yield call(payload.onCopyError);
  } finally {
    yield put(setLoading(false));
  }
}

function* renameFormSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const { data } = yield call(
      FormBuilderService.updateForm,
      payload.data,
      payload.id
    );
    yield put(renameFormSuccess(data));
    yield call(payload.closeModal);
    yield call(payload.onRenameSuccess);
  } catch (err) {
    yield call(payload.onRenameError);
  } finally {
    yield put(setLoading(false));
  }
}

export function* formBuilderSaga() {
  yield takeEvery(GET_ALL_FORMS_PAGINATED, getFormsPaginated);
  yield takeEvery(SET_FORMS_ORDER, getFormsPaginated);
  yield takeEvery(CREATE_FORM, createFormSaga);
  yield takeEvery(GET_SINGLE_FORM, getSingleFormSaga);
  yield takeEvery(UPDATE_FORM, updateFormSaga);
  yield takeEvery(DELETE_FORM, deleteFormSaga);
  yield takeEvery(COPY_FORM, copyFormSaga);
  yield takeEvery(RENAME_FORM, renameFormSaga);
}

export default function* rootSaga() {
  yield all([fork(formBuilderSaga), fork(getAllFormsSaga)]);
}
