import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Aeva";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/dashboard";
export const AUTH_PREFIX_PATH = "/auth";
export const UNSUBSCRIBE_PATH = "/unsubscribe";
export const APP_LOYALTY_PREFIX_PATH = "/loyalty";
export const AUTH_LOYALTY_PREFIX_PATH = "/auth-loyalty";
export const PUBLIC_PREFIX_PATH = "/public";
export const AGENCY_PREFIX_PATH = "/agency";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
};

export const API_IMG_URL = env.API_ENDPOINT_IMG_URL;
export const STRIPE_API_KEY = env.API_ENDPOINT_STRIPE_API_KEY;
export const API_PREVIEW_LANDING_PAGE_PROTOCOL =
  env.API_ENDPOINT_PREVIEW_LANDING_PAGE_PROTOCOL;
export const WEBSOCKETS_URL = env.WEBSOCKETS_URL;
export const S3_STATIC_URL = env.S3_STATIC_URL;
export const A_RECORD = env.A_RECORD;
export const CNAME_RECORD = env.CNAME_RECORD;
export const APP_ENV = env.APP_ENV;
