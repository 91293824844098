import {
  SET_ALL_DOCUMENTS,
  SET_CONTACT_DOCUMENTS,
  SET_LOADER,
} from "redux/constants/Documents";
import produce from "immer";

export const initialState = {
  documentsList: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
  },
  loading: true,
  contactDocuments: null,
};

const documentsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_ALL_DOCUMENTS:
        draft.documentsList = {
          count: payload?.data?.count,
          current: payload?.data?.current,
          next: payload?.data?.next,
          previous: payload?.data?.previous,
          total_pages: payload?.data?.total_pages,
          results: payload?.data?.results,
        };
        break;
      case SET_CONTACT_DOCUMENTS:
        draft.contactDocuments = payload?.data;
        break;
      case SET_LOADER:
        draft.loading = payload;
        break;
      default:
        return state;
    }
  });
export default documentsReducer;
