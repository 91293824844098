import produce from "immer";
import {
  SET_DOCTOR,
  SET_DOCTORS_PAGINATION,
  SET_DOCTOR_TITLES,
} from "redux/constants/Doctors";
export const initialState = {
  doctors: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
    order: "ascend",
    orderField: "first_name",
  },
  doctor: null,
  doctorTitles: [],
};

const doctorsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_DOCTORS_PAGINATION:
        draft.doctors = {
          count: payload.data.count,
          current: payload.data.current,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results: payload.data.results,
          isLoading: false,
          order: payload.order,
          orderField: payload.orderField,
        };
        break;
      case SET_DOCTOR:
        draft.doctor = payload;
        break;
      case SET_DOCTOR_TITLES:
        draft.doctorTitles = payload.data;
        break;
      default:
        return state;
    }
  });
export default doctorsReducer;
