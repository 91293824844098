import { message } from "antd";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { setBrandData } from "redux/actions/TwilioBrandRegostration";
import {
  POST_BRAND_REGISTRATION,
  GET_BRAND_REGISTRATION,
  UPDATE_BRAND_REGISTRATION,
  SUBMIT_BRAND_REGISTRATION,
  RESUBMIT_BRAND_REGISTRATION,
} from "redux/constants/TwilioBrandRegostration";
import { BRAND_FIELD_NAME } from "constants/BusinessBrandConstants";
import TwilioRegistrationService from "services/TwilioRegistrationService";

const brand_data_keys = [BRAND_FIELD_NAME.BUSINESS, BRAND_FIELD_NAME.AUTH_REP, BRAND_FIELD_NAME.ADDRESS];
const handleErrorMessage = (error) => {
  let error_key = Object.keys(error);
  if (brand_data_keys.includes(`${error_key}`)) {
    message.error(Object.values(error[error_key])[0], 3);
  } else {
    message.error(error?.non_field_errors, 3);
  }
};

export function* postBrandRegistrationData() {
  yield takeEvery(POST_BRAND_REGISTRATION, function* ({ payload }) {
    try {
      const response = yield call(TwilioRegistrationService.postBrandRegistrationData, payload);
      yield put(setBrandData(response.data));
    } catch (err) {
      console.log(err.response.data);
      handleErrorMessage(err.response.data);
    }
  });
}

export function* getBrandRegistrationData() {
  yield takeEvery(GET_BRAND_REGISTRATION, function* () {
    try {
      const response = yield call(TwilioRegistrationService.getBrandRegistrationData);
      yield put(setBrandData(response.data));
    } catch (err) {
      console.log(err.response.data);
      message.error(err.response.data?.non_field_errors, 3);
    }
  });
}

export function* updateBrandRegistrationDataSaga() {
  yield takeEvery(UPDATE_BRAND_REGISTRATION, function* ({ payload, id }) {
    try {
      const response = yield call(TwilioRegistrationService.updateBrandRegistrationData, payload, id);
      yield put(setBrandData(response.data));
    } catch (err) {
      handleErrorMessage(err.response.data);
    }
  });
}

export function* submitBrandRegistrationSaga() {
  yield takeEvery(SUBMIT_BRAND_REGISTRATION, function* () {
    try {
      const response = yield call(TwilioRegistrationService.submitBrandRegistration);
      yield put(setBrandData(response.data));
    } catch (err) {
      console.log(err.response.data);
      message.error(err.response.data?.non_field_errors, 3);
    }
  });
}

export function* resubmitBrandRegistrationSaga() {
  yield takeEvery(RESUBMIT_BRAND_REGISTRATION, function* () {
    try {
      const response = yield call(TwilioRegistrationService.resubmitBrandRegistration);
      yield put(setBrandData(response.data));
    } catch (err) {
      console.log(err.response.data);
      message.error(err.response.data?.non_field_errors, 5);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(postBrandRegistrationData),
    fork(getBrandRegistrationData),
    fork(updateBrandRegistrationDataSaga),
    fork(submitBrandRegistrationSaga),
    fork(resubmitBrandRegistrationSaga),
  ]);
}
