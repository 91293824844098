export const GET_STRIPE_ACCOUNT_ID = "GET_STRIPE_ACCOUNT_ID";
export const SET_STRIPE_ACCOUNT_ID = "SET_STRIPE_ACCOUNT_ID";
export const GET_STRIPE_CONNECT_URL = "GET_STRIPE_CONNECT_URL";
export const SET_STRIPE_CONNECT_URL = "SET_STRIPE_CONNECT_URL";
export const CREATE_INVOICE_URL = "CREATE_INVOICE_URL";
export const SET_INVOICE_URL = "SET_INVOICE_URL";
export const GET_TAXES = "GET_TAXES";
export const SET_TAXES = "SET_TAXES";
export const CREATE_TAX = "CREATE_TAX";
export const DELETE_TAX = "DELETE_TAX";
export const SHOW_LOADER = "SHOW_LOADER";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const LIST_PRICES = "LIST_PRICES";
export const CREATE_PRICE = "CREATE_PRICE";
export const SET_PRICE = "SET_PRICE";
export const EDIT_PRICE = "EDIT_PRICE";
export const SET_EDITED_PRICE = "SET_EDITED_PRICE";
export const DELETE_PRICE = "DELETE_PRICE";
export const SET_PAYMENT_PROCESSOR = "SET_PAYMENT_PROCESSOR";
export const CHANGE_TYPE = "CHANGE_TYPE";
