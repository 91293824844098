import {
  GET_DASHBOARD_DATA,
  SET_DASHBOARD_DATA,
  GET_CONTACT_CONVERSIONS,
  SET_CONTACT_CONVERSIONS,
  SET_DASHBOARD_FILTER,
} from "../constants/Dashboard";

export const getDashboardData = (
  payload = { start_date: null, end_date: null }
) => ({
  type: GET_DASHBOARD_DATA,
  payload,
});

export const setDashboardStatsData = (payload) => ({
  type: SET_DASHBOARD_DATA,
  payload,
});

export const getContactConversions = (payload) => ({
  type: GET_CONTACT_CONVERSIONS,
  payload,
});

export const setContactConversions = (payload) => ({
  type: SET_CONTACT_CONVERSIONS,
  payload,
});

export const setDashboarFilter = (payload) => ({
  type: SET_DASHBOARD_FILTER,
  payload,
});
