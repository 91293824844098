import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  FUNNELS: "funnels/",
  DELETE_FUNNEL: "funnels/%s/",
  EDIT_FUNNEL: "funnels/%s/",
  GET_FUNNEL_CONTACTS: "funnels-contacts/?id=%s&page=%s&perPage=%s&search=%s",
  REORDER_FUNNELS: "funnels/reorder/",
  REORDER_CONTACTS: "funnels-contacts/reorder/",
  REACTIVATIN_FUNNELS: "funnels/reactivation/"
};

class FunnelService extends ApiService {
  getFunnels = async (pipelineId) => {
    let queryParams = "";
    if (pipelineId) {
      queryParams = `?pipeline=${pipelineId}`
    }
    return await this.apiClient.get(`${ENDPOINTS.FUNNELS}${queryParams}`);
  };

  createFunnel = async (value) => {
    return await this.apiClient.post(ENDPOINTS.FUNNELS, value);
  };

  deleteFunnel = async (id) => {
    return await this.apiClient.delete(format(ENDPOINTS.DELETE_FUNNEL, id));
  };

  editFunnel = async (data) => {
    return await this.apiClient.put(
      format(ENDPOINTS.EDIT_FUNNEL, data.id),
      data
    );
  };

  getFunnelContacts = async (page, perPage, id, search) => {
    return await this.apiClient.get(
      format(ENDPOINTS.GET_FUNNEL_CONTACTS, id, page, perPage, search)
    );
  };
  reorderFunnels = async (data) => {
    return await this.apiClient.patch(format(ENDPOINTS.REORDER_FUNNELS), data);
  };

  reorderContacts = async (data) => {
    return await this.apiClient.patch(format(ENDPOINTS.REORDER_CONTACTS), data);
  };

  getReactivationFunnels = () => {
    return this.apiClient.get(ENDPOINTS.REACTIVATIN_FUNNELS);
  };
}

export default new FunnelService();
