import { 
    GET_NOTIFICATIONS,
    SET_NOTIFICATIONS,
    UPDATE_ALL_NOTIFICATIONS,
    UPDATE_SINGLE_NOTIFICATION,
    SET_SINGLE_NOTIFICATION
} from '../constants/NotificationSettings';

export const getNotifications = () => ({
    type: GET_NOTIFICATIONS
});

export const setNotifications = (payload) => ({
    type: SET_NOTIFICATIONS,
    payload
});

export const updateAllNotifications = (payload) => ({
        type: UPDATE_ALL_NOTIFICATIONS,
        payload 
});

export const updateSingleNotification = (payload) => ({
    type: UPDATE_SINGLE_NOTIFICATION,
    payload
});

export const setSingleNotification = (payload) => ({
    type: SET_SINGLE_NOTIFICATION,
    payload
});
