const createActivity = (id, contact, oldContactFunnel) => {
  const newActivity = {
    contact: {
      id: id,
      name: contact.data.name,
      created_at: contact.data.created_at,
    },
    content_object: {
      contact: {
        id: id,
        name: contact.data.name,
        created_at: contact.data.created_at,
      },
      funnel_new: contact.data.status,
      funnel_old: oldContactFunnel,
    },
    content_type: {
      id: 41,
      app_label: "activity",
      model: "statuschange",
    },
    date: new Date(),
  };
  return newActivity;
};

export const createStatusChanged = (payload) => {
  const { id, contact, oldContactFunnel } = payload;

  if (oldContactFunnel?.id !== contact?.data?.status?.id)
    return createActivity(id, contact, oldContactFunnel);

  if (oldContactFunnel === null && contact.data.status !== null)
    return createActivity(id, contact, oldContactFunnel);

  if (oldContactFunnel !== null && contact.data.status === null)
    return createActivity(id, contact, oldContactFunnel);
};
