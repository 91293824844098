import store from "redux/store";
import ApiService from "./ApiService";
import { format } from "util";
import { stringify } from "querystring";

const ENDPOINTS = {
  STRIPE_CONNECT: "stripe-connect/",
  INVOICE: {
    stripe: "stripe-invoice/",
    square: "square/invoice/",
  },
  TAX: {
    stripe: "stripe/tax/",
    square: "square/tax/",
  },
  DELETE_TAX: {
    stripe: "stripe/tax/%s/",
    square: "square/tax/%s/",
  },
  PRODUCTS: {
    stripe: "stripe/product/",
    square: "square/products/",
    manual: "manual/products/",
  },
  CREATE_PRODUCT: {
    stripe: "stripe/product/",
    square: "square/products/",
    manual: "manual/products/",
  },
  EDIT_PRODUCT: {
    stripe: "stripe/product/%s/",
    square: "square/products/%s/",
    manual: "manual/products/%s/",
  },
  DELETE_PRODUCT: {
    stripe: "stripe/product/%s/",
    square: "square/products/%s/",
    manual: "manual/products/%s/",
  },
  CHANGE_TYPE: {
    square: "square/products/%s/change-type/",
    manual: "manual/products/%s/change-type/",
  },
  CREATE_PRICE: {
    stripe: "stripe/product/%s/price/",
    square: "square/products/%s/price/",
    manual: "manual/products/%s/price/",
  },
  LIST_PRICES: {
    stripe: "stripe/product/%s/price/",
    square: "square/products/%s/price/",
    manual: "manual/products/%s/price/",
  },
  EDIT_PRICE: {
    stripe: "stripe/product/%s/price/%s/",
    square: "square/products/%s/price/%s/",
    manual: "manual/products/%s/price/%s/",
  },
  DELETE_PRICE: {
    stripe: "stripe/product/%s/price/%s/",
    square: "square/products/%s/price/%s/",
    manual: "manual/products/%s/price/%s/",
  },
  CREATE_CASH_PAYMENT: "cash-invoice/",
  SQUARE_CONNECT: "square/connect/",
  SQUARE_DISCONNECT: "square/disconnect/",
  SQUARE_CONNECT_STATUS: "square/status/",
  SQUARE_PRODUCT_DOWNLOAD: "square/products/retreive-from-square/",
  SQUARE_PRODUCT_DOWNLOAD_CACHE: "square/products/cache-retreive-from-square/",
};

const paymentProcessor = (itemData, checkSquare = false) => {
  const processor = store.getState()?.paymentIntegration?.paymentProcessor;

  if (checkSquare) {
    let version = null;
    if (itemData instanceof FormData) {
      version = itemData?.get("square_version");
    } else {
      version = itemData?.square_version;
    }

    const itemExists = !(version === "null" || version === null);
    return processor && itemExists ? processor : "manual";
  }

  return processor ? processor : "manual";
};

class PaymentIntegrationService extends ApiService {
  getStripeConnectURL = () => {
    return this.apiClient.get(ENDPOINTS.STRIPE_CONNECT);
  };

  getStripeUserAccountId = (data) => {
    return this.apiClient.post(ENDPOINTS.STRIPE_CONNECT, data);
  };

  getTaxData = (data) => {
    return this.apiClient.get(
      `${ENDPOINTS.TAX[paymentProcessor()]}?${stringify(data)}`
    );
  };

  createTax = (data) => {
    return this.apiClient.post(ENDPOINTS.TAX[paymentProcessor()], data);
  };

  deleteTax = (data) => {
    return this.apiClient.delete(
      format(ENDPOINTS.DELETE_TAX[paymentProcessor()], data)
    );
  };

  createInvoiceURL = (data) => {
    return this.apiClient.post(ENDPOINTS.INVOICE[paymentProcessor()], data);
  };

  getProducts = (data) => {
    return this.apiClient.get(
      `${ENDPOINTS.PRODUCTS[paymentProcessor()]}?${stringify(data)}`
    );
  };

  createProduct = (data) => {
    return this.apiClient.post(
      ENDPOINTS.CREATE_PRODUCT[paymentProcessor()],
      data
    );
  };

  editProduct = (data, productId) => {
    const processor = paymentProcessor(data, true);
    return this.apiClient.patch(
      format(ENDPOINTS.EDIT_PRODUCT[processor], productId),
      data
    );
  };

  deleteProduct = (data) => {
    const processor = paymentProcessor(data, true);
    return this.apiClient.delete(
      format(ENDPOINTS.DELETE_PRODUCT[processor], data?.id)
    );
  };

  listPrices = (data) => {
    const processor = paymentProcessor(data, true);
    return this.apiClient.get(
      format(ENDPOINTS.LIST_PRICES[processor], data?.id)
    );
  };

  createPrice = (data, productData) => {
    const processor = paymentProcessor(productData, true);
    return this.apiClient.post(
      format(ENDPOINTS.CREATE_PRICE[processor], productData?.id),
      data
    );
  };

  editPrice = (data, productData, priceId) => {
    const processor = paymentProcessor(productData, true);
    return this.apiClient.put(
      format(ENDPOINTS.EDIT_PRICE[processor], productData?.id, priceId),
      data
    );
  };

  deletePrice = (productData, priceId) => {
    const processor = paymentProcessor(productData, true);
    return this.apiClient.delete(
      format(ENDPOINTS.DELETE_PRICE[processor], productData?.id, priceId)
    );
  };

  createCashPayment = (payload) => {
    return this.apiClient.post(ENDPOINTS.CREATE_CASH_PAYMENT, payload);
  };

  squareConnect = (payload) => {
    return this.apiClient.post(ENDPOINTS.SQUARE_CONNECT, payload);
  };

  squareDisconnect = () => {
    return this.apiClient.post(ENDPOINTS.SQUARE_DISCONNECT);
  };

  squareConnectionStatus = () => {
    return this.apiClient.get(ENDPOINTS.SQUARE_CONNECT_STATUS);
  };

  downloadSquareProducts = () => {
    return this.apiClient.get(ENDPOINTS.SQUARE_PRODUCT_DOWNLOAD);
  };

  downloadSquareProductsCache = () => {
    return this.apiClient.get(ENDPOINTS.SQUARE_PRODUCT_DOWNLOAD_CACHE);
  };

  changeProductType = (productId) => {
    return this.apiClient.post(
      format(ENDPOINTS.CHANGE_TYPE[paymentProcessor()], productId)
    );
  };
}

export default new PaymentIntegrationService();
