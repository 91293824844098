import { getLocalStorageItem } from "utils/localStorage";
import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
  RESET_PASSWORD_ERROR,
  CREATE_PASSWORD_ERROR,
  SIGNIN_ERROR,
  CHANGE_PASSWORD_ERROR,
  GET_CURRENT_USER_DATA_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_INFO,
  VERIFICATION_ERROR,
} from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN),
  currentUser: "",
  success: false,
  isNewUser: getLocalStorageItem("newUser"),
  verificationMessage: "",
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.token,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        verificationMessage: "",
        showMessage: false,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload.response.data.password,
        showMessage: true,
      };
    case SIGNIN_ERROR:
      return {
        ...state,
        message: action.payload.response.data.non_field_errors[0],
        showMessage: true,
      };
    case CREATE_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload.response.data.error.non_field_errors,
        showMessage: true,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        message:
          action.payload?.response?.data?.error?.old_password ||
          action?.payload?.response?.data?.error?.new_password,
        showMessage: !!action.payload,
      };
    case GET_CURRENT_USER_DATA_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        success: true,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEND_FORGOT_PASSWORD_EMAIL_SUCCESS: {
      return {
        ...state,
      };
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case UPDATE_USER_INFO: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          organization: action.payload.data,
        },
      };
    }
    case VERIFICATION_ERROR: {
      return {
        ...state,
        verificationMessage: action.payload.response.data.non_field_errors[0],
        showMessage: true,
      };
    }
    default:
      return state;
  }
};

export default auth;
