import {
  GET_CONVERSATION,
  SET_CONVERSATION,
  GET_CONVERSATION_BY_CONTACT,
  SET_CONVERSATION_BY_CONTACT,
  MARK_MESSAGE_AS_READ,
  SET_MESSAGE_AS_READ,
  ADD_MESSAGE_TO_LIST,
  UPDATE_CONTACT_INFO,
  SET_FILTER,
  SET_SEARCH,
  SET_RECIEVED_MESSAGE_TO_CONTACT,
  SET_RECIEVED_MESSAGE_TO_LIST,
  SET_IS_SENDING_MESSAGE,
} from "../constants/Conversation";

export const getConversationForAllContacts = (payload) => ({
  type: GET_CONVERSATION,
  payload: payload,
});

export const setConversationForAllContacts = (payload) => ({
  type: SET_CONVERSATION,
  payload,
});

export const getConversationByContact = (payload) => ({
  type: GET_CONVERSATION_BY_CONTACT,
  payload,
});

export const setConversationByContact = (payload) => ({
  type: SET_CONVERSATION_BY_CONTACT,
  payload,
});

export const markMessageAsRead = (payload) => ({
  type: MARK_MESSAGE_AS_READ,
  payload,
});

export const setMessageAsRead = (payload) => ({
  type: SET_MESSAGE_AS_READ,
  payload,
});

export const addMessageToConversation = (payload) => ({
  type: ADD_MESSAGE_TO_LIST,
  payload,
});

export const updateContactInfo = (payload) => ({
  type: UPDATE_CONTACT_INFO,
  payload,
});

export const setFilterVal = (payload) => ({
  type: SET_FILTER,
  payload,
});

export const setSearchVal = (payload) => ({
  type: SET_SEARCH,
  payload,
});

export const setReceivedMessageToContact = (payload) => ({
  type: SET_RECIEVED_MESSAGE_TO_CONTACT,
  payload,
});
export const setReceivedMessageToList = (payload) => ({
  type: SET_RECIEVED_MESSAGE_TO_LIST,
  payload,
});
export const setIsSendingMessage = (payload) => ({
  type: SET_IS_SENDING_MESSAGE,
  payload,
});
