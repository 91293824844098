/* eslint-disable default-case */
import { ERROR_TYPE_DELETE } from "constants/ContactConstant";
import {
  SET_ORGANIZATION_DATA,
  SET_PRACTICE_INFO,
  UPDATE_ORGANIZATION_ERROR,
} from "redux/constants/Organization";

export const initialState = {
  organization: {
    errorMessage: "",
    errorType: "",
  },
  practiceInfo: null,
  organization_forwarding_number: null,
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ORGANIZATION_ERROR: {
      if (action.payload?.response?.data?.phone_number) {
        return {
          ...state,
          errorMessage: action.payload.response.data.phone_number,
        };
      } else if (action.payload?.response?.data?.website) {
        return {
          ...state,
          errorMessage: action.payload.response.data.website,
        };
      } else if (action.payload?.response?.data?.name) {
        return {
          ...state,
          errorMessage: action.payload.response.data.name,
        };
      } else if (action.payload?.response?.data?.email) {
        return {
          ...state,
          errorMessage: action.payload.response.data.email,
        };
      } else if (action.payload?.response?.data?.non_field_errors) {
        return {
          ...state,
          errorMessage: action.payload.response.data.non_field_errors,
        };
      } else if (action.payload?.response?.data?.delete_address_error) {
        return {
          ...state,
          errorMessage: action.payload.response.data.delete_address_error,
          errorType: ERROR_TYPE_DELETE,
        };
      } else {
        return {
          ...state,
          errorMessage: [],
          errorType: [],
        };
      }
    }
    case SET_PRACTICE_INFO:
      return {
        ...state,
        practiceInfo: action.payload,
      };

    case SET_ORGANIZATION_DATA:
      return {
        ...state,
        organization_forwarding_number: {
          name: action.payload.name,
          active_phone_number: action.payload.active_phone_number,
          forwarding_number: action.payload.forwarding_number,
        },
      };

    default:
      return state;
  }
};

export default organizationReducer;
