import produce from "immer";
import {
  SET_PAYMENT_DETAILS,
  SET_ACTIVE_SUBSCRIPTION,
  SET_COUNTRIES,
  SET_BILLING_ADDRESS,
  SET_ALL_TRANSACTIONS,
} from "redux/constants/Payment";

export const initialState = {
  paymentDetails: null,
  countries: [],
  transactions: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
  },
};

const paymentReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_PAYMENT_DETAILS:
        draft.paymentDetails = payload;
        break;
      case SET_ACTIVE_SUBSCRIPTION:
        draft.paymentDetails.active_subscription = payload;
        break;
      case SET_COUNTRIES:
        draft.countries = payload;
        break;
      case SET_BILLING_ADDRESS:
        draft.paymentDetails.payment_information.billing_address = payload;
        break;
      case SET_ALL_TRANSACTIONS:
        draft.transactions = {
          count: payload.count,
          current: payload.current,
          next: payload.next,
          previous: payload.previous,
          total_pages: payload.total_pages,
          results: payload.results,
          isLoading: false,
        };
        break;
      default:
        return state;
    }
  });
export default paymentReducer;
