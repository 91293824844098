import {
  CREATE_CHANGE_FUNNEL_TRIGGER,
  SET_NEW_CHANGE_FUNNEL_TRIGGER,
  GET_TRIGGERS_PAGINATION,
  SET_TRIGGERS_PAGINATION,
  DELETE_TRIGGER,
  GET_TRIGGER,
  SET_TRIGGER,
  EDIT_CHANGE_FUNNEL_TRIGGER,
  SET_UPDATED_TRIGGER,
  CREATE_REACTIVATION_TRIGGER,
  EDIT_REACTIVATIONL_TRIGGER,
  GET_REACTIVATION_TRIGGER,
  UPDATE_SINGLE_TRIGGER,
} from "redux/constants/Trigger";

export const postNewChangeFunnelTrigger = (payload, closeModal) => ({
  type: CREATE_CHANGE_FUNNEL_TRIGGER,
  payload,
  closeModal,
});

export const setNewChangeFunnelTrigger = (payload) => ({
  type: SET_NEW_CHANGE_FUNNEL_TRIGGER,
  payload,
});

export const getTriggersPagination = (payload) => ({
  type: GET_TRIGGERS_PAGINATION,
  payload,
});

export const setTriggersPagination = (payload) => ({
  type: SET_TRIGGERS_PAGINATION,
  payload,
});

export const deleteTrigger = (payload) => ({
  type: DELETE_TRIGGER,
  payload,
});

export const getTrigger = (payload) => ({
  type: GET_TRIGGER,
  payload,
});

export const setTrigger = (payload) => ({
  type: SET_TRIGGER,
  payload,
});

export const editChangeFunnelTrigger = (id, payload, closeModal, enableBtn) => ({
  type: EDIT_CHANGE_FUNNEL_TRIGGER,
  id,
  payload,
  closeModal,
});

export const setUpdatedTrigger = (payload) => ({
  type: SET_UPDATED_TRIGGER,
  payload,
});

export const postNewReactivationTrigger = (payload, closeModal) => ({
  type: CREATE_REACTIVATION_TRIGGER,
  payload,
  closeModal,
});

export const editReactivationTrigger = (id, payload, closeModal) => ({
  type: EDIT_REACTIVATIONL_TRIGGER,
  id,
  payload,
  closeModal,
});

export const getReactivationTrigger = (payload) => ({
  type: GET_REACTIVATION_TRIGGER,
  payload,
});

export const setUpdateSingleTrigger = (payload) => ({
  type: UPDATE_SINGLE_TRIGGER,
  payload,
})