import { message } from "antd";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getContactPictures, setAllPictures } from "redux/actions/Pictures";
import {
  DELETE_PICTURES,
  GET_CONTACT_PICTURES,
  UPLOAD_PICTURE,
} from "redux/constants/Pictures";
import PicturesService from "services/PicturesService";

export function* getContactPicturesSaga() {
  yield takeEvery(GET_CONTACT_PICTURES, function* ({ payload, page }) {
    try {
      const results = yield call(
        PicturesService.getContactPictures,
        payload,
        page.page
      );
      yield put(setAllPictures(results));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* deletePicturesSaga() {
  yield takeEvery(
    DELETE_PICTURES,
    function* ({ payload, contactId, page, successMessage }) {
      try {
        yield call(PicturesService.deletePictures, payload);
        yield put(getContactPictures(contactId, { page: page }));
        message.success(successMessage, 3);
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* uploadPicturesSaga() {
  yield takeEvery(UPLOAD_PICTURE, function* ({ payload, initData, contactId }) {
    try {
      yield call(PicturesService.uploadPicture, payload);
      yield put(getContactPictures(contactId, initData));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getContactPicturesSaga),
    fork(deletePicturesSaga),
    fork(uploadPicturesSaga),
  ]);
}
