// @ts-nocheck
import moment from "moment";
import { TIME_TYPE, DAYS_OF_WEEK } from "constants/EventConstant";

const TIME_FORMAT = "hh:mm a";

export const getDate = (date) => {
  var dateS = date.split("-");
  return moment(new Date(dateS[0], dateS[1] - 1, dateS[2])).format("DD MMMM");
};

export const formatDate = (value, format = null) =>
  format ? moment(value).format(format) : moment(value);

export const formatTime = (value, format) => moment(value, format);

export const isSameMonth = (a, b) =>
  new Date(a).getMonth() === new Date(b).getMonth();

export const formatTimeString = (time, format = TIME_FORMAT) =>
  moment(time, "hh:mm:ss").format(format);

export const secondsToTime = (seconds) => {
  return moment.utc(parseInt(seconds) * 1000).format("H [h] m [m] s[s]");
};

export const convertTime = (time, timeType) => {
  if (TIME_TYPE.HOURS === timeType) return time / 60;
  if (TIME_TYPE.DAYS === timeType) return time / 1440;

  return time;
};

export const getDaysString = (days) => {
  var daysString = "";
  if (days) {
    days.forEach((d) => {
      daysString = daysString + DAYS_OF_WEEK[d] + ", ";
    });

    daysString = daysString.slice(0, -2);
  }

  return daysString;
};
