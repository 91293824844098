import {
  SET_FUNNELS,
  GET_FUNNELS,
  CREATE_FUNNEL,
  UPDATE_FUNNELS,
  DELETE_FUNNEL,
  EDIT_FUNNEL,
  GET_FUNNEL_CONTACTS,
  SET_FUNNEL_CONTACTS,
  REORDER_FUNNELS,
  UPDATE_NAME_FUNNEL,
  REORDER_CONTACTS,
  SET_ORDER_CONTACTS,
  GET_REACTIVATION_FUNNELS,
  CLEARE_FUNNELS_DATA,
} from "../constants/Funnel";

export const setFunnels = (payload) => ({
  type: SET_FUNNELS,
  payload,
});

export const getFunnels = (pipelineId) => ({
  type: GET_FUNNELS,
  pipelineId,
});

export const createFunnel = (payload, callback) => ({
  type: CREATE_FUNNEL,
  payload: payload,
  callback,
});

export const updateFunnels = (payload) => ({
  type: UPDATE_FUNNELS,
  payload: payload,
});

export const deleteFunnel = (payload) => ({
  type: DELETE_FUNNEL,
  payload: payload,
});

export const editFunnel = (payload, callback) => ({
  type: EDIT_FUNNEL,
  payload: payload,
  callback,
});

export const getFunnelContacts = (payload) => ({
  type: GET_FUNNEL_CONTACTS,
  payload: payload,
});

export const setFunnelContacts = (payload) => ({
  type: SET_FUNNEL_CONTACTS,
  payload,
});
export const reorderFunnels = (payload) => ({
  type: REORDER_FUNNELS,
  payload: payload,
});

export const updateNameFunnel = (payload) => ({
  type: UPDATE_NAME_FUNNEL,
  payload: payload,
});

export const reorderContactsFunnels = (payload) => ({
  type: REORDER_CONTACTS,
  payload: payload,
});

export const setOrderContacts = (payload) => ({
  type: SET_ORDER_CONTACTS,
  payload,
});

export const getReactivationFunnels = () => ({
  type: GET_REACTIVATION_FUNNELS,
});

export const clearFunnelsState = () => ({
  type: CLEARE_FUNNELS_DATA,
});
