import {
  COPY_FORM,
  CREATE_FORM,
  DELETE_FORM,
  GET_ALL_FORMS,
  GET_ALL_FORMS_PAGINATED,
  GET_SINGLE_FORM,
  RENAME_FORM,
  RENAME_FORM_SUCCESS,
  RESET_SINGLE_FORM,
  SET_ALL_FORMS,
  SET_ALL_FORMS_NO_PAGINATION,
  SET_FORMS_ORDER,
  SET_FORMS_PAGE,
  SET_LOADING,
  SET_SINGLE_FORM,
  UPDATE_FORM,
} from "redux/constants/FormBuilder";

export const getAllFormsPaginated = (payload = {}) => ({
  type: GET_ALL_FORMS_PAGINATED,
  payload,
});

export const setAllForms = (payload) => ({
  type: SET_ALL_FORMS,
  payload,
});

export const getSingleForm = (payload) => ({
  type: GET_SINGLE_FORM,
  payload,
});

export const setSingleForm = (payload) => ({
  type: SET_SINGLE_FORM,
  payload,
});

export const deleteForm = (payload) => ({
  type: DELETE_FORM,
  payload,
});

export const updateForm = (payload) => ({
  type: UPDATE_FORM,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setFormsPage = (payload) => ({
  type: SET_FORMS_PAGE,
  payload,
});

export const setFormsOrder = (payload) => ({
  type: SET_FORMS_ORDER,
  payload,
});

export const getAllFormsNoPagination = (payload) => ({
  type: GET_ALL_FORMS,
  payload,
});

export const setAllFormsNoPagination = (payload) => ({
  type: SET_ALL_FORMS_NO_PAGINATION,
  payload,
});

export const createForm = (payload) => ({
  type: CREATE_FORM,
  payload,
});

export const resetSingleForm = () => ({
  type: RESET_SINGLE_FORM,
});

export const copyForm = (payload) => ({
  type: COPY_FORM,
  payload,
});

export const renameForm = (payload) => ({
  type: RENAME_FORM,
  payload,
});

export const renameFormSuccess = (payload) => ({
  type: RENAME_FORM_SUCCESS,
  payload,
});
