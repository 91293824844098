export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS";
export const SET_PAYMENT_DETAILS = "SET_PAYMENT_DETAILS";
export const SUBSCRIBE = "SUBSCRIBE";
export const UNSUBSCRIBE = "UNSUBSCRIBE";
export const SET_ACTIVE_SUBSCRIPTION = "SET_ACTIVE_SUBSCRIPTION";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const UPDATE_BILLING_ADDRESS = "UPDATE_BILLING_ADDRESS";
export const SET_BILLING_ADDRESS = "SET_BILLING_ADDRESS";
export const CHANGE_CREDIT_CARD = "CHANGE_CREDIT_CARD";
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const SET_ALL_TRANSACTIONS = "SET_ALL_TRANSACTIONS";
