import { getLocalStorageItem, setLocalStorageItem } from "utils/localStorage";
import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  LOGIN_ATTEMPT: "/login/attempt/",
  LOGIN_CONFIRM: "/login/confirm/",
  LOGOUT: "/logout/",
  FORGOT_PASSWORD: "/password_reset/",
  RESET_PASSWORD: "/password_reset/confirm/",
  CREATE_PASSWORD: "/users/create-password/",
  CHANGE_PASSWORD: "/users/change-password/",
  ME: "/users/me/",
  UPDATE_USER: "/users/%s/",
  SIGN_UP: "/users/",
  ROTATE_REFRESH_TOKEN: "/token/refresh/",
  LOGIN_LOYALTY_USER:"/loyalty/login/"
};

class AuthService extends ApiService {
  constructor() {
    super();
    this.init();
  }

  init = () => {
    const token = this.getToken();
    const user = this.getUser();

    if (token && user) {
      this.setAuthorizationHeader();

      this.api.setUnauthorizedCallback(this.destroySession.bind(this));
    }
  };

  setAuthorizationHeader = () => {
    const token = this.getToken();
    if (token) {
      this.api.attachHeaders({
        Authorization: `Bearer ${token}`,
      });
    }
  };

  createSession = (user) => {
    setLocalStorageItem("token", JSON.stringify(user));
    this.setAuthorizationHeader();
  };

  destroySession = () => {
    localStorage.clear();
    this.api.removeHeaders(["Authorization"]);
  };

  getUser = () => {
    const user = getLocalStorageItem("token");
    return JSON.parse(user);
  };

  login = async (loginData) => {
    const { data } = await this.apiClient.post(
      ENDPOINTS.LOGIN_ATTEMPT,
      loginData
    );
    if (data) this.createSession(data);
    return data;
  };

  logout = async () => {
    const { data } = await this.apiClient.post(ENDPOINTS.LOGOUT, {
      refresh: this.getRefreshToken(),
    });
    this.destroySession();
    return data;
  };

  getCurrentUserData = async () => {
    const { data } = await this.apiClient.get(ENDPOINTS.ME);
    return data;
  };

  resetPassword = async (payload) => {
    const { data } = await this.apiClient.post(
      ENDPOINTS.RESET_PASSWORD,
      payload
    );
    return data;
  };

  changePassword = async (payload) => {
    const { data } = await this.apiClient.patch(
      ENDPOINTS.CHANGE_PASSWORD,
      payload
    );
    return data;
  };

  createPassword = async (payload) => {
    const { data } = await this.apiClient.patch(
      ENDPOINTS.CREATE_PASSWORD,
      payload
    );
    return data;
  };

  getToken = () => {
    const user = getLocalStorageItem("token");
    return user ? JSON.parse(user).access : undefined;
  };

  getRefreshToken = () => {
    const user = getLocalStorageItem("token");
    return user ? JSON.parse(user).refresh : undefined;
  };

  sendForgotPasswordEmail = async (payload) => {
    const { data } = await this.apiClient.post(
      ENDPOINTS.FORGOT_PASSWORD,
      payload
    );
    return data;
  };

  updateUser = async ({ payload, id }) => {
    const { data } = await this.apiClient.patch(
      format(ENDPOINTS.UPDATE_USER, id),
      payload
    );
    return data;
  };

  signUp = (user) => {
    return this.apiClient.post(ENDPOINTS.SIGN_UP, user);
  };

  loginUser = async (code) => {
    const { data } = await this.apiClient.post(ENDPOINTS.LOGIN_CONFIRM, code);
    this.createSession(data);
    return data;
  };

  setNewRefresToken = async (token) => {
    this.api.removeHeaders(["Authorization"]);
    const { data } = await this.apiClient.post(
      ENDPOINTS.ROTATE_REFRESH_TOKEN,
      token
    );
    this.createSession(data);
    return data;
  };

  rotateRefreshToken = async () => {
    const token = this.getRefreshToken();
    const data = {
      refresh: token,
    };
    const newToken = await this.setNewRefresToken(data);
    return newToken;
  };

  loginLoyaltyUser = async (loginData) => {
    const { data } = await this.apiClient.post(
      ENDPOINTS.LOGIN_LOYALTY_USER,
      loginData
    );
    if (data) this.createSession(data);
    return data;
  };
}

const authService = new AuthService();
export default authService;
