import produce from "immer";
import { SET_TABLE_LOADING, SET_TRANSACTIONS } from "redux/constants/Reporting";

export const initialState = {
  transactions: null,
  tableLoading: true,
};

const reportingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_TRANSACTIONS:
        draft.transactions = payload;
        break;
      case SET_TABLE_LOADING:
        draft.tableLoading = payload;
        break;
      default:
        return state;
    }
  });
export default reportingReducer;
