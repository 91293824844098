export const FUNNEL_NEW_LEAD = "New Leads";

export const DEFAULT_FUNNELS = {
  NEW_LEAD: "New Leads",
  HOT_LEAD: "Hot Leads",
  BOOKING_CONFIRMED: "Booking Confirmed",
  CONSULTATION_DONE: "Consultation Done",
  SERVICE_SOLD: "Service(s) sold",
  BOOK_ME: "Book Me",
  SEND_REVIEW: "Send review",
  REPLIED: "Replied (Reactivation Friendly)",
  HOT_LEAD_REACTIVATED: "Hot Lead (Reactivated)",
};

export const DEFAULT_FUNNELS_VALUES = [
  DEFAULT_FUNNELS.NEW_LEAD,
  DEFAULT_FUNNELS.HOT_LEAD,
  DEFAULT_FUNNELS.BOOKING_CONFIRMED,
  DEFAULT_FUNNELS.CONSULTATION_DONE,
  DEFAULT_FUNNELS.SERVICE_SOLD,
  DEFAULT_FUNNELS.BOOK_ME,
  DEFAULT_FUNNELS.SEND_REVIEW,
  DEFAULT_FUNNELS.REPLIED,
  DEFAULT_FUNNELS.HOT_LEAD_REACTIVATED,
];

export const MODE_TYPE = {
  CAMPAIGNS: "Campaigns",
  TRIGGERS: "Trigger",
};

export const TABS = {
  CAMPAIGNS: "Campaigns",
  TRIGGERS: "Trigger",
};

export const TAB_TO_MODE = {
  [TABS.CAMPAIGNS]: MODE_TYPE.CAMPAIGNS,
  [TABS.TRIGGERS]: MODE_TYPE.TRIGGERS,
};

export const REVIEW = "Review";
export const REACTIVATION = "Reactivation";
export const CHANGE_FUNNEL = "Change funnel";
export const CHANGE_CAMPAIGN = "Change campaign";
export const ADD_TO_CAMPAIGN = "Add to campaign";
export const ADD_TO_FUNNEL = "Add to funnel";

export const TRIGGER_CHOICES = {
  CHANGE_FUNNEL: "Change_funnel",
  REACTIVATION: "Change_campaign",
};

export const ACTION_CHOICES = {
  ADD_TO_CAMPAIGN: "Add_to_campaign",
  ADD_TO_FUNNEL: "Add_to_funnel",
};

export const TRIGGERS_FIELDS = {
  TRIGGER: "trigger_type",
  ACTIONS: "action_type",
  FUNNEL: "destination_funnel",
  CAMPAIGN: "object_id",
  NAME: "name",
};

export const REACTIVATION_TRIGGERS_FIELDS = {
  TRIGGER: "trigger_type",
  ACTIONS: "action_type",
  CAMPAIGN: "reactivation_campaign",
  NAME: "name",
  ANSWER_TRIGGER: "answer_trigger",
  FUNNEL: "object_id",
  ANSWER: "answer",
};

export const MAX_LENGHT_NAME = 50;

export const NUMBER_PER_PAGE = 10;

export const TRIGGER_TABLE_ITEM_ACTIONS = {
  EDIT: "edit-trigger",
  DELETE: "delete-trigger",
};

export const SELECTED_PIPELINE = "selectedPipeline";

export const FUNNEL = "Funnel";
