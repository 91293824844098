import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { GET_CHAT_WIDGET, UPDATE_CHAT_WIDGET, SET_AVATAR } from "redux/constants/ChatWidget";
import ChatWidgetService from "../../services/ChatWidgetService";
import { setChatWidget, isLoading, setAvatar } from "redux/actions/ChatWidget";
import { message } from "antd";


export function* getChatWidget() {
    yield takeEvery(GET_CHAT_WIDGET, function* () {
        try {
            yield put(isLoading(true));
            const { data } = yield call(ChatWidgetService.getChatWidget);
            yield put(setChatWidget(data.results[0]));
        } catch (err) {
            console.log(err);
        } finally {
            yield put(isLoading(false));
        }
    });
}

export function* updateChatWidget() {
    yield takeEvery(UPDATE_CHAT_WIDGET, function* ({payload, id, successMessage}) {
        try {
            const response = yield call(ChatWidgetService.updateChatWidget, payload, id);
            yield put(setChatWidget(response.data));
            message.success(successMessage, 3);
        } catch (err){
            message.error(err.response.data.detail);
            console.log(err);
        }
    });
}

export function* cropAvatar() {
    yield takeEvery(SET_AVATAR, function* (payload) {
        try {
            const response = yield call(ChatWidgetService.cropAvatar, payload);
            yield put(setAvatar(response.data));
        } catch (err) {
            console.log(err);
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getChatWidget),
        fork(updateChatWidget),
        fork(cropAvatar),
    ])
}