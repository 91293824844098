import {
  all,
  takeEvery,
  put,
  fork,
  call,
  delay,
  select,
} from "redux-saga/effects";
import {
  SEND_FORGOT_PASSWORD_EMAIL,
  SIGNOUT,
  SIGNIN,
  RESET_PASSWORD,
  CREATE_PASSWORD,
  CHANGE_PASSWORD,
  GET_CURRENT_USER_DATA,
  UPDATE_USER,
  SIGNUP,
  VERIFICATION_SIGNIN,
  SIGNIN_LOYATY_USER,
} from "../constants/Auth";
import {
  changePasswordError,
  createPasswordError,
  getCurrentUserDataSuccess,
  resetPasswordError,
  sendForgotPasswordEmailError,
  sendForgotPasswordEmailSuccess,
  showAuthMessage,
  signInError,
  updateUserSuccess,
  signIn,
  getCurrentUserData,
  verificationError,
  authenticated,
  hideAuthMessage,
} from "../actions/Auth";
import { setPaymentDetails } from "../actions/Payment";
import { setSingleUserAwards } from "redux/actions/Awards";
import { go, push } from "connected-react-router";

import AuthService from "services/AuthService";
import { ROUTES } from "routes";
import jwt_decode from "jwt-decode";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "utils/localStorage";
import { message } from "antd";
import { makeSelectCurrentUser } from "redux/selectors/Users";
import { ROLE } from "constants/RoleConstants";
import {
  NEW_USER,
  SELECTED_ORGANIZATION,
  ORGANIZATION_NOT_EXISTS,
  ACTIVE_SUBSCRIPTION_NOT_EXISTS,
  USER_LOGIN,
  CARD_NOT_VALID,
  REFERRER,
} from "constants/AuthConstants";
import { setOrganizationData } from "redux/actions/Organization";
import { redirectPage } from "helpers/redirectToPage";

function setLoyatyOrganizationInStorage(token) {
  if (!!jwt_decode(token)?.loyalty_organization) {
    let loyalty_organization = JSON.parse(
      jwt_decode(token)?.loyalty_organization
    );
    if (Object.values(loyalty_organization).length > 1) {
      push(ROUTES.LOYALTY_SELECT_ORGANIZATION);
    } else {
      setLocalStorageItem(SELECTED_ORGANIZATION, loyalty_organization[0]);
    }
  }
}

export function* signInSaga() {
  yield takeEvery(SIGNIN, function* ({ payload, disableSubmit }) {
    try {
      const res = yield call(AuthService.login, payload);
      if (res) {
        yield call(loginUser, res);
      } else {
        setLocalStorageItem(USER_LOGIN, payload.username);
        yield put(push(ROUTES.VERIFICATION_CODE));
      }
    } catch (error) {
      yield put(signInError(error));
      disableSubmit(false);
    }
  });
}

export function* signInVerificationSaga() {
  yield takeEvery(VERIFICATION_SIGNIN, function* ({ payload, disableSubmit }) {
    try {
      const res = yield call(AuthService.loginUser, payload);
      yield call(loginUser, res);
    } catch (error) {
      yield put(verificationError(error));
      disableSubmit(false);
    }
  });
}

export function* passwordReset() {
  yield takeEvery(
    RESET_PASSWORD,
    function* ({ payload, messageSuccess, redirectRoute }) {
      try {
        yield call(AuthService.resetPassword, payload);
        yield delay(3000, message.success(messageSuccess));
        yield put(push(redirectRoute));
      } catch (error) {
        yield put(resetPasswordError(error));
      }
    }
  );
}

export function* passwordChange() {
  yield takeEvery(
    CHANGE_PASSWORD,
    function* ({ payload, messageSuccess, disableSubmit }) {
      try {
        yield call(AuthService.changePassword, payload);
        message.success(messageSuccess, 3);
        yield put(changePasswordError(null));
        disableSubmit(false);
      } catch (error) {
        yield put(changePasswordError(error));
        disableSubmit(false);
      }
    }
  );
}

export function* passwordCreate() {
  yield takeEvery(
    CREATE_PASSWORD,
    function* ({ payload, messageSuccess, redirectRoute, disableSubmit }) {
      try {
        yield call(AuthService.createPassword, payload);
        removeLocalStorageItem(NEW_USER);
        const token = getLocalStorageItem("token");
        let user = yield select(makeSelectCurrentUser());
        user.organization === null &&
          setLocalStorageItem(ORGANIZATION_NOT_EXISTS, true);
        user.active_subscription === null &&
          setLocalStorageItem(ACTIVE_SUBSCRIPTION_NOT_EXISTS, true);
        !user.payment_information?.payment_method?.is_valid &&
          setLocalStorageItem(CARD_NOT_VALID, true);
        yield delay(3000, message.success(messageSuccess));
        setLoyatyOrganizationInStorage(token);
        yield put(push(redirectRoute));
      } catch (error) {
        yield put(createPasswordError(error));
        disableSubmit(false);
      }
    }
  );
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      let user = yield select(makeSelectCurrentUser());
      yield call(AuthService.logout);
      yield put(setPaymentDetails(null));
      yield put(authenticated(null, false));
      if (user.role.access_type == ROLE.PATIENT) {
        yield put(setSingleUserAwards(null));
        yield put(getCurrentUserDataSuccess(""));
        yield put(push(ROUTES.LOYALTY_LOGIN));
        yield put(go());
      } else {
        yield put(push(ROUTES.LOGIN));
        yield put(go());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* forgotPasswordEmailSend() {
  yield takeEvery(
    SEND_FORGOT_PASSWORD_EMAIL,
    function* ({ payload, redirectRoute, disableSubmit }) {
      try {
        const sendEmail = yield call(
          AuthService.sendForgotPasswordEmail,
          payload
        );
        yield put(push(redirectRoute));
        yield put(sendForgotPasswordEmailSuccess(sendEmail));
      } catch (err) {
        message.error(err.response.data.email[0], 3);
        yield put(sendForgotPasswordEmailError(err));
        disableSubmit(false);
      }
    }
  );
}

export function* userCurrent() {
  yield takeEvery(GET_CURRENT_USER_DATA, function* () {
    try {
      const data = yield call(AuthService.getCurrentUserData);
      yield put(getCurrentUserDataSuccess(data));
      data.organization === null &&
        setLocalStorageItem(ORGANIZATION_NOT_EXISTS, true);
      setLocalStorageItem(
        ACTIVE_SUBSCRIPTION_NOT_EXISTS,
        data.active_subscription === null ? true : false
      );
      setLocalStorageItem(
        CARD_NOT_VALID,
        !data.payment_information?.payment_method?.is_valid ? true : false
      );
      yield put(setOrganizationData(data.organization));
    } catch (error) {}
  });
}

export function* userUpdate() {
  yield takeEvery(
    UPDATE_USER,
    function* ({ payload, id, successMessage, disableSubmit }) {
      try {
        const data = yield call(AuthService.updateUser, {
          payload: payload,
          id: id,
        });
        yield put(updateUserSuccess(data));
        message.success(successMessage, 3);
        disableSubmit(false);
      } catch (error) {
        message.error(error.response?.data?.non_field_errors[0], 3);
        disableSubmit(false);
      }
    }
  );
}

export function* signUp() {
  yield takeEvery(SIGNUP, function* ({ payload, disableSubmit }) {
    try {
      yield call(AuthService.signUp, payload);
      const data = {
        username: payload.email,
        password: payload.password,
      };
      yield put(signIn(data, disableSubmit));
    } catch (error) {
      error.response.data["username"] &&
        message.error(error.response.data["username"], 3);
      if ("non_field_errors" in error.response.data) {
        message.error(error.response.data.non_field_errors[0], 3);
      }
      disableSubmit(false);
    }
  });
}

function* loginUser(res) {
  yield put(getCurrentUserData());
  const accessData = jwt_decode(res.access);
  const isNewUser = accessData.is_new_user;
  yield put(authenticated(res.access, isNewUser));
  if (isNewUser) {
    setLocalStorageItem(NEW_USER, true);
    yield put(push(ROUTES.CREATE_PASSWORD));
  } else if (
    accessData.organization === null &&
    !accessData.app_access_list.includes("agency")
  ) {
    setLocalStorageItem(ORGANIZATION_NOT_EXISTS, true);
    yield put(push(ROUTES.SETTINGS));
  } else {
    const referrer = getLocalStorageItem(REFERRER);
    let pageRoute = redirectPage(accessData?.app_access_list);
    const route = referrer ? referrer : pageRoute;
    removeLocalStorageItem(REFERRER);
    yield put(push(route));
  }
  removeLocalStorageItem(USER_LOGIN);
}

function* loginLoyatyUser(res) {
  yield put(getCurrentUserData());
  const isNewUser = jwt_decode(res.access).is_new_user;
  yield put(authenticated(res.access, isNewUser));
  if (isNewUser) {
    setLocalStorageItem(NEW_USER, true);
    yield put(push(ROUTES.CREATE_PASSWORD_LOYALTY_USER));
  } else {
    let loyalty_organization = JSON.parse(
      jwt_decode(res.access).loyalty_organization
    );
    if (Object.values(loyalty_organization).length > 1) {
      yield put(push(ROUTES.LOYALTY_SELECT_ORGANIZATION));
    } else {
      setLocalStorageItem(SELECTED_ORGANIZATION, loyalty_organization[0]);
      yield put(push(ROUTES.LOYALTY_PAGE));
    }
  }
  yield put(hideAuthMessage());
}

export function* signInLoyatyUserSaga() {
  yield takeEvery(SIGNIN_LOYATY_USER, function* ({ payload, disableSubmit }) {
    try {
      const res = yield call(AuthService.loginLoyaltyUser, payload);
      if (res) {
        yield call(loginLoyatyUser, res);
      }
    } catch (error) {
      yield put(signInError(error));
      disableSubmit(false);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInSaga),
    fork(signOut),
    fork(forgotPasswordEmailSend),
    fork(passwordReset),
    fork(passwordCreate),
    fork(passwordChange),
    fork(userCurrent),
    fork(userUpdate),
    fork(signUp),
    fork(signInVerificationSaga),
    fork(signInLoyatyUserSaga),
  ]);
}
