// @ts-nocheck
import { all, takeEvery, fork, call, put } from "redux-saga/effects";
import { message } from "antd";
import {
  removeLocalStorageItem,
  setLocalStorageItem,
} from "utils/localStorage";
import PaymentService from "../../services/PaymentService";
import {
  setPaymentDetails,
  setActiveSubscription,
  setCountries,
  setBillingAddress,
  setTransactions,
} from "../actions/Payment";
import { getCurrentUserData } from "../actions/Auth";
import {
  ADD_PAYMENT_METHOD,
  GET_PAYMENT_DETAILS,
  SUBSCRIBE,
  GET_COUNTRIES,
  UPDATE_BILLING_ADDRESS,
  CHANGE_CREDIT_CARD,
  GET_ALL_TRANSACTIONS,
} from "./../constants/Payment";
import { getErrorMessage } from "./../../helpers/getErrorMessage";

export function* addPaymentMethod() {
  yield takeEvery(
    ADD_PAYMENT_METHOD,
    function* ({ payload, messageSuccess, setLoaderActive }) {
      try {
        const { data } = yield call(PaymentService.addPaymentMethod, payload);
        yield put(setPaymentDetails(data));
        message.success(messageSuccess, 3);
        yield put(getCurrentUserData());
      } catch (err) {
        setLoaderActive(false);
        console.log(err);
        message.error(
          "Card information - " + getErrorMessage(err.response.data),
          3
        );
      }
    }
  );
}

export function* getPaymentDetails() {
  yield takeEvery(GET_PAYMENT_DETAILS, function* () {
    try {
      const { data } = yield call(PaymentService.getPaymentDetails);
      yield put(setPaymentDetails(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* subscribe() {
  yield takeEvery(
    SUBSCRIBE,
    function* ({ payload, messageSuccess, handleSubscriptionFinish }) {
      try {
        const { data } = yield call(PaymentService.subscribe, payload);
        yield put(setActiveSubscription(data));
        handleSubscriptionFinish();
        yield put(getCurrentUserData());
        message.success(messageSuccess, 3);
        removeLocalStorageItem("activeSubscriptionNotExists");
      } catch (err) {
        console.log(err);
        handleSubscriptionFinish();
        message.error(
          "Subscription - " + getErrorMessage(err.response.data),
          3
        );
      }
    }
  );
}

export function* getCountries() {
  yield takeEvery(GET_COUNTRIES, function* () {
    try {
      const { data } = yield call(PaymentService.getCountries);
      yield put(setCountries(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* updateBillingAddress() {
  yield takeEvery(
    UPDATE_BILLING_ADDRESS,
    function* ({ payload, messageSuccess, handleUpdateFinish }) {
      try {
        const { data } = yield call(
          PaymentService.updateBillingAddress,
          payload
        );
        yield put(setBillingAddress(data));
        message.success(messageSuccess, 3);
        handleUpdateFinish();
      } catch (err) {
        console.log(err);
        handleUpdateFinish();
        message.error(
          "Billing address - " + getErrorMessage(err.response.data),
          3
        );
      }
    }
  );
}

export function* updateCreditCard() {
  yield takeEvery(
    CHANGE_CREDIT_CARD,
    function* ({ payload, messageSuccess, handleChangeFinish }) {
      try {
        const { data } = yield call(PaymentService.changeCreditCard, payload);
        yield put(setPaymentDetails(data));
        message.success(messageSuccess, 3);
        setLocalStorageItem(
          "cardNotValid",
          !data.payment_information?.payment_method?.is_valid
        );
        handleChangeFinish();
      } catch (err) {
        console.log(err);
        handleChangeFinish();
        message.error(
          "Card information - " + getErrorMessage(err.response.data),
          3
        );
      }
    }
  );
}

export function* getTransactions() {
  yield takeEvery(GET_ALL_TRANSACTIONS, function* ({ payload }) {
    try {
      const { data } = yield call(
        PaymentService.getTransactions,
        payload.page,
        payload.perPage
      );
      yield put(setTransactions(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addPaymentMethod),
    fork(getPaymentDetails),
    fork(subscribe),
    fork(getCountries),
    fork(updateBillingAddress),
    fork(updateCreditCard),
    fork(getTransactions),
  ]);
}
