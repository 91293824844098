import produce from "immer";
import {
  SET_ALL_DOMAINS,
  SET_ALL_LANDING_PAGES,
  UPDATE_DOMAIN_LIST,
  SET_LANDING_PAGE,
  UPDATE_LANDING_PAGE_INFO,
  SET_ALL_STEPS_BY_PAGE,
  SET_STEP,
  REMOVE_STEP_FROM_LIST,
  UPDATE_STEP_LIST,
  PUSH_STEP_TO_LIST,
  SET_LANDING_PAGE_TEMPLATES,
  UPDATE_STEP_CHAT_WIDGET,
  SET_LANDING_PAGE_REQUEST,
} from "redux/constants/WebPage";

export const initialState = {
  domainsList: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
  },
  landingPageList: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
  },
  landingPageTemplatesList: [],
  landingPage: null,
  stepsByPageList: {},
  step: null,
  landingPageContentRequest: {},
};

const webPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_ALL_DOMAINS:
        draft.domainsList = {
          count: payload?.data?.count,
          current: payload?.data?.current,
          next: payload?.data?.next,
          previous: payload?.data?.previous,
          total_pages: payload?.data?.total_pages,
          results: payload?.data?.results,
          isLoading: false,
        };
        break;
      case UPDATE_DOMAIN_LIST:
        let indexChange = draft.domainsList.results.findIndex(
          (el) => el.id === payload.id
        );
        draft.domainsList.results[indexChange] = payload;
        break;
      case SET_ALL_LANDING_PAGES:
        draft.landingPageList = {
          count: payload?.data?.count,
          current: payload?.data?.current,
          next: payload?.data?.next,
          previous: payload?.data?.previous,
          total_pages: payload?.data?.total_pages,
          results: payload?.data?.results,
          isLoading: false,
        };
        break;
      case SET_LANDING_PAGE:
        draft.landingPage = payload;
        break;
      case UPDATE_LANDING_PAGE_INFO:
        if (draft.landingPageList?.results.length > 0) {
          let index = draft.landingPageList?.results?.findIndex(
            (el) => el.id === payload.id
          );
          draft.landingPageList.results[index] = payload;
        } else {
          draft.landingPage = payload;
        }
        break;
      case SET_STEP:
        draft.step = payload;
        break;
      case SET_ALL_STEPS_BY_PAGE:
        draft.stepsByPageList = payload;
        break;
      case REMOVE_STEP_FROM_LIST:
        let newList = draft.stepsByPageList.filter((el) => el.id !== payload);

        draft.stepsByPageList = newList;
        break;
      case UPDATE_STEP_LIST:
        if (draft.stepsByPageList?.length > 0) {
          let index = draft.stepsByPageList?.findIndex(
            (el) => el.id === payload.id
          );
          draft.stepsByPageList[index] = payload;
        } else {
          draft.stepsByPageList = payload;
        }
        break;
      case PUSH_STEP_TO_LIST:
        draft.stepsByPageList.push(payload);
        break;
      case SET_LANDING_PAGE_TEMPLATES:
        draft.landingPageTemplatesList = payload.results;
        break;
      case UPDATE_STEP_CHAT_WIDGET:
        draft.stepsByPageList.forEach(
          (step) => (step.enabled_chat_widget = payload)
        );
        break;
      case SET_LANDING_PAGE_REQUEST:
        draft.landingPageContentRequest = payload;
        break;
      default:
        return state;
    }
  });
export default webPageReducer;
