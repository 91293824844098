import { stringify } from "querystring";
import ApiService from "./ApiService";
import { ORDERING } from "constants/ApiConstant";
import {
  DEFAULT_ORDERING,
  DEFAULT_ORDERING_LOYALTY_USERS,
  DEFAULT_ORDER_FIELD,
  DEFAULT_ORDER_FIELD_LOYALTY_USERS,
} from "constants/LoyaltyUsersConstants";

const ENDPOINTS = {
  LIST_CONTACTS_FOR_LOYALTY: "loyalty/contacts/available/",
  CREATE_LOYALTY_USER: "user/loyalty/",
  LIST_CONTACTS_IN_LOYALTY_APP: "loyalty/contacts/",
};

class LoyaltyUsersService extends ApiService {
  getAvailableContacts = async (page, perPage, search, order = "", orderField = "") => {
    const queryStringParams = {
      page,
      per_page: perPage,
      search: search,
      ordering: `${order === ORDERING.DESC ? "-" : ""}${orderField},${
        orderField !== DEFAULT_ORDER_FIELD ? DEFAULT_ORDERING : ""
      }`,
    };
    return await this.apiClient.get(`${ENDPOINTS.LIST_CONTACTS_FOR_LOYALTY}?${stringify(queryStringParams)}`);
  };

  createLoyaltyUser = async (data) => {
    return await this.apiClient.post(ENDPOINTS.CREATE_LOYALTY_USER, data);
  };

  getContactsInLoyaltyApp = async (page, perPage, search, order = "", orderField = "") => {
    const queryStringParams = {
      page,
      per_page: perPage,
      search: search,
      ordering: `${order === ORDERING.DESC ? "-" : ""}${orderField},${
        orderField !== DEFAULT_ORDER_FIELD_LOYALTY_USERS ? DEFAULT_ORDERING_LOYALTY_USERS : ""
      }`,
    };
    return await this.apiClient.get(
      `${ENDPOINTS.LIST_CONTACTS_IN_LOYALTY_APP}?${stringify(queryStringParams)}`
    );
  };
}

export default new LoyaltyUsersService();
