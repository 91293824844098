import { message } from "antd";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { getAllPipelines, setAllPipelines, setPipeline } from "redux/actions/Pipelines";
import { CREATE_PIPELINE, DELETE_PIPELINE, GET_ALL_PIPELINES, GET_SALES_PIPELINE, GET_SINGLE_PIPELINE, UPDATE_PIPELINE } from "redux/constants/Pipeline";
import PipelineService from "services/PipelineService";

export function* getAllPipelinesSaga() {
  yield takeEvery(GET_ALL_PIPELINES, function* ({ payload }) {
    try {
      const results = yield call(PipelineService.getAllPipelines, payload);
      yield put(setAllPipelines(results));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getSalesPipelineSaga() {
  yield takeEvery(GET_SALES_PIPELINE, function* () {
    try {
      const results = yield call(PipelineService.getSalesPipeline);
      yield put(setPipeline(results));
    } catch (err) {
      console.log(err);
    }
  })
}

export function* getSinglePipelineSaga() {
  yield takeEvery(GET_SINGLE_PIPELINE, function* ({ payload }) {
    try {
      const results = yield call(PipelineService.getSinglePipeline, payload);
      yield put(setPipeline(results.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* createPipelineSaga() {
    yield takeEvery(CREATE_PIPELINE, function* ({ payload, initData, successMessage }) {
      try {
        yield call(PipelineService.createPipeline, payload);
        yield put(getAllPipelines(initData));
        message.success(successMessage, 3);
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
            message.error(err.response.data.non_field_errors[0], 3);
          }
      }
    });
  }

export function* deletePipelineSaga() {
    yield takeEvery(DELETE_PIPELINE, function* ({ payload, initData, successMessage }) {
      try {
        yield call(PipelineService.deletePipeline, payload);
        yield put(getAllPipelines(initData));
        message.success(successMessage, 3)
      } catch (err) {
        console.log(err);
      }
    });
  }

export function* updatePipelineSaga() {
  yield takeEvery(UPDATE_PIPELINE, function* ({id, payload, initData, successMessage}) {
    try {
      yield call (PipelineService.updatePipeline, id, payload);
      yield put(getAllPipelines(initData));
      message.success(successMessage, 3)
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getAllPipelinesSaga),
    fork(createPipelineSaga),
    fork(deletePipelineSaga),
    fork(updatePipelineSaga),
    fork(getSinglePipelineSaga),
    fork(getSalesPipelineSaga),
  ]);
}
