/* eslint-disable default-case */
import produce from "immer";
import {
  SET_AVAILABLE_CONTACTS_FOR_LOYALTY_APP,
  SET_AVAILABLE_CONTACTS_ORDER,
  SET_CONTACTS_IN_LOYALTY_APP_ORDER,
  SET_LOYALTY_APP_CONTACTS,
} from "redux/constants/LoyaltyUsers";

export const initialState = {
  availableContacts: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
    order: "descend",
    orderField: "created_at",
  },
  loyaltyContacts: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
    order: "descend",
    orderField: "created_at",
  },
};

const loyaltyUsersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_AVAILABLE_CONTACTS_FOR_LOYALTY_APP:
        draft.availableContacts = {
          count: payload.data.count,
          current: payload.data.current,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results: payload.data.results,
          isLoading: false,
          order: payload.order,
          orderField: payload.orderField,
        };
        break;
      case SET_AVAILABLE_CONTACTS_ORDER:
        draft.availableContacts.order = payload.order;
        draft.availableContacts.orderField = payload.orderField;
        break;
      case SET_LOYALTY_APP_CONTACTS:
        draft.loyaltyContacts = {
          count: payload.data.count,
          current: payload.data.current,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results: payload.data.results,
          isLoading: false,
          order: payload.order,
          orderField: payload.orderField,
        };
        break;
      case SET_CONTACTS_IN_LOYALTY_APP_ORDER:
        draft.loyaltyContacts.order = payload.order;
        draft.loyaltyContacts.orderField = payload.orderField;
        break;
    }
  });

export default loyaltyUsersReducer;
