import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  GET_PIPELINES: "pipelines/?page=%s",
  CREATE_PIPELINE: "pipelines/",
  DELETE_PIPELINE: "pipelines/%s",
  GET_SINGLE_PIPELINE: "pipelines/%s/",
  UPDATE_PIPELINE: "pipelines/%s/",
  SALES_PIPELINE: "pipelines/sales_pipeline/"
};

class PipelineService extends ApiService {
  getAllPipelines = async ( page ) => {
    return await this.apiClient.get(format(ENDPOINTS.GET_PIPELINES, page));
  };

  createPipeline = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.CREATE_PIPELINE, payload);
  };

  deletePipeline = async (payload) => {
    return await this.apiClient.delete(format(ENDPOINTS.DELETE_PIPELINE, payload));
  };

  getSinglePipeline = async (payload) => {
    return await this.apiClient.get(format(ENDPOINTS.GET_SINGLE_PIPELINE, payload));
  };

  updatePipeline = async (id, payload) => {
    return await this.apiClient.put(format(ENDPOINTS.UPDATE_PIPELINE, id), payload);
  }

  getSalesPipeline = async () => {
    return await this.apiClient.get(ENDPOINTS.SALES_PIPELINE);
  };
}

export default new PipelineService();
