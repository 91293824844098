// @ts-nocheck
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import {
  GET_FUNNELS,
  CREATE_FUNNEL,
  DELETE_FUNNEL,
  EDIT_FUNNEL,
  GET_FUNNEL_CONTACTS,
  REORDER_FUNNELS,
  REORDER_CONTACTS,
  GET_REACTIVATION_FUNNELS,
} from "../constants/Funnel";
import {
  updateFunnels,
  setFunnels,
  setFunnelContacts,
  updateNameFunnel,
  setOrderContacts,
} from "../actions/Funnel";
import { updateContactFunnel } from "../actions/Contact";
import FunnelService from "services/FunnelService";
import { ORDER_CONTACT } from "../../constants/ContactConstant";

export function* getFunnelsSaga() {
  yield takeEvery(GET_FUNNELS, function* ({ pipelineId }) {
    try {
      const funnels = yield call(FunnelService.getFunnels, pipelineId);
      yield put(setFunnels({ data: funnels.data, type: "initial" }));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* createFunnel() {
  yield takeEvery(CREATE_FUNNEL, function* ({ payload, callback }) {
    try {
      const funnel = yield call(FunnelService.createFunnel, payload);
      yield put(updateFunnels({ data: funnel.data, action: "add" }));
      callback();
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data["non_field_errors"], 3);
      }
    }
  });
}

export function* deleteFunnel() {
  yield takeEvery(DELETE_FUNNEL, function* ({ payload }) {
    try {
      yield call(FunnelService.deleteFunnel, payload);
      yield put(updateFunnels({ action: "delete", id: payload }));
      yield put(updateContactFunnel(payload));
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data["non_field_errors"], 3);
      }
    }
  });
}

export function* editFunnel() {
  yield takeEvery(EDIT_FUNNEL, function* ({ payload, callback }) {
    try {
      const funnel = yield call(FunnelService.editFunnel, payload);
      yield put(updateNameFunnel(funnel.data));
      callback();
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data["non_field_errors"], 3);
      }
    }
  });
}

export function* getFunnelContactsSaga() {
  yield takeEvery(GET_FUNNEL_CONTACTS, function* ({ payload }) {
    try {
      const contacts = yield call(
        FunnelService.getFunnelContacts,
        payload.page,
        payload.perPage,
        payload.id,
        payload.search
      );
      const data = { id: payload.id, data: contacts.data };
      yield put(setFunnelContacts(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* funnelsReorder() {
  yield takeEvery(REORDER_FUNNELS, function* ({ payload }) {
    try {
      yield call(FunnelService.reorderFunnels, payload);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* reorderContactsFunnels() {
  yield takeEvery(REORDER_CONTACTS, function* ({ payload }) {
    try {
      if (payload.funnel_id_source === payload.funnel_id_destination) {
        yield put(setOrderContacts(payload));
      } else {
        payload.type = ORDER_CONTACT.DELETE_AND_ADD;
        yield put(setOrderContacts(payload));
      }
      yield call(FunnelService.reorderContacts, payload);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getReactivationFunnelsSaga() {
  yield takeEvery(GET_REACTIVATION_FUNNELS, function* () {
    try {
      const funnels = yield call(FunnelService.getReactivationFunnels);
      yield put(setFunnels({ data: funnels.data, type: "initial" }));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getFunnelsSaga),
    fork(createFunnel),
    fork(deleteFunnel),
    fork(editFunnel),
    fork(getFunnelContactsSaga),
    fork(funnelsReorder),
    fork(reorderContactsFunnels),
    fork(getReactivationFunnelsSaga)
  ]);
}
