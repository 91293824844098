export const CREATE_AWARD = "CREATE_AWARD";
export const GET_AWARDS = "GET_AWARDS";
export const SET_AWARDS = "SET_AWARDS";
export const GET_SINGLE_AWARD = "GET_SINGLE_AWARD";
export const SET_SINGLE_AWARD = "SET_SINGLE_AWARD";
export const EDIT_AWARD = "EDIT_AWARD";
export const ADD_AWARD_TO_USER = "ADD_AWARD_TO_USER";
export const GET_USER_LIST = "GET_USER_LIST";
export const SET_AWARD_USER_LIST = "SET_AWARD_USER_LIST";
export const ADD_USER_TO_LATEST = "ADD_USER_TO_LATEST";
export const GET_SINGLE_USER_AWARDS = "GET_SINGLE_USER_AWARDS";
export const SET_SINGLE_USER_AWARDS = "SET_SINGLE_USER_AWARDS";
export const ADD_POINTS = "ADD_POINTS";
export const SET_NEW_POINTS = "SET_NEW_POINTS";
export const REDEEM_AWARD = "REDEEM_AWARD";
export const SET_REDEEMED_AWARD = "SET_REDEEMED_AWARD";
export const DELETE_AWARD = "DELETE_AWARD";
export const ARCHIVE_AWARD = "ARCHIVE_AWARD";
export const GET_ARCHIVED_AWARDS = "GET_ARCHIVED_AWARDS";
export const SET_ARCHIVED_AWARDS = "SET_ARCHIVED_AWARDS";
export const ACTIVATE_AWARD = "ACTIVATE_AWARD";
export const SET_SINGLE_ARHIVED_AWARD = "SET_SINGLE_ARHIVED_AWARD";
export const CHANGE_STATUS_ARHIVED_AWARD = "CHANGE_STATUS_ARHIVED_AWARD";
export const GET_SINGLE_ARHIVED_AWARD = "GET_SINGLE_ARHIVED_AWARD";
export const GET_AWARDS_ACTIVE_POINTS = "GET_AWARDS_ACTIVE_POINTS";
export const REENROLL_TO_AWARD = "REENROLL_TO_AWARD";
export const GET_AVAILABLE_AWARDS = "GET_AVAILABLE_AWARDS"
export const SET_AVAILABLE_AWARDS = "SET_AVAILABLE_AWARDS"