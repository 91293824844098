import ApiService from "./ApiService";
import { stringify } from "querystring";
import { format } from "util";

const ENDPOINTS = {
  TREATMENTS: "treatments?&type=%s&page=%s&organization=%s&area=%s",
  CREATE_TREATMENTS: "treatments/",
  CUSTOM_CREATE: "treatments/custom_create/",
  INCLUDE_TREATMENT: "treatments/include_treatment/",
  EDIT_TREATMENTS: "treatments/%s/",
  PATCH_TREATMENTS: "treatments/%s/",
  DELETE_TREATMENT: "treatments/%s/",
  GET_TREATMENTS_PAGE: "treatments/?page=%s",
  TREATMENT_IMAGES: "treament_images/?treatment=%s",
  OFFER_REQUEST: "virtual-consult/offer/",
  BUILT_IN_TREATMENTS: "builtin_treatments/",
  CONTACT_TREATMENTS: "contacts/%s/treatments/",
  TREATMENT_AREAS: "treatment-areas/?organization_id=%s",
  UPDATE_CONTACT_TREATMENT: "contacts/treatments/%s/",
  CREATE_CONTACT_TREATMENT: "contacts/treatments/",
  DELETE_CONTACT_TREATMENT: "contacts/treatments/%s/",
  PATCH_CONTACT_TREATMENT: "contacts/treatments/%s/",
};
class TreatmentService extends ApiService {
  createTreatment = (data) => {
    return this.apiClient.post(ENDPOINTS.CREATE_TREATMENTS, data);
  };

  getTreatments = (payload) => {
    return this.apiClient.get(
      format(
        ENDPOINTS.TREATMENTS,
        payload?.type,
        payload?.page || 1,
        payload?.organization || "",
        payload?.area || ""
      )
    );
  };

  getTretmentsPerPage = (page) => {
    return this.apiClient.get(format(ENDPOINTS.GET_TREATMENTS_PAGE, page));
  };

  editTreatment = (payload) => {
    const { treatmentId } = payload;
    return this.apiClient.put(
      format(ENDPOINTS.EDIT_TREATMENTS, treatmentId),
      payload
    );
  };

  deleteTreatment = (payload) => {
    const { treatmentId } = payload;
    return this.apiClient.delete(
      format(ENDPOINTS.DELETE_TREATMENT, treatmentId),
      payload
    );
  };

  includeTreatment = (data) => {
    return this.apiClient.post(ENDPOINTS.INCLUDE_TREATMENT, data);
  };

  createCustomTreatment = (data) => {
    return this.apiClient.post(ENDPOINTS.CUSTOM_CREATE, data);
  };

  getTreatmentImages = (treatmentId) => {
    return this.apiClient.get(format(ENDPOINTS.TREATMENT_IMAGES, treatmentId));
  };

  requestOffer = (data) => {
    return this.apiClient.post(ENDPOINTS.OFFER_REQUEST, data);
  };

  getBuiltInTreatments = (data) => {
    return this.apiClient.get(
      `${ENDPOINTS.BUILT_IN_TREATMENTS}?${stringify(data)}`
    );
  };

  getContactTreatments = (data, contactId) => {
    return this.apiClient.get(
      `${format(ENDPOINTS.CONTACT_TREATMENTS, contactId)}?${stringify(data)}`
    );
  };

  getTreatmentAreas = (organizationId) => {
    return this.apiClient.get(
      format(ENDPOINTS.TREATMENT_AREAS, organizationId)
    );
  };

  updateContactTreatments = (payload) => {
    const { id, data } = payload;
    return this.apiClient.put(
      format(ENDPOINTS.UPDATE_CONTACT_TREATMENT, id),
      data
    );
  };

  createContactTreatment = (payload) => {
    return this.apiClient.post(
      format(ENDPOINTS.CREATE_CONTACT_TREATMENT),
      payload
    );
  };

  deleteContactTreatment = (payload) => {
    return this.apiClient.delete(
      format(ENDPOINTS.DELETE_CONTACT_TREATMENT, payload)
    );
  };

  patchContactTreatment = (payload) => {
    const { id, data } = payload;
    return this.apiClient.patch(
      format(ENDPOINTS.PATCH_CONTACT_TREATMENT, id),
      data
    );
  };
}

export default new TreatmentService();
