import { LANDING_PAGE_DOCTOR_PERFORMER,  LANDING_PAGE_PRACTICE_PERFORMER} from 'constants/LandingPageConstants';

class LandingPageEditStateHelper {

     isCreatedWithBuilder = (page) => {
        if (!!page?.length){
            return page[0]?.landing_page?.created_via_builder;
        }
        return page?.created_via_builder;
    }

     setEditLandingPageData = (page) => {
        
        let showInput = !!page?.landing_page_content?.treatment?.organization;
        let files = page?.landing_page_content?.before_and_after_images.map((item) => ({...item, url: item.image, uid: item.id}));
        const data = {
            initModal: {
                name: page?.name, 
                showInput: showInput,
                selectName: showInput ? -1 : page?.landing_page_content?.treatment?.id, 
                inputName: showInput ? page?.landing_page_content?.treatment?.name : null,
                radioGroup: page?.landing_page_content?.doctor?.id ? LANDING_PAGE_DOCTOR_PERFORMER : LANDING_PAGE_PRACTICE_PERFORMER,
                showAddressField: !!page?.landing_page_content?.doctor,
                showDoctorSelect: !!page?.landing_page_content?.doctor?.id,
                doctor: page?.landing_page_content?.doctor,
                address: page?.landing_page_content?.address?.id,
                discount_amount: page?.landing_page_content?.discount?.value,
                discount_type: page?.landing_page_content?.discount?.discount_type,
            },
            comments: page?.landing_page_content?.comments,
            form: page?.form?.id,
            template_name: page?.template,
            images: {
                radioGroup: !!page?.landing_page_content?.before_and_after_images?.length,
                imageTitle_0: page?.landing_page_content?.before_and_after_images[0]?.title,
                imageTitle_1: page?.landing_page_content?.before_and_after_images[1]?.title,
                imageTitle_2: page?.landing_page_content?.before_and_after_images[2]?.title,
                description_0: page?.landing_page_content?.before_and_after_images[0]?.description,
                description_1: page?.landing_page_content?.before_and_after_images[1]?.description,
                description_2: page?.landing_page_content?.before_and_after_images[2]?.description,
                files: files,
            },
            id: page?.landing_page_content?.id
        }
        return data;
        
    }

}

const landingPageEditStateHelper = new LandingPageEditStateHelper();
export default landingPageEditStateHelper;