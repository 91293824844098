import produce from "immer";
import {
  SET_ALL_SMS_TEMPLATES,
  UPDATE_SMS_TEMPLATE_LIST,
  SET_SELECTED_SMS_TEMPLATE,
} from "redux/constants/SmsBuilder";

export const initialState = {
  templateList: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
  },
  newSmsTemplate: null,
};

const smsBuilderReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_ALL_SMS_TEMPLATES:
        draft.templateList = {
          count: payload?.data?.count,
          current: payload?.data?.current,
          next: payload?.data?.next,
          previous: payload?.data?.previous,
          total_pages: payload?.data?.total_pages,
          results: payload?.data?.results,
          isLoading: false,
        };
        break;
      case UPDATE_SMS_TEMPLATE_LIST:
        let indexChange = draft.templateList.results.findIndex(
          (el) => el.id === payload.id
        );
        draft.templateList.results[indexChange] = payload;
        break;
      case SET_SELECTED_SMS_TEMPLATE:
        draft.newSmsTemplate = payload;
        break;
      default:
        return state;
    }
  });
export default smsBuilderReducer;
