import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
    GET_NOTIFICATIONS: "/notifications/",
    SET_ALL_NOTIFICATIONS: "/notifications/toggle_config/",
    UPDATE_SINGLE_NOTIFICATION: "/notifications/%s/"
};

class NotificationService extends ApiService {
    getNotifications = () => {
        return this.apiClient.get(format(ENDPOINTS.GET_NOTIFICATIONS));
    };

    updateAllNotifications = (payload) => {
        return this.apiClient.put(format(ENDPOINTS.SET_ALL_NOTIFICATIONS), payload);
    }

    updateSingleNotification = (payload) => {
        return this.apiClient.put(format(ENDPOINTS.UPDATE_SINGLE_NOTIFICATION, payload.id), payload);
    }
}

export default new NotificationService();