/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import { NUMBER_PER_PAGE } from "constants/ContactConstant";
import produce from "immer";
import {
  DELETE_APPOINTMENT_SUCCESS,
  EDIT_APPOINTMENT_SUCCESS,
  GET_APPOINTMENTS_SUCCESS,
  NEW_APPOINTMENT_SUCCESS,
  SHOW_MESSAGE,
  GET_CALENDAR_SUCCESS,
  PREVIOUS_AND_NEXT_SUCCESS,
} from "../constants/Appointment";

export const initialState = {
  message: "",
  appointmentsList: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
  },
  appointments: {
    previous: null,
    next: null,
  },
  calendar: [],
};

const deleteAppointmentSuccess = (tempList, draft, payload, isCalendar) => {
  tempList = tempList.filter((x) => x.id !== payload);
  !isCalendar && (draft.appointmentsList.results = tempList);
  isCalendar && (draft.calendar = tempList);
};

const appointmentReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SHOW_MESSAGE:
        draft.message = payload
          ? payload.time_from || payload.time_to || payload.message
          : "";
        break;

      case EDIT_APPOINTMENT_SUCCESS:
        const appointmentIndex = draft.appointmentsList.results.findIndex(
          (appointment) => appointment?.id == payload?.id
        );
        draft.appointmentsList.results[appointmentIndex] = payload;
        break;

      case DELETE_APPOINTMENT_SUCCESS:
        deleteAppointmentSuccess(
          draft.appointmentsList.results,
          draft,
          payload,
          false
        );
        deleteAppointmentSuccess(draft.calendar, draft, payload, true);
        break;

      case NEW_APPOINTMENT_SUCCESS:
        draft.calendar.push(payload);
        if (draft.appointmentsList.results.length > 0) {
          (new Date(
            draft.appointmentsList.results[
              draft.appointmentsList.results.length - 1
            ].date
          ).getDate() >= new Date(payload.date).getDate() ||
            draft.appointmentsList.results.length < NUMBER_PER_PAGE) &&
            draft.appointmentsList.results.push(payload);
        } else {
          draft.appointmentsList.results.push(payload);
        }
        draft.appointmentsList.count += 1;
        break;

      case GET_APPOINTMENTS_SUCCESS:
        draft.appointmentsList = {
          count: payload.data.count,
          current: payload.data.current,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results:
            payload.data.current === 1
              ? payload.data.results
              : [...draft.appointmentsList.results, ...payload.data.results],
          isLoading: false,
        };
        break;

      case PREVIOUS_AND_NEXT_SUCCESS:
        draft.appointments.previous = payload.data.previous_appointment;
        draft.appointments.next = payload.data.next_appointment;
        break;

      case GET_CALENDAR_SUCCESS:
        draft.calendar = payload;
        break;
    }
  });

export default appointmentReducer;
