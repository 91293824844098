import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  GET_CONTACT_PICTURES: "contacts/%s/pictures/contact_pictures/?page=%s",
  DELETE_PICTURES: "pictures/delete/",
  UPLOAD_PICTURES: "pictures/",
};

class PicturesService extends ApiService {
  getContactPictures = async (contactId, page) => {
    return await this.apiClient.get(
      format(ENDPOINTS.GET_CONTACT_PICTURES, contactId, page)
    );
  };

  deletePictures = async (data) => {
    return await this.apiClient.post(ENDPOINTS.DELETE_PICTURES, data);
  };

  uploadPicture = async (payload) => {
    return await this.apiClient.post(ENDPOINTS.UPLOAD_PICTURES, payload, {
      headers: {
        "content-type": "multipart/form-data;",
      },
    });
  };
}

export default new PicturesService();
