import {
  ADD_PAYMENT_METHOD,
  GET_PAYMENT_DETAILS,
  SET_PAYMENT_DETAILS,
  SUBSCRIBE,
  SET_ACTIVE_SUBSCRIPTION,
  GET_COUNTRIES,
  SET_COUNTRIES,
  UPDATE_BILLING_ADDRESS,
  SET_BILLING_ADDRESS,
  CHANGE_CREDIT_CARD,
  GET_ALL_TRANSACTIONS,
  SET_ALL_TRANSACTIONS,
} from "../constants/Payment";

export const addPaymentMethod = (payload, messageSuccess, setLoaderActive) => ({
  type: ADD_PAYMENT_METHOD,
  payload,
  messageSuccess,
  setLoaderActive,
});

export const getPaymentDetails = () => ({
  type: GET_PAYMENT_DETAILS,
});

export const setPaymentDetails = (payload) => ({
  type: SET_PAYMENT_DETAILS,
  payload,
});

export const subscribe = (
  payload,
  messageSuccess,
  handleSubscriptionFinish
) => ({
  type: SUBSCRIBE,
  payload,
  messageSuccess,
  handleSubscriptionFinish,
});

export const setActiveSubscription = (payload) => ({
  type: SET_ACTIVE_SUBSCRIPTION,
  payload,
});

export const getCountries = () => ({
  type: GET_COUNTRIES,
});

export const setCountries = (payload) => ({
  type: SET_COUNTRIES,
  payload,
});

export const updateBillingAddress = (
  payload,
  messageSuccess,
  handleUpdateFinish
) => ({
  type: UPDATE_BILLING_ADDRESS,
  payload,
  messageSuccess,
  handleUpdateFinish,
});

export const setBillingAddress = (payload) => ({
  type: SET_BILLING_ADDRESS,
  payload,
});

export const changeCreditCard = (
  payload,
  messageSuccess,
  handleChangeFinish
) => ({
  type: CHANGE_CREDIT_CARD,
  payload,
  messageSuccess,
  handleChangeFinish,
});

export const getTransactions = (payload) => ({
  type: GET_ALL_TRANSACTIONS,
  payload,
});

export const setTransactions = (payload) => ({
  type: SET_ALL_TRANSACTIONS,
  payload,
});
