import {
  SET_ALL_TEMPLATES,
  UPDATE_TEMPLATE_LIST,
  PUSH_TEMPLATE_TO_LIST,
  SET_EMAIL_FORM_TEMPLATES,
  SET_TEMPLATE_BY_ID,
  SET_SINGLE_TEMPLATE_ID,
  SET_OVERALL_EMAIL_STATS,
  SET_EMAIL_STATS,
  SET_EMAIL_CONTACTS,
} from "redux/constants/HtmlBuilder";
import produce from "immer";

export const initialState = {
  templateList: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
  },
  emailFormTemplates: [],
  singleFormTemplate: null,
  lastCreatedTemplateId: null,
  emailAnalyticsOverall: null,
  emailStats: [],
  emailContacts: {},
};

const htmlBuilderReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_ALL_TEMPLATES:
        draft.templateList = {
          count: payload?.data?.count,
          current: payload?.data?.current,
          next: payload?.data?.next,
          previous: payload?.data?.previous,
          total_pages: payload?.data?.total_pages,
          results: payload?.data?.results,
          isLoading: false,
        };
        break;

      case UPDATE_TEMPLATE_LIST:
        let indexChange = draft.templateList.results.findIndex((el) => el.id === payload.id);
        draft.templateList.results[indexChange] = payload;
        break;
      case PUSH_TEMPLATE_TO_LIST:
        draft.templateList.results.push(payload.data);
        break;
      case SET_EMAIL_FORM_TEMPLATES:
        draft.emailFormTemplates = payload;
        break;
      case SET_TEMPLATE_BY_ID:
        draft.singleFormTemplate = payload;
        break;
      case SET_SINGLE_TEMPLATE_ID:
        draft.lastCreatedTemplateId = payload;
        break;
      case SET_OVERALL_EMAIL_STATS:
        draft.emailAnalyticsOverall = payload;
        break;
      case SET_EMAIL_STATS:
        let newData = [];
        if (payload?.current != 1) {
          newData = [...draft.emailStats?.results, ...payload?.results];
        } else {
          newData = payload?.results;
        }
        draft.emailStats = payload;
        draft.emailStats.results = newData;
        break;
      case SET_EMAIL_CONTACTS:
        const {data, analyticsId} = payload;
        draft.emailContacts[analyticsId] = data;
        break;
      default:
        return state;
    }
  });
export default htmlBuilderReducer;
