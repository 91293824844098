import ApiService from "./ApiService";
import { format } from "util";
import { stringify } from "querystring";

const ENDPOINTS = {
  UPDATE_ORGANIZATION: "organization/%s/",
  RETRIEVE_ORGANIZATION: "organization/%s/",
  POST_NEW_ORGANIZATION: "organization/",
  LIST_ORGANIZATION: "organization",
  POST_PRACTICE_INFO: "practice-info/",
  UPDATE_PRACTICE_INFO: "practice-info/%s/",
  UPDATE_FORWARDING_PHONE_NUMBER: "organization/forwarding_number/",
  GET_ORGANIZATION_SECURITY_SETTINGS: "settings/security/",
  UPDATE_ORGANIZATION_SECURITY_SETTINGS: "settings/security/%s/",
};

class OrganizationService extends ApiService {
  retrieveOrganization = ({ orgId }) => {
    return this.apiClient.get(format(ENDPOINTS.RETRIEVE_ORGANIZATION, orgId));
  };

  updateOrganization = async ({ payload, id }) => {
    const { data } = await this.apiClient.patch(
      format(ENDPOINTS.UPDATE_ORGANIZATION, id),
      payload
    );
    return data;
  };
  postNewOrganization = (organizationData) => {
    const data = this.apiClient.post(
      ENDPOINTS.POST_NEW_ORGANIZATION,
      organizationData
    );
    return data;
  };

  postPracticeInfo = (practiceInfo) => {
    const data = this.apiClient.post(
      ENDPOINTS.POST_PRACTICE_INFO,
      practiceInfo
    );
    return data;
  };

  getPracticeInfo = () => {
    const data = this.apiClient.get(ENDPOINTS.POST_PRACTICE_INFO);
    return data;
  };

  updatePracticeInfo = (payload, id) => {
    const data = this.apiClient.patch(
      format(ENDPOINTS.UPDATE_PRACTICE_INFO, id),
      payload
    );
    return data;
  };

  editForwardingPhoneNumber = async (payload) => {
    const data = await this.apiClient.patch(
      format(ENDPOINTS.UPDATE_FORWARDING_PHONE_NUMBER),
      payload
    );
    return data;
  };

  getOrganizationSecuritySettings = async () => {
    const data = await this.apiClient.get(
      ENDPOINTS.GET_ORGANIZATION_SECURITY_SETTINGS
    );
    return data;
  };

  updateSecuritySettings = (id, value) => {
    return this.apiClient.put(
      format(ENDPOINTS.UPDATE_ORGANIZATION_SECURITY_SETTINGS, id),
      value
    );
  };

  listOrganizations = (payload) => {
    return this.apiClient.get(
      `${ENDPOINTS.LIST_ORGANIZATION}?${stringify(payload)}`
    );
  };
}

export default new OrganizationService();
