import { SUPPORTING_NETWORKS } from "constants/SettingsConstants";

class BusinessSuggestionsStateHelper {

    generateInitialBussinessSuggestions = () => {
        let suggestion = {};
        SUPPORTING_NETWORKS.forEach((network, ind) => {
          suggestion[network] = [{ id: ind }];
        });
        return suggestion;
      };
      
    overrideBussinessSuggestions = (newData, oldData) => {
        const oldDataNetworks = Object.keys(oldData);
        const newDataNetworks = Object.keys(newData);
      
        newDataNetworks.forEach((networkKey) => {
          oldData[networkKey] = newData[networkKey];
        });
      
        const missingKeys = oldDataNetworks.filter((key) => newDataNetworks.indexOf(key) < 0);
      
        missingKeys.forEach((key) => {
          const newData = [{ id: -1 }];
          oldData[key] = newData;
        });
      
        return oldData;
      };
}

const bussinessSuggestionStateHelper = new BusinessSuggestionsStateHelper();
export default bussinessSuggestionStateHelper;