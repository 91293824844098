import produce from "immer";
import {
  RENAME_FORM_SUCCESS,
  RESET_SINGLE_FORM,
  SET_ALL_FORMS,
  SET_ALL_FORMS_NO_PAGINATION,
  SET_FORMS_ORDER,
  SET_FORMS_PAGE,
  SET_LOADING,
  SET_SINGLE_FORM,
} from "redux/constants/FormBuilder";

const initialState = {
  count: 0,
  currentPage: 1,
  next: null,
  previous: null,
  totalPages: 0,
  items: [],
  order: "",
  orderField: "",
  isLoading: true,
  allForms: [],
  selectedForm: {
    id: null,
    name: "",
    bodyContent: "",
    cssContent: "",
  },
};

const formBuilderReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_ALL_FORMS:
        draft.count = payload.count;
        draft.currentPage = payload.current;
        draft.next = payload.next;
        draft.previous = payload.previous;
        draft.totalPages = payload.total_pages;
        draft.items = payload.results;
        break;
      case SET_FORMS_PAGE:
        draft.currentPage = payload;
        break;
      case SET_FORMS_ORDER:
        draft.order = payload.order;
        draft.orderField = payload.orderField;
        break;
      case SET_LOADING:
        draft.isLoading = payload;
        break;
      case SET_SINGLE_FORM:
        draft.selectedForm = {
          id: payload.id,
          name: payload.name,
          bodyContent: payload.body_content,
          cssContent: payload.css_content,
          redirect_link: payload.redirect_link,
          campaign: payload?.campaign?.id,
          submit_message: payload?.submit_message,
        };
        break;
      case RESET_SINGLE_FORM:
        draft.selectedForm = {
          id: null,
          name: "",
          bodyContent: "",
          cssContent: "",
        };
        break;
      case SET_ALL_FORMS_NO_PAGINATION:
        draft.allForms = [{ id: null, name: "Select form" }, ...payload];
        break;
      case RENAME_FORM_SUCCESS:
        draft.items = state.items.map((form) =>
          form.id === payload.id ? { ...form, name: payload.name } : form
        );
        break;
      default:
        return state;
    }
  });
export default formBuilderReducer;
