import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Layout, Spin, Grid } from "antd";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import PageHeader from "components/layout-components/PageHeader";
import navigationConfig from "configs/AgencyNavigationConfig";
import AgencyViews from "views/agency-views";
import utils from "utils";
import {
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
} from "constants/ThemeConstant";

const HeaderNav = lazy(() => import("components/layout-components/HeaderNav"));

const AgencyLayout = ({ navCollapsed, navType, location }) => {
  const { status } = useThemeSwitcher();
  const { useBreakpoint } = Grid;

  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <Suspense fallback={<Spin />}>
      <Layout>
        <HeaderNav isMobile={isMobile} />
        {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-container">
          {isNavSide && !isMobile ? (
            <SideNav
              localization={false}
              routeInfo={currentRouteInfo}
              navigationConfig={navigationConfig}
            />
          ) : null}
          <Layout
            className="app-layout"
            style={{ paddingLeft: getLayoutGutter() }}
          >
            <div className={`app-content`}>
              <PageHeader
                display={currentRouteInfo?.breadcrumb}
                title={currentRouteInfo?.title}
              />
              <Layout.Content>
                <AgencyViews />
              </Layout.Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
    </Suspense>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AgencyLayout));
