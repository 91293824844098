import React, { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import {
  APP_LOYALTY_PREFIX_PATH,
  AUTH_LOYALTY_PREFIX_PATH,
} from "configs/AppConfig";
import Loading from "components/shared-components/Loading";
import PrivatePatientRoute from "shared/PrivatePatientRoute";

export const AppLoyaltyViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivatePatientRoute
          path={`${APP_LOYALTY_PREFIX_PATH}/home`}
          component={lazy(() =>
            import("../../app-views/AwardsPage/singleUserAwardsPage/index")
          )}
          exact
        />
        <PrivatePatientRoute
          path={`${APP_LOYALTY_PREFIX_PATH}/edit-profile`}
          component={lazy(() => import(`../../app-views/components/Profile`))}
          exact
        />
        <PrivatePatientRoute
          path={`${APP_LOYALTY_PREFIX_PATH}/edit-password`}
          component={lazy(() => import(`../../app-views/components/ChangePassword`))}
          exact
        />
        <Redirect from={`${APP_LOYALTY_PREFIX_PATH}`} to={`${AUTH_LOYALTY_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  );
};

export default AppLoyaltyViews;
