export const ROLE = {
  OWNER: "Owner",
  PATIENT: "Patient",
  EMPLPOYEE: "Employee",
  AGENCY_MANAGER: "Agency_Manager",
};

export const ROLE_MANDATORY_ORGANIZATION = [
  ROLE.OWNER,
  ROLE.EMPLPOYEE,
  ROLE.PATIENT,
];
