import { createSelector } from "reselect";
import reducers from "../reducers";

const selectUsersDomain = (state) => state.auth || reducers;

const makeSelectCurrentUser = () => createSelector(selectUsersDomain, (substate) => substate.currentUser);

const makeSelectGoogleReview = createSelector(
  selectUsersDomain,
  (substate) => substate.currentUser.organization.google_review_link
);

export { makeSelectCurrentUser, makeSelectGoogleReview };
