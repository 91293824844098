import websocketService from "services/WebsocketService";
import { WEBSOCKET_EVENT } from "constants/WebsocketConstants";

class NotificationAdapter {
  onCallStart(callback) {
    websocketService.listenAndExecuteEvent(
      WEBSOCKET_EVENT.INCOMING_CALL,
      callback
    );
  }
  onCallEnd(callback) {
    websocketService.listenAndExecuteEvent(WEBSOCKET_EVENT.CALL_END, callback);
  }
}

const notificationAdapter = new NotificationAdapter();
export default notificationAdapter;
