import ApiService from "./ApiService";

const ENDPOINTS = {
  CONTACT_SERVICE_RECOURSE: "contact-settings/",
};

class ContactSettingsService extends ApiService {
  getContactSettings = async () => {
    const { data } = await this.apiClient.get(
      ENDPOINTS.CONTACT_SERVICE_RECOURSE
    );
    return data;
  };

  setContactSettings = async (payload) => {
    const { data } = await this.apiClient.post(
      ENDPOINTS.CONTACT_SERVICE_RECOURSE,
      payload
    );
    return data;
  };

  deleteContactSettings = async (id) => {
    return this.apiClient.delete(ENDPOINTS.CONTACT_SERVICE_RECOURSE + id + "/");
  };
}

export default new ContactSettingsService();
