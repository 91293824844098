import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  POST_BRAND_DATA: "twilio/registration/brand/",
  GET_BRAND_DATA: "twilio/registration/brand/data/",
  UPDATE_BRAND_DATA: "twilio/registration/brand/%s/",
  SUBMIT_BRAND_DATA: "twilio/registration/brand/submit/",
  RESUBMIT_BRAND_DATA: "twilio/registration/brand/re-submit/",
};

class TwilioRegistrationService extends ApiService {
  postBrandRegistrationData = (data) => {
    return this.apiClient.post(ENDPOINTS.POST_BRAND_DATA, data);
  };

  getBrandRegistrationData = () => {
    return this.apiClient.get(ENDPOINTS.GET_BRAND_DATA);
  };

  updateBrandRegistrationData = (data, id) => {
    return this.apiClient.patch(format(ENDPOINTS.UPDATE_BRAND_DATA, id), data);
  };

  submitBrandRegistration = () => {
    return this.apiClient.post(ENDPOINTS.SUBMIT_BRAND_DATA);
  };

  resubmitBrandRegistration = () => {
    return this.apiClient.post(ENDPOINTS.RESUBMIT_BRAND_DATA);
  };
}

export default new TwilioRegistrationService();
