import {
  GET_EVENTS,
  SET_EVENTS,
  SHOW_MESSAGE,
  REORDER_EVENTS,
  POST_EVENT,
  PUSH_NEW_EVENT,
  DELETE_EVENT,
  REMOVE_EVENT,
  CHANGE_EVENT_DATA,
  UPDATE_EVENT,
  SET_LOADING,
  UNSHIFT_NEW_EVENT,
} from "../constants/Event";

export const getEvents = (payload) => ({
  type: GET_EVENTS,
  payload,
});

export const setEvents = (payload) => ({
  type: SET_EVENTS,
  payload,
});

export const showMessage = (payload) => ({
  type: SHOW_MESSAGE,
  payload,
});

export const reorderEvents = (payload, id) => ({
  type: REORDER_EVENTS,
  payload,
  id,
});

export const postNewEvent = (payload, id, resetModal, messageSuccess) => ({
  type: POST_EVENT,
  payload,
  id,
  resetModal,
  messageSuccess,
});

export const pushNewEventToList = (payload) => ({
  type: PUSH_NEW_EVENT,
  payload,
});

export const deleteEvent = (payload, id) => ({
  type: DELETE_EVENT,
  payload,
  id,
});

export const removeEvent = (payload) => ({
  type: REMOVE_EVENT,
  payload,
});

export const changeEventData = (
  payload,
  id,
  data,
  resetModal,
  messageSuccess
) => ({
  type: CHANGE_EVENT_DATA,
  payload,
  id,
  data,
  resetModal,
  messageSuccess,
});

export const updateEventList = (payload) => ({
  type: UPDATE_EVENT,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const unshiftNewEventToList = (payload) => ({
  type: UNSHIFT_NEW_EVENT,
  payload,
});
