import { useState, useEffect } from "react";

const useWindowSize = (breakpoint = 768) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobileResolution = width <= breakpoint;

  return { isMobileResolution };
};

export default useWindowSize;
