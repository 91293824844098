import { stringify } from "querystring";
import ApiService from "./ApiService";
import { format } from "util";
import { ORDERING } from "constants/ApiConstant";
import { setOrderField } from "helpers/setOrderField";
import {
  DEFAULT_ORDERING,
  DEFAULT_ORDER_FIELD,
} from "constants/ContactConstant";

const ENDPOINTS = {
  LIST_CONTACTS: "contacts/",
  SEARCH_CONTACTS: "contacts/?search=",
  DELETE_CONTACTS: "contacts/delete/",
  GET_CONTACT: "contacts/%s/",
  EDIT_CONTACT: "contacts/%s/",
  PARTIAL_UPDATE_CONTACT: "contacts/%s/",
  CHANGE_IMAGE_CONTACT: "/contacts/change_image/",
  TAGS: "/tags/",
  IMPORT_CONTACTS: "/contact/import-contacts/",
  DOWNLOAD_CONTACTS: "/contact/download-contacts/",
  REMOVE_FROM_FUNNEL: "contacts/%s/funnel_remove/",
  ALL_CONTACTS: "allcontacts/",
  UNSUBSCRIBE_CONTACT: "contacts/contact_unsubscribed/",
  ADD_CONTACTS_TO_CAMPAIGN: "campaign/%s/contacts/",
  FILTER_CONTACTS: "contacts/filter/",
  CREATE_INSURANCE: "contacts/%s/insurance/",
  UPDATE_INSURANCE: "insurance/%s/",
  DELETE_INSURANCE: "insurance/%s/",
  CREATE_CONTACT_ADDRESS: "contacts/%s/address/",
  UPDATE_CONTACT_ADDRESS: "address/%s/",
  UPDATE_CONTACT_CARD: "contact-card/%s/",
  EXTERNAL_PLATFORM: "contacts/%s/external-platform/",
  ADD_TAGS_IN_BULK: "contacts/add_tags/",
  REMOVE_TAGS_IN_BULK: "contacts/remove_tags/",
  GET_MERGE_CONTACTS: "contacts/merge/data",
  MERGE_DATA: "contacts/merge/",
  GET_ALL_CONTACTS: "all/contacts/",
  SEND_BULK_EMAILS: "/contacts/mass-marketing/bulk-emails/",
  SEND_BULK_SMS: "/contacts/mass-marketing/bulk-sms/",
  GET_CONTACT_TRANSACTIONS: "/contacts/%s/transactions/",
};

class ContactService extends ApiService {
  getContacts = async (
    page,
    perPage,
    search,
    campaign,
    order = "",
    orderField = ""
  ) => {
    orderField = setOrderField(orderField);
    const queryStringParams = {
      page,
      per_page: perPage,
      search: search,
      ordering: `${order === ORDERING.DESC ? "-" : ""}${orderField},${
        orderField !== DEFAULT_ORDER_FIELD ? DEFAULT_ORDERING : ""
      }`,
    };
    if (campaign) queryStringParams["campaign"] = campaign;
    return await this.apiClient.get(
      `${ENDPOINTS.LIST_CONTACTS}?${stringify(queryStringParams)}`
    );
  };

  getAllContacts = async () => {
    return this.apiClient.get(ENDPOINTS.ALL_CONTACTS);
  };

  deleteContacts = async (values) => {
    const queryStringParams = {
      search: values?.search,
    };
    return this.apiClient.post(
      `${ENDPOINTS.DELETE_CONTACTS}?${stringify(queryStringParams)}`,
      values
    );
  };

  getContact = async (value) => {
    return this.apiClient.get(format(ENDPOINTS.GET_CONTACT, value));
  };

  editContact = async (id, data) => {
    return this.apiClient.put(format(ENDPOINTS.EDIT_CONTACT, id), data);
  };

  changeImage = async (payload) => {
    const { data } = await this.apiClient.post(
      ENDPOINTS.CHANGE_IMAGE_CONTACT,
      payload,
      {
        headers: {
          "content-type": "multipart/form-data;",
        },
      }
    );
    return data;
  };

  storeContacts = async (formData) => {
    return this.apiClient.post(ENDPOINTS.LIST_CONTACTS, formData);
  };

  createInsurance = async (contactId, data) => {
    return this.apiClient.post(
      format(ENDPOINTS.CREATE_INSURANCE, contactId),
      data
    );
  };

  updateInsurance = async (id, data) => {
    return this.apiClient.put(format(ENDPOINTS.UPDATE_INSURANCE, id), data);
  };

  deleteInsurance = async (id, data) => {
    return this.apiClient.delete(format(ENDPOINTS.DELETE_INSURANCE, id), data);
  };

  createContactAddress = async (conactId, data) => {
    return this.apiClient.post(
      format(ENDPOINTS.CREATE_CONTACT_ADDRESS, conactId),
      data
    );
  };

  updateContactAddress = async (id, data) => {
    return this.apiClient.put(
      format(ENDPOINTS.UPDATE_CONTACT_ADDRESS, id),
      data
    );
  };

  updateContactCard = async (id, data) => {
    return this.apiClient.put(format(ENDPOINTS.UPDATE_CONTACT_CARD, id), data);
  };

  updateExternalPlatform = async (id, data) => {
    return this.apiClient.post(format(ENDPOINTS.EXTERNAL_PLATFORM, id), data);
  };

  getTags = async () => {
    return this.apiClient.get(format(ENDPOINTS.TAGS));
  };

  importContacts = async (payload) => {
    const { data } = await this.apiClient.post(
      ENDPOINTS.IMPORT_CONTACTS,
      payload
    );
    return data;
  };

  importContactsResults = () => {
    return this.apiClient.get(ENDPOINTS.IMPORT_CONTACTS);
  };

  removeFromFunnel = async (id, status) => {
    return this.apiClient.post(format(ENDPOINTS.REMOVE_FROM_FUNNEL, id), {
      status,
    });
  };

  unsubscribeContact = (data) => {
    return this.apiClient.post(ENDPOINTS.UNSUBSCRIBE_CONTACT, data);
  };

  partialUpdateContact = (payload) => {
    const { amount_spent, id } = payload;
    return this.apiClient.patch(format(ENDPOINTS.PARTIAL_UPDATE_CONTACT, id), {
      amount_spent,
    });
  };

  addContactsToCampaign = (values, campaignId) => {
    const queryStringParams = {
      search: values?.search,
    };

    return this.apiClient.post(
      `${format(ENDPOINTS.ADD_CONTACTS_TO_CAMPAIGN, campaignId)}?${stringify(
        queryStringParams
      )}`,
      values
    );
  };

  filterContacts = (
    page,
    perPage,
    search,
    campaign,
    order = "",
    orderField = "",
    data
  ) => {
    orderField = setOrderField(orderField);
    const queryStringParams = {
      page,
      per_page: perPage,
      search: search,
      ordering: `${order === ORDERING.DESC ? "-" : ""}${orderField},${
        orderField !== DEFAULT_ORDER_FIELD ? DEFAULT_ORDERING : ""
      }`,
    };
    if (campaign) queryStringParams["campaign"] = campaign;
    return this.apiClient.get(
      `${ENDPOINTS.FILTER_CONTACTS}?${stringify(queryStringParams)}`,
      { params: { data: data } }
    );
  };

  downloadContacts = () => {
    return this.apiClient.get(ENDPOINTS.DOWNLOAD_CONTACTS);
  };

  addTagsInBulk = (data) => {
    const queryStringParams = {
      search: data?.orderingInfo?.search,
    };
    return this.apiClient.post(
      `${ENDPOINTS.ADD_TAGS_IN_BULK}?${stringify(queryStringParams)}`,
      data
    );
  };

  removeTagsInBulk = (data) => {
    const queryStringParams = {
      search: data?.orderingInfo?.search,
    };
    return this.apiClient.post(
      `${ENDPOINTS.REMOVE_TAGS_IN_BULK}?${stringify(queryStringParams)}`,
      data
    );
  };

  sendBulkEmail = (data) => {
    return this.apiClient.post(ENDPOINTS.SEND_BULK_EMAILS, data);
  };

  sendBulkSMS = (data) => {
    return this.apiClient.post(ENDPOINTS.SEND_BULK_SMS, data);
  };

  updateContactStatus = (payload) => {
    const { values, id } = payload;
    return this.apiClient.patch(
      format(ENDPOINTS.PARTIAL_UPDATE_CONTACT, id),
      values
    );
  };

  getMergeContactsData = (data) => {
    const queryStringParams = {
      first: data?.first,
      second: data?.second,
    };
    return this.apiClient.get(
      `${ENDPOINTS.GET_MERGE_CONTACTS}?${stringify(queryStringParams)}`
    );
  };

  mergeContacts = (data) => {
    return this.apiClient.post(ENDPOINTS.MERGE_DATA, data);
  };

  getAllContactsPaginationNone = async () => {
    return this.apiClient.get(ENDPOINTS.GET_ALL_CONTACTS);
  };

  getContactTransactions = (query, contactId) => {
    const formatedEndpoint = format(
      ENDPOINTS.GET_CONTACT_TRANSACTIONS,
      contactId
    );

    return this.apiClient.get(`${formatedEndpoint}?${stringify(query)}`);
  };
}

export default new ContactService();
