import reducers from "redux/reducers";
import { createSelector } from "reselect";
import { FILTER_OPTIONS } from "constants/ConversationConstants";

const selectConversationListDomain = (state) =>
  state?.conversation?.conversation || reducers;
const selectConversationByContact = (state) =>
  state?.conversation?.conversationByContact || reducers;

const makeSelectConversationByContact = (id) =>
  createSelector(selectConversationByContact, (substate) =>
    substate?.results?.filter((message) => {
      return message.contact.id === id;
    })
  );

const makeSelectConversationByMode = (mode) =>
  createSelector(selectConversationListDomain, (substate) => {
    let conversationList = [];
    if (mode === FILTER_OPTIONS.UNREAD) {
      conversationList = substate?.results?.filter(
        (message) => !message.mark_as_reply
      );
    } else if (mode === FILTER_OPTIONS.RECENTS) {
      conversationList = substate?.results?.filter(
        (message) => message.mark_as_reply
      );
    } else {
      conversationList = substate?.results;
    }
    return conversationList
      ?.slice()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
  });

const selectFilterValue = (state) => state?.conversation?.filter;

const selectSearchValue = (state) => state?.conversation?.search;

const selectConversation = (state) => state?.conversation || reducers;

const makeSelectIsSendingMessage = () =>
  createSelector(selectConversation, (substate) => substate?.isSendingMessage);

export {
  selectConversationListDomain,
  selectConversationByContact,
  makeSelectConversationByContact,
  selectSearchValue,
  selectFilterValue,
  makeSelectConversationByMode,
  makeSelectIsSendingMessage,
};
