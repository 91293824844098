import ApiService from "./ApiService";
import { stringify } from "querystring";

const ENDPOINTS = {
  GET_DASHBOARD_DATA: "analytics",
  GET_CONVERSION_DATA: "analytics/contact_conversions",
};

class DashboardService extends ApiService {
  getDashboardStatsData = (payload) => {
    let queryParams = "";
    if (payload?.start_date && payload?.end_date) {
      queryParams = payload;
    }
    return this.apiClient.get(`${ENDPOINTS.GET_DASHBOARD_DATA}/?${stringify(queryParams)}`);
  };

  getDashboardConversionData = ({ payload }) => {
    return this.apiClient.get(`${ENDPOINTS.GET_CONVERSION_DATA}/?${stringify(payload)}`);
  };
}

export default new DashboardService();
