import { all, takeEvery, fork, call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
  ADD_AWARD_TO_USER,
  CREATE_AWARD,
  EDIT_AWARD,
  GET_AWARDS,
  GET_SINGLE_AWARD,
  GET_USER_LIST,
  GET_SINGLE_USER_AWARDS,
  ADD_POINTS,
  REDEEM_AWARD,
  DELETE_AWARD,
  ARCHIVE_AWARD,
  GET_ARCHIVED_AWARDS,
  ACTIVATE_AWARD,
  GET_SINGLE_ARHIVED_AWARD,
  GET_AWARDS_ACTIVE_POINTS,
  REENROLL_TO_AWARD,
  GET_AVAILABLE_AWARDS,
} from "redux/constants/Awards";
import AwardsService from "services/AwardsService";
import {
  setAwards,
  setSingleAward,
  setUserList,
  addUserToLatestUsers,
  setSingleUserAwards,
  setNewPoints,
  setRedeemedAward,
  setArchivedAwards,
  changeStatusArhivedAword,
  setSingleArchivedAward,
  setAvailableAwards,
  getAwards,
} from "redux/actions/Awards";
import { message } from "antd";
import { ROUTES } from "routes";
import { ACTIVE, FIRST_PAGE } from "constants/AwardsConstants";
import { makeSelectCurrentUser } from "redux/selectors/Users";

function* getPotentialUsers({ payload }) {
  try {
    const { data } = yield call(AwardsService.getUserList, payload);
    yield put(setUserList(data));
  } catch (err) {
    console.log(err);
  }
}

export function* createAward() {
  yield takeEvery(CREATE_AWARD, function* ({ payload, successMessage }) {
    try {
      yield call(AwardsService.createAwards, payload);
      message.success(successMessage, 3);
      yield put(push(ROUTES.AWARDS));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getAwardsSaga() {
  yield takeEvery(GET_AWARDS, function* (payload) {
    try {
      const { data } = yield call(AwardsService.getAwards, payload);
      yield put(setAwards(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getSingleAward() {
  yield takeEvery(GET_SINGLE_AWARD, function* (payload) {
    try {
      const { data } = yield call(AwardsService.getSingleAward, payload);
      yield put(setSingleAward(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* editAward() {
  yield takeEvery(EDIT_AWARD, function* ({ payload, successMessage }) {
    try {
      const { data } = yield call(AwardsService.editAward, payload);
      yield put(setSingleAward(data));
      message.success(successMessage, 3);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* addAwardToUser() {
  yield takeEvery(ADD_AWARD_TO_USER, function* ({ payload, successMessage }) {
    try {
      const { data } = yield call(AwardsService.addAwardToUser, payload);
      yield put(addUserToLatestUsers(data));
      yield put(setSingleAward(data));
      if (payload?.id) {
        const { data } = yield call(AwardsService.getSingleUserAwards, payload);
        yield put(setSingleUserAwards(data));
      }
      message.success(successMessage, 3);
      let payloadData = {
        awardId: payload.awardId,
        page: FIRST_PAGE,
        search: "",
      };
      yield call(getPotentialUsers, { payload: payloadData });
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getUserList() {
  yield takeEvery(GET_USER_LIST, getPotentialUsers);
}

export function* getSingleUserAwards() {
  yield takeEvery(GET_SINGLE_USER_AWARDS, function* ({ payload }) {
    try {
      const { data } = yield call(AwardsService.getSingleUserAwards, payload);
      yield put(setSingleUserAwards(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* addPoints() {
  yield takeEvery(ADD_POINTS, function* ({ payload, successMessage, failMessage }) {
    try {
      yield call(AwardsService.addPoints, payload);
      const user = yield select(makeSelectCurrentUser())
      const contactData={
        id:payload.userId,
        organization: user?.organization?.id
      }
      const { data } = yield call(AwardsService.getSingleUserAwards, contactData);
      yield put(setSingleUserAwards(data));
      message.success(successMessage, 3);
    } catch (err) {
      message.error(failMessage, 3);
    }
  });
}

export function* redeemAward() {
  yield takeEvery(REDEEM_AWARD, function* ({ payload, successMessage, isModalVisible }) {
    try {
      const { data } = yield call(AwardsService.redeemAward, payload);
      yield put(setRedeemedAward({ data: data, id: payload.pointsId }));

      const user = yield select(makeSelectCurrentUser())
      const userData = { id: payload.userId, organization: user?.organization?.id }
      const awards = yield call(AwardsService.getSingleUserAwards, userData);
      yield put(setSingleUserAwards(awards.data));

      isModalVisible(false);
      message.success(successMessage, 3);
    } catch (err) {
      let errorMessage = err?.response?.data?.non_field_errors[0];
      message.error(errorMessage, 2);
    }
  });
}

export function* deleteAward() {
  yield takeEvery(DELETE_AWARD, function* ({ payload, successMessage }) {
    try {
      yield call(AwardsService.deleteAward, payload);
      const { data } = yield call(AwardsService.getAwards, { payload: 1 });
      yield put(setAwards(data));
      message.success(successMessage, 3);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* archiveAwardSaga() {
  yield takeEvery(ARCHIVE_AWARD, function* ({ payload, successMessage, setPage }) {
    try {
      yield call(AwardsService.archiveAward, payload);
      const { data } = yield call(AwardsService.getAwards, { payload: FIRST_PAGE });
      yield put(setAwards(data));
      setPage(FIRST_PAGE);
      message.success(successMessage, 3);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getArchivedAwardsSaga() {
  yield takeEvery(GET_ARCHIVED_AWARDS, function* (payload) {
    try {
      const { data } = yield call(AwardsService.getArchivedAwards, payload);
      yield put(setArchivedAwards(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* activteAwardSaga() {
  yield takeEvery(ACTIVATE_AWARD, function* ({ payload, awardId, successMessage }) {
    try {
      yield call(AwardsService.activteAward, payload, awardId);
      yield put(changeStatusArhivedAword(ACTIVE));
      yield put(getAwards(FIRST_PAGE));
      message.success(successMessage, 3);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getSingleArchivedAward() {
  yield takeEvery(GET_SINGLE_ARHIVED_AWARD, function* ({ payload }) {
    try {
      const { data } = yield call(AwardsService.getSingleArchivedAward, payload);
      yield put(setSingleArchivedAward(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getAwardsActivePoints() {
  yield takeEvery(GET_AWARDS_ACTIVE_POINTS, function* (payload) {
    try {
      const { data } = yield call(AwardsService.getAwardsActivePoints, payload);
      yield put(setAwards(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* reEnrollToAwardSaga() {
  yield takeEvery(REENROLL_TO_AWARD, function* ({ payload, successMessage }) {
    try {
      const { data } = yield call(AwardsService.reEnrollToAward, payload);
      yield put(setNewPoints(data));
      message.success(successMessage, 3);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getAvailableAwardsSaga() {
  yield takeEvery(GET_AVAILABLE_AWARDS, function* ({ payload }) {
    try {
      const { data } = yield call(AwardsService.getAvailableAwards, payload);
      yield put(setAvailableAwards(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createAward),
    fork(getAwardsSaga),
    fork(getSingleAward),
    fork(editAward),
    fork(addAwardToUser),
    fork(getUserList),
    fork(getSingleUserAwards),
    fork(addPoints),
    fork(redeemAward),
    fork(deleteAward),
    fork(archiveAwardSaga),
    fork(getArchivedAwardsSaga),
    fork(activteAwardSaga),
    fork(getSingleArchivedAward),
    fork(getAwardsActivePoints),
    fork(reEnrollToAwardSaga),
    fork(getAvailableAwardsSaga),
  ]);
}
