export const GET_CONVERSATION = "GET_CONVERSATION";
export const SET_CONVERSATION = "SET_CONVERSATION";
export const GET_CONVERSATION_BY_CONTACT = "GET_CONVERSATION_BY_CONTACT";
export const SET_CONVERSATION_BY_CONTACT = "SET_CONVERSATION_BY_CONTACT";
export const MARK_MESSAGE_AS_READ = "MARK_MESSAGE_AS_READ";
export const SET_MESSAGE_AS_READ = "SET_MESSAGE_AS_READ";
export const ADD_MESSAGE_TO_LIST = "ADD_MESSAGE_TO_LIST";
export const UPDATE_CONTACT_INFO = "UPDATE_CONTACT_INFO";
export const SET_FILTER = "SET_FILTER";
export const SET_SEARCH = "SET_SEARCH";
export const SET_RECIEVED_MESSAGE_TO_CONTACT =
  "SET_RECIEVED_MESSAGE_TO_CONTACT";
export const SET_RECIEVED_MESSAGE_TO_LIST = "SET_RECIEVED_MESSAGE_TO_LIST";
export const SET_IS_SENDING_MESSAGE = "SET_IS_SENDING_MESSAGE";
