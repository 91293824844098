import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  GET_CONVERSATION_FOR_ALL_CONTACTS:
    "conversations/?search=%s&filterReplay=%s&page=%s&perPage=%s",
  GET_CONVERSATION_BY_CONTACT: "conversations/%s/contact/?page=%s&perPage=%s",
  MARK_MESSAGE_AS_READ: "conversations/%s/",
};

class ConversationService extends ApiService {
  getConversationAllContacts = async ({ search, filter, page, perPage }) => {
    return await this.apiClient.get(
      format(
        ENDPOINTS.GET_CONVERSATION_FOR_ALL_CONTACTS,
        search,
        filter,
        page,
        perPage
      )
    );
  };

  getConversationByContact = async ({ id, page, perPage }) => {
    return await this.apiClient.get(
      format(ENDPOINTS.GET_CONVERSATION_BY_CONTACT, id, page, perPage)
    );
  };

  markMessageAsRead = async ({ message, data }) => {
    return await this.apiClient.patch(
      format(ENDPOINTS.MARK_MESSAGE_AS_READ, message.id),
      data
    );
  };
}

export default new ConversationService();
