import {
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_ERROR,
  POST_NEW_ORGANIZATION,
  POST_PRACTICE_INFO,
  SET_PRACTICE_INFO,
  GET_PRACTICE_INFO,
  UPDATE_PRACTICE_INFO,
  EDIT_FORWARDING_PHONE_NUMBER,
  SET_ORGANIZATION_DATA,
} from "../constants/Organization";

export const updateOrganization = (payload, id, messageSuccess) => ({
  type: UPDATE_ORGANIZATION,
  payload,
  id,
  messageSuccess,
});

export const updateOrganizationError = (payload) => ({
  type: UPDATE_ORGANIZATION_ERROR,
  payload,
});

export const postNewOrganization = (payload, messageSuccess, pushToRoute) => ({
  type: POST_NEW_ORGANIZATION,
  payload,
  messageSuccess,
  pushToRoute,
});

export const getPracticeInfo = (setIsLoading) => ({
  type: GET_PRACTICE_INFO,
  setIsLoading,
});

export const postPracticeInfo = (payload, messageSuccess) => ({
  type: POST_PRACTICE_INFO,
  payload,
  messageSuccess,
});

export const setPracticeInfo = (payload) => ({
  type: SET_PRACTICE_INFO,
  payload,
});

export const updatePracticeInfo = (payload, messageSuccess, id, form) => ({
  type: UPDATE_PRACTICE_INFO,
  payload,
  messageSuccess,
  id,
  form,
});

export const setOrganizationData = (payload) => ({
  type: SET_ORGANIZATION_DATA,
  payload,
});

export const editForwardingPhoneNumber = (payload, closeModal) => ({
  type: EDIT_FORWARDING_PHONE_NUMBER,
  payload,
  closeModal,
});
