import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { AGENCY_ROUTES, ROUTES } from "routes";
import { makeSelectIsAuthenticated } from "selectors";
import { makeSelectCurrentUser } from "redux/selectors/Users";
import { getCurrentUserData } from "redux/actions/Auth";
import { ROLE } from "constants/RoleConstants";

export function PublicRoute({ component: Component, ...rest }) {
  const isAuthenticated = useSelector(makeSelectIsAuthenticated());
  const user = useSelector(makeSelectCurrentUser());
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentUserData());
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && user ? (
          <Redirect
            to={
              user?.role?.access_type === ROLE.AGENCY_MANAGER
                ? AGENCY_ROUTES.CLONE
                : ROUTES.CONTACTS
            }
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
  user: makeSelectCurrentUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PublicRoute);
