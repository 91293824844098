import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import { PublicRoute } from "shared/PublicRoute";
import { PrivateRoute } from "shared/PrivateRoute";

export const AuthViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <PublicRoute
          path={`${AUTH_PREFIX_PATH}/login`}
          component={lazy(() => import(`./authentication/login`))}
        />
        <PublicRoute
          path={`${AUTH_PREFIX_PATH}/verification-code`}
          component={lazy(() => import(`./authentication/verification-page`))}
        />
        <PrivateRoute
          path={`${AUTH_PREFIX_PATH}/create-password`}
          type="createPassword"
          component={lazy(() => import(`./authentication/create-password`))}
        />
        <PublicRoute
          path={`${AUTH_PREFIX_PATH}/reset-password`}
          component={lazy(() => import(`./authentication/reset-password`))}
        />
        <PublicRoute
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import(`./authentication/forgot-password-page`))}
        />
        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))} />
        <PublicRoute
          path={`${AUTH_PREFIX_PATH}/register`}
          component={lazy(() => import(`./authentication/register`))}
          exact
        />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  );
};

export default AuthViews;
