// @ts-nocheck
/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import {
  SET_CONVERSATION,
  SET_CONVERSATION_BY_CONTACT,
  SET_MESSAGE_AS_READ,
  ADD_MESSAGE_TO_LIST,
  UPDATE_CONTACT_INFO,
  SET_FILTER,
  SET_SEARCH,
  SET_RECIEVED_MESSAGE_TO_CONTACT,
  SET_RECIEVED_MESSAGE_TO_LIST,
  SET_IS_SENDING_MESSAGE,
} from "../constants/Conversation";
import produce from "immer";
import { REMOVE_CONTACTS_FROM_CONVERSATION } from "redux/constants/Contact";

export const initialState = {
  conversation: {},
  conversationByContact: {},
  filter: 0,
  search: "",
  isSendingMessage: false,
};

const conversationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_CONVERSATION:
        draft.conversation = {
          count: payload.data.count,
          page: payload.currentPage,
          current: payload.currentPage,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results:
            payload.currentPage === 1
              ? payload.data.results
              : [...draft.conversation.results, ...payload.data.results],
          isLoading: false,
        };
        break;
      case SET_CONVERSATION_BY_CONTACT:
        draft.conversationByContact = {
          count: payload.data.count,
          page: payload.currentPage,
          current: payload.currentPage,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results:
            payload.currentPage === 1
              ? payload.data.results
              : [
                  ...draft.conversationByContact.results,
                  ...payload.data.results,
                ],
          isLoading: false,
        };
        break;
      case SET_MESSAGE_AS_READ:
        let newConversationList = draft.conversation.results.filter(
          (el) => el.id !== payload
        );
        draft.conversation.results = newConversationList;
        break;
      case ADD_MESSAGE_TO_LIST:
        if (draft.conversationByContact !== undefined) {
          draft.conversationByContact.results?.unshift(payload.data);
        }
        break;
      case UPDATE_CONTACT_INFO:
        if (
          draft.conversationByContact !== undefined &&
          draft.conversation?.results
        ) {
          draft.conversation.results = draft.conversation.results.map((el) => {
            if (el.contact.id === payload.data.id) {
              return {
                ...el,
                contact: payload.data,
                content_object: { ...el.content_object, contact: payload.data },
              };
            }
            return el;
          });
        }
        break;
      case SET_FILTER:
        draft.filter = payload;

        break;
      case SET_SEARCH:
        draft.search = payload;
        break;
      case SET_RECIEVED_MESSAGE_TO_CONTACT:
        const message = draft.conversationByContact.results.find(
          (message) => message.id == payload.data.id
        );

        if (!message) {
          draft.conversationByContact.results.unshift(payload.data);
          draft.conversationByContact.count += 1;
        }
        break;
      case SET_RECIEVED_MESSAGE_TO_LIST:
        const itemIndex = draft.conversation.results.findIndex(
          (el) => el.contact.id === payload.id
        );
        if (itemIndex == -1) {
          draft.conversation.results.unshift(payload.data);
        } else {
          draft.conversation.results[itemIndex].content_object =
            payload.data.content_object;
        }
        break;
      case REMOVE_CONTACTS_FROM_CONVERSATION:
        if (draft.conversation?.results) {
          payload?.selected_contacts?.forEach((contactId) => {
            const contactIndex = draft.conversation.results.findIndex(
              (el) => el.contact.id === contactId
            );
            if (contactIndex != -1) {
              draft.conversation.results.splice(contactIndex, 1);
              draft.conversation.count -= 1;
            }
          });
        }
        break;
      case SET_IS_SENDING_MESSAGE:
        draft.isSendingMessage = payload;
        break;
    }
  });

export default conversationReducer;
